.tags-wrapper {
	margin-top: 20px;
	.error-state-field {
		transform: translateX(-15%);
		margin-top: -28px;
		p {
			color: #eb2c5b;
			font-family: 'TT-Commons-Regular';
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 120%;
			position: absolute;
			left: 45%;
		}
	}
	.pill-block-popup {
		transform: translateX(35%);
		width: 76%;
		max-height: 200px;
		margin-top: 6px;
		overflow-y: auto;
		padding: 8px 20px;
		&::-webkit-scrollbar {
			width: 3px;
			height: 25px;
			background: transparent;
		}
		&::-webkit-scrollbar-track {
			background: transparent;
		}
		&::-webkit-scrollbar-thumb {
			background: #888;
		}
	}
}
