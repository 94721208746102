.custom-file-picker-wrapper {
	width: 100%;
	.custom-file-picker-label {
		font-family: 'TT-Commons-Medium';
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		line-height: 19px;
		letter-spacing: 1px;
		text-transform: uppercase;
		color: rgba(255, 255, 255, 0.42);
		margin: 4px 0px;
	}
	.custom-file-picker-input-wrapper {
		.custom-file-picker-input {
			display: none;
		}
		.custom-file-label {
			height: 66px;
			display: inline-block;
			cursor: pointer;
			border: 1px solid rgba(255, 255, 255, 0.1);
			box-sizing: border-box;
			border-radius: 4px;
			width: 90%;
			.file-picker-outerdiv {
				padding: 20px 16px;
				width: 100%;
				height: 100%;
				position: relative;
				background: transparent;
				display: flex;
				.file-picker-icon {
					max-width: 105px !important;
				}
				.file-picker-icon-selected {
					max-width: 175px;
					height: 50px;
					margin-top: -12px;
				}
				.file-picker-span {
					margin-left: 15px;
					font-family: 'TT-Commons-Medium';
					font-style: normal;
					font-weight: bold;
					font-size: 24px;
					line-height: 25px;
					color: rgba(255, 255, 255, 0.32);
					margin-top: 3px;
				}
				.file-picker-span-white {
					color: white !important;
				}
			}
		}
	}
	.cat-custom-file-picker-input-wrapper {
		.cat-file-picker-backdrop {
			width: 152px;
			height: 96px;
			cursor: pointer;
			position: relative;
			overflow: hidden;
			.cat-file-picker-backdrop-image {
				width: 99%;
				height: 98%;
				box-sizing: border-box;
				border-radius: 4px;
			}
			.blur-parent {
				opacity: 0.4;
			}
			.cat-file-picker-backdrop-span {
				position: absolute;
				z-index: 1000;
				transform: translate(20%, -110%);
				-ms-transform: translate(10%, -110%) !important;
				color: white;
				border: none;
				cursor: pointer;
				border-radius: 5px;
				text-align: center;
				display: flex;
				flex-direction: column;
				.cat-custom-file-picker-upload {
					display: none;
				}
				.cat-file-picker-backdrop-button {
					display: flex;
					justify-content: flex-start;
					margin-bottom: 12px;
					.cat-file-picker-backdrop-button-img {
						width: 17px;
						height: 17px;
					}
					.cat-file-picker-backdrop-button-span {
						font-family: 'TT-Commons-Demi';
						font-style: normal;
						font-weight: 600;
						font-size: 16px;
						line-height: 120%;
						margin-left: 4px;
						margin-top: 3px;
					}
				}
			}
		}
		.cat-custom-file-picker-input {
			display: none;
		}
		.cat-custom-file-label {
			height: 66px;
			display: inline-block;
			cursor: pointer;
			border: 1px solid rgba(255, 255, 255, 0.1);
			box-sizing: border-box;
			border-radius: 4px;
			width: 152px;
			height: 96px;
			.cat-file-picker-outerdiv {
				padding: 20px 16px;
				width: 100%;
				height: 100%;
				position: relative;
				background: transparent;
				display: flex;
				.cat-file-picker-icon {
					width: 18px;
					height: 18px;
					margin-top: 4px;
				}
				.cat-file-picker-span {
					margin-left: 15px;
					font-family: 'TT-Commons-Medium';
					font-style: normal;
					font-weight: bold;
					font-size: 24px;
					line-height: 25px;
					color: rgba(255, 255, 255, 0.32);
					margin-top: 3px;
				}
				.cat-file-picker-span-white {
					color: white !important;
				}
			}
		}
	}
}
