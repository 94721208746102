@import 'src/app/common/utils/scss/main.scss';

.popular-right-container {
	width: 100%;
	position: relative;
	margin-top: 1.2rem;
	padding-right: 7px;
	display: flex;
	flex-direction: column;

	#content-table-div::-webkit-scrollbar {
		display: none;
	}

	.content-table-div {
		display: flex;
		width: 100%;

		.empty-state-container {
			// width: 100vh;
			overflow: hidden;
			height: 80vh;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			.loader-container {
				transform: translateX(15%);
				margin-top: -20%;
			}
		}
		.empty-image {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			// transform: translateX(15%);

			img {
				margin-bottom: 15.3px;
			}
			.no-filter-found {
				font-style: normal;
				font-weight: bold;
				font-size: 24px;
				line-height: 115%;
				color: #ffffff;
			}
			.please-change-filter {
				font-style: normal;
				font-weight: normal;
				font-size: 18px;
				line-height: 135%;
				text-align: center;
				color: rgba(255, 255, 255, 0.7);
			}
		}

		#content-table-div {
			position: relative;
			max-height: fit-content;
			width: 100%;

			&::-webkit-scrollbar {
				width: 0px;
				height: 0px;
				background: transparent;
			}
			&::-webkit-scrollbar-track {
				background: transparent;
			}
			&::-webkit-scrollbar-thumb {
				background: transparent;
			}

			.carousel-dd {
				// position: absolute;
				background: rgba(14, 14, 23, 0.32);
				padding-top: 16px;
				padding: 16px 40px;
				padding-bottom: 0px;
				.back-div {
					display: flex;
					width: fit-content;
					cursor: pointer;
					z-index: 2;
					margin-top: -20px;
					position: absolute;
					img {
						width: 11.71px;
						height: 14.17px;
						margin-right: 7px;
					}
					.back-mssg {
						font-family: 'TT-Commons-Regular';
						font-style: normal;
						font-weight: normal;
						font-size: 16px;
						line-height: 120%;
						color: rgba(255, 255, 255, 0.7);
						padding-top: 0px !important;
					}
				}

				.view-in-progress-video {
					font-family: 'TT-Commons-Demi';
					font-style: normal;
					font-weight: 600;
					font-size: 20px;
					line-height: 115%;
					text-align: right;
					color: #f82c5e;
					margin-top: -20px;
					margin-bottom: 20px;
					cursor: pointer;
				}
				.margin-on-selection {
					margin-top: -40px !important;
				}
				.disable-view-in-progress-video {
					pointer-events: none;
					opacity: 0.6;
				}
				.extra-margin {
					margin-left: 35px;
					input[type='checkbox'] {
						margin-left: 35px !important;
					}
				}
				.selection-button {
					font-style: normal;
					font-weight: 600;
					font-size: 18px;
					line-height: 96%;
					font-family: 'TT-Commons-Regular';
					color: #ffffff;
					// margin-top: -20px;
					margin-bottom: 20px;

					.span-delete-selected {
						font-style: normal;
						font-weight: 600;
						font-size: 18px;
						line-height: 96%;
						font-family: 'TT-Commons-Bold';
						color: #f82c5e;
					}
					.span-delete-unselected {
						font-style: normal;
						font-weight: 600;
						font-size: 18px;
						line-height: 96%;
						font-family: 'TT-Commons-Medium';
						color: #ffffff;
					}

					input[type='checkbox'] {
						position: absolute;
						appearance: none;
						background: $filter-tag-color;
						border: 1px solid $filter-tag-color;
						width: 20px;
						height: 20px;
						border-radius: 50%;
						left: 5px;
						top: 10px;
						z-index: 2;
						cursor: pointer;
						margin-left: 6px;

						&:checked {
							background: url('../../../../assets/images/Subtract.svg') no-repeat;
							background-position: center;
							border: 1px solid $primary-default;
						}
					}
				}
				.shuffle-mode-none {
					display: none;
				}
				.shuffle-mode {
					margin-bottom: 20px;
					display: flex;
					align-items: center;
					.ordinary {
						font-family: 'TT-Commons-Regular';
						font-style: normal;
						font-weight: 400;
						font-size: 18px;
						line-height: 135%;
						color: #ffffff;
					}
					.highlighted {
						font-family: 'TT-Commons-Regular';
						font-style: normal;
						font-weight: 400;
						font-size: 18px;
						line-height: 135%;
						color: #f82c5e;
						cursor: pointer;
					}
				}
			}
		}
	}

	#content-block::-webkit-scrollbar {
		display: none;
	}
	.content-block {
		display: flex;
		overflow-y: auto;
		width: 100%;

		&::-webkit-scrollbar {
			width: 0px;
			height: 0px;
			background: transparent;
		}
		&::-webkit-scrollbar-track {
			background: transparent;
		}
		&::-webkit-scrollbar-thumb {
			background: transparent;
		}

		.empty-state-container {
			// width: 100vh;
			overflow: hidden;
			height: 80vh;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			.loader-container {
				transform: translateX(15%);
				margin-top: -20%;
			}
		}
		.empty-image {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			// transform: translateX(15%);

			img {
				margin-bottom: 15.3px;
			}
			.no-filter-found {
				font-style: normal;
				font-weight: bold;
				font-size: 24px;
				line-height: 115%;
				color: #ffffff;
			}
			.please-change-filter {
				font-style: normal;
				font-weight: normal;
				font-size: 18px;
				line-height: 135%;
				text-align: center;
				color: rgba(255, 255, 255, 0.7);
			}
		}
	}
	#content-block {
		overflow: auto;
		position: relative;
		// hiding scroll bar
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
		max-height: fit-content;
		// display: flex;
		width: 100%;

		&::-webkit-scrollbar {
			width: 0px;
			height: 0px;
			background: transparent;
		}
		&::-webkit-scrollbar-track {
			background: transparent;
		}
		&::-webkit-scrollbar-thumb {
			background: transparent;
		}

		.carousel-dd {
			// position: absolute;
			background: rgba(14, 14, 23, 0.32);
			padding-top: 16px;
			padding: 16px 40px;
			padding-bottom: 0px;
			.back-div {
				display: flex;
				width: fit-content;
				cursor: pointer;
				z-index: 2;
				margin-top: -20px;
				position: absolute;
				img {
					width: 11.71px;
					height: 14.17px;
					margin-right: 7px;
				}
				.back-mssg {
					font-family: 'TT-Commons-Regular';
					font-style: normal;
					font-weight: normal;
					font-size: 16px;
					line-height: 120%;
					color: rgba(255, 255, 255, 0.7);
					padding-top: 0px !important;
				}
			}

			.view-in-progress-video {
				font-family: 'TT-Commons-Demi';
				font-style: normal;
				font-weight: 600;
				font-size: 20px;
				line-height: 115%;
				text-align: right;
				color: #f82c5e;
				margin-top: -20px;
				margin-bottom: 20px;
				cursor: pointer;
			}
			.margin-on-selection {
				margin-top: -40px !important;
			}
			.disable-view-in-progress-video {
				pointer-events: none;
				opacity: 0.6;
			}
			.extra-margin {
				margin-left: 35px;
				input[type='checkbox'] {
					margin-left: 35px !important;
				}
			}
			.selection-button {
				font-style: normal;
				font-weight: 600;
				font-size: 18px;
				line-height: 96%;
				font-family: 'TT-Commons-Regular';
				color: #ffffff;
				// margin-top: -20px;
				margin-bottom: 20px;

				.span-delete-selected {
					font-style: normal;
					font-weight: 600;
					font-size: 18px;
					line-height: 96%;
					font-family: 'TT-Commons-Bold';
					color: #f82c5e;
				}
				.span-delete-unselected {
					font-style: normal;
					font-weight: 600;
					font-size: 18px;
					line-height: 96%;
					font-family: 'TT-Commons-Medium';
					color: #ffffff;
				}

				input[type='checkbox'] {
					position: absolute;
					appearance: none;
					background: $filter-tag-color;
					border: 1px solid $filter-tag-color;
					width: 20px;
					height: 20px;
					border-radius: 50%;
					left: 5px;
					top: 10px;
					z-index: 2;
					cursor: pointer;
					margin-left: 6px;

					&:checked {
						background: url('../../../../assets/images/Subtract.svg') no-repeat;
						background-position: center;
						border: 1px solid $primary-default;
					}
				}
			}
			.shuffle-mode {
				margin-bottom: 20px;
				display: flex;
				align-items: center;
				.ordinary {
					font-family: 'TT-Commons-Regular';
					font-style: normal;
					font-weight: 400;
					font-size: 18px;
					line-height: 135%;
					color: #ffffff;
				}
				.highlighted {
					font-family: 'TT-Commons-Regular';
					font-style: normal;
					font-weight: 400;
					font-size: 18px;
					line-height: 135%;
					color: #f82c5e;
					cursor: pointer;
				}
			}
		}

		.infinite-scroll-component__outerdiv {
			width: 100%;
			.infinite-scroll-component {
				height: 100%;
			}
			.infinite-scroll-data {
				display: flex;
				background: rgba(14, 14, 23, 0.32);
				height: 100%;
				.carousel-data-container {
					display: flex;
					flex-direction: column;
					width: 100%;
					padding: 36px 40px;
					padding-top: 12px;
					overflow: auto;
					&::-webkit-scrollbar {
						width: 0px;
						height: 0px;
						background: transparent;
					}
					&::-webkit-scrollbar-track {
						background: transparent;
					}
					&::-webkit-scrollbar-thumb {
						background: transparent;
					}

					.card-grid {
						display: grid;
						gap: 20px;

						@media only screen and (min-width: 1560px) {
							grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
						}
						@media only screen and (min-width: 1340px) and (max-width: 1560px) {
							grid-template-columns: 1fr 1fr 1fr 1fr;
						}
						@media only screen and (max-width: 1340px) {
							grid-template-columns: 1fr 1fr 1fr;
						}
					}
				}
			}
		}
	}
	.header-block {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		margin-bottom: 25px;
		.view-tags {
			font-family: 'TT-Commons-Medium';
			font-style: normal;
			font-weight: 600;
			font-size: 20px;
			line-height: 115%;
			color: #f82c5e;
			cursor: pointer;
		}
	}
	.dashboard-button-block {
		display: flex;
		align-items: center;
		justify-content: space-between;
		transform: translate(0px, -2px);
		margin-top: -7px;
		button:first-child {
			padding: 8.8px 16px;
		}
	}
	.search-box-block {
		margin-bottom: 25px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		.aside-buttons {
			display: flex;
			align-items: center;
			#tooltip.bottom-side {
				margin-top: 120px;
				right: 195px;
				left: auto;
			}

			#tooltip.bottom-side .tooltip-arrow {
				top: 0;
				left: 50%;
				margin-left: -5px;
				border-width: 0 5px 5px;
				border-bottom-color: #2a2a31;
			}

			.unselect-all-cards-icon {
				margin-left: 18px;
				cursor: pointer;
			}
			.change-layout-icon {
				height: 46px;
				width: 46px;
				margin-left: 12px;
				cursor: pointer;
			}
			.no-margin {
				margin-right: 0px;
			}
		}
	}
	.header-parent-block {
		display: flex;
		flex-direction: column;
	}
	.popup-overlay {
		background: rgba(0, 0, 0, 0.1);
		animation: overlayAnim 0.3s forwards;
	}

	@keyframes overlayAnim {
		from {
			background: rgba(0, 0, 0, 0.1);
		}
		to {
			background: rgba(0, 0, 0, 0.86);
		}
	}

	@media only screen and (max-width: 1080px) and (min-height: 600px) {
		max-height: 95vh;
		h1 {
			font-size: 32px;
			letter-spacing: 0px;
		}
		p {
			font-size: 20px;
		}
		h3 {
			font-size: 22px;
		}
		.header-block {
			margin-bottom: 30px;
			.heading {
				margin-top: 6px;
			}
		}
		.search-box-block {
			margin-bottom: 25px;
		}
		.dashboard-button-block {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 0;
			transform: translate(0px, 0px);
			button:first-child {
				padding: 5px 16px;
			}
			button:last-child {
				p {
					transform: translateX(-2px);
				}
			}
		}
	}

	@media only screen and (min-width: 1081px) and (max-width: 1280px) {
		max-height: 88.5vh;
		p {
			font-size: 20px;
		}
		h3 {
			font-size: 26px;
		}
	}

	@media only screen and (min-width: 1281px) {
		max-height: 89vh;
		h1 {
			font-size: 36px;
			letter-spacing: 0;
		}
	}
}

@media only screen and (max-width: 1080px) {
	.right-container {
		margin-top: 4.5%;
		margin-left: 0.4%;
	}
}
