.report-popup-wrapper {
	.cross-icon {
		position: absolute;
		right: 14px;
		top: 14px;
		cursor: pointer;
		margin-bottom: 6px;
		padding-bottom: 6px;
	}
	.report-popup-heading {
		font-family: 'TT-Commons-Regular';
		font-style: normal;
		font-weight: 600;
		font-size: 22px;
		line-height: 124%;
		color: #ffffff;
		position: relative;
		margin-top: 32px;
		margin-left: 32px;
		margin-bottom: 16px;
	}
	.seprator {
		border: 1px solid rgba(14, 14, 23, 0.2);
		width: 406px;
		height: 0px;
		margin-left: 32px;
		margin-bottom: 16px;
	}
	.report-content-container {
		overflow-y: auto;
		max-height: 420px;
		&::-webkit-scrollbar {
			width: 3px;
			height: 25px;
			background: transparent;
		}
		&::-webkit-scrollbar-track {
			background: transparent;
		}
		&::-webkit-scrollbar-thumb {
			background: #888;
		}

		.report-content-wrapper {
			margin-left: 24px;
			display: flex;
			margin-bottom: 24px;
			// align-items: center;

			.reporters-avatar {
				position: relative;
				display: inline-block;
				border: 2px solid #2a2a31;
				border-radius: 50%;
				overflow: hidden;
				width: 32px;
				height: 32px;
				margin-right: 12px;
			}
			.user-icon-error {
				height: 32px;
				font-family: 'TT-Commons-Regular';
				font-style: normal;
				font-weight: 400;
				font-size: 15px;
				line-height: 130%;
				text-align: center;
				letter-spacing: 1px;
				text-transform: uppercase;
				color: #ffffff;
				.user-name-error-span {
					display: flex;
					justify-content: center;
					align-items: center;
					height: 100%;
					margin-top: 2px;
				}
			}

			.reporters-avatar img {
				width: 28px;
				height: 28px;
				display: block;
				// transform: scale(1, -1);
			}

			.desc-wrapper {
				.popup-name {
					font-family: 'TT-Commons-Demi';
					font-style: normal;
					font-weight: 600;
					font-size: 18px;
					line-height: 96%;
					color: #ffffff;
					margin-bottom: 8px;
				}
				.popup-desc {
					font-family: 'TT-Commons-Regular';
					font-style: normal;
					font-weight: 400;
					font-size: 16px;
					line-height: 120%;
					color: #ffffff;
					margin-bottom: 5px;
					width: 370px;
				}
				.popup-time {
					font-family: 'TT-Commons-Medium';
					font-style: normal;
					font-weight: 400;
					font-size: 15px;
					line-height: 130%;
					letter-spacing: 1px;
					color: rgba(255, 255, 255, 0.42);
				}
			}
		}
	}
}
