@import '../../../common/utils/scss/variables.scss';

.preview-screen-container {
	.preview-screen-heading {
		margin-bottom: 30px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.publish-qwed-digest {
			.publish-qwed-digest-text {
				font-weight: 600;
				font-size: 20px;
				line-height: 115%;
				color: #ffffff;
				padding: 13px 17px;
				border-radius: 8px;

				span {
					line-height: 20px;
					display: inline-block;
					transform: translateY(2px);
				}
			}
		}
	}
	.search-box-container {
		display: flex;
		justify-content: space-between;
		margin-bottom: 21px;

		.search-with-cancel {
			display: flex;
			align-items: center;
			:focus-within {
				border: 2px solid #f82c5e;
				transition: border 0.1s ease-in-out;
			}
			.qwed-digest-search {
				width: 508px;
				margin-right: 18px;

				input {
					border: none !important;
				}
			}
			.image-button-container {
				border: none !important;
				.cancel-button {
					border: none;
					.cancel-button-label {
						font-style: normal;
						font-weight: normal;
						font-size: 18px;
						line-height: 135%;
						color: #ffffff;
					}
				}
			}
		}
		.header-buttons {
			display: flex;
			align-items: center;
			.preview-qwed-digest {
				margin-left: 16.5px;
				.preview-qwed-digest-text {
					font-weight: 600;
					font-size: 20px;
					line-height: 115%;
					color: #ffffff;
					padding: 13px 17px;
					border-radius: 8px;
					background: #2a2a31;

					span {
						line-height: 20px;
						display: inline-block;
						transform: translateY(2px);
					}
				}
			}
			.image-button-container {
				width: 47px;
				margin-left: 16.5px;
				.edit-button {
					border: none;
					background: #2a2a31;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 47px;
					height: 47px;

					img {
						margin: 0;
					}
				}
			}
		}
	}
	.preview-screen-content-container {
		padding: 32px 40.71px 36px 36px;
		background-color: $disabled-bg;
		height: 80vh;
		overflow: auto;
		&::-webkit-scrollbar {
			width: 3px;
			height: 25px;
			background: transparent;
		}
		&::-webkit-scrollbar-track {
			background: transparent;
		}
		&::-webkit-scrollbar-thumb {
			background: #888;
		}
		.cards {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr;
			column-gap: 15px;
			row-gap: 20px;
		}
	}
}
