@import '../../common/utils/scss/variables.scss';
.all-users-right-container {
	margin-top: 1.5%;
	height: 100%;
	display: flex;
	flex-direction: column;
	.all-users-header-block {
		display: flex;
		align-items: center;
		.count-user {
			background: #2a2a31;
			margin-left: 12px;
			border-radius: 44px;
			padding: 9px 13px;
			padding-top: 11px;
			font-family: 'TT-Commons-Regular';
			font-style: normal;
			font-weight: 700;
			font-size: 20px;
			line-height: 115%;
			color: rgba(255, 255, 255, 0.42);
		}
	}
	.all-users-search-with-cancel {
		margin-top: 40px;
		width: 100%;
		position: relative;
		display: flex;
		align-items: center;
		margin-bottom: 24px;
		justify-content: space-between;

		.all-users-search {
			display: flex;
			align-items: center;
		}
		.cancel-button {
			margin-left: 10px;
			color: white;
			border: none;
		}
		.selection-sort {
			position: absolute;
			right: 4px;
		}
		.date-button-block {
			display: flex;
			.date-placeholder {
				width: 100%;
				height: 50px;
				box-sizing: border-box;
				border-radius: 8px;
				padding: 8px;
				background-color: #363541;
				p {
					//styleName: Heading / 4;
					font-family: 'TT-Commons-Demi';
					font-style: normal;
					color: #fff;
					font-size: 22px;
					font-weight: 600;
					letter-spacing: 0;
					line-height: 164%;
					padding-top: 2.5px;
				}
			}
			.no-display {
				display: none;
			}
			.date-filter-button {
				position: relative;
				display: flex;
				flex: 0 0 auto;
				margin-left: 14px;
			}
		}
	}

	.content-block {
		position: relative;
		// hiding scroll bar
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
		max-height: fit-content;
	}
}
