@import 'src/app/common/utils/scss/variables.scss';

#scrollableDivLiveBucketList {
	max-height: 66.5vh !important;
	overflow: auto;
	padding-top: 75px;
	padding-bottom: 40px;
	margin-top: 12px;

	&::-webkit-scrollbar {
		width: 3px;
		height: 0px;
		background: transparent;
		display: none;
	}
	&::-webkit-scrollbar-track {
		background: transparent;
	}
	&::-webkit-scrollbar-thumb {
		background: transparent;
	}
}
.table-bucket-live-details {
	background: #2a2a31;
	width: 94.25% !important;
	border-collapse: separate;
	border-spacing: 0 2px;
	border: none;
	table-layout: auto;
	margin-left: 40px;

	&::-webkit-scrollbar {
		width: 0px;
		height: 0px;
		background: transparent;
	}
	&::-webkit-scrollbar-track {
		background: transparent;
	}
	&::-webkit-scrollbar-thumb {
		background: transparent;
	}

	tbody {
		tr {
			transition: transform 1s ease;
			&.translateX {
				transform: translate(0px, 0px);
			}
		}
		&::-webkit-scrollbar {
			width: 0px;
			height: 0px;
			background: transparent;
		}
		&::-webkit-scrollbar-track {
			background: transparent;
		}
		&::-webkit-scrollbar-thumb {
			background: transparent;
		}
	}

	.column-head {
		vertical-align: middle;
	}
	thead {
		tr {
			box-shadow: none;
			height: 74px;
			background: #363541;
			position: absolute;
			left: 0;
			right: 0;
			z-index: 2;
			transform: translateY(-78px);
			width: 94%;
			display: flex;
			align-items: center;
			border-radius: 8px 8px 0 0;
			margin: 0px 40px;
		}
	}

	.checkbox {
		display: inline-flex;
		vertical-align: middle;
		align-items: center;
		width: 100%;
		position: relative;

		.grab-icon {
			margin-right: 28px;
			cursor: pointer;
		}

		.bucket-name {
			padding-top: 3px;
			cursor: pointer;
			&:hover {
				color: #f82c5e;
			}
		}

		#name-checkbox-row {
			appearance: none;
			-webkit-appearance: none;
			width: 19px !important;
			height: 19px !important;
			cursor: pointer;
			background: rgba(40, 40, 40, 0.2);
			color: black;
			-moz-appearance: none;
			border: 1px solid rgba(255, 255, 255, 0.32);
			box-sizing: border-box;
			border-radius: 4px;
			margin-right: 16px;
			// margin-top: 26px;
			margin-left: -1px;
		}
		.name-checkbox-row {
			appearance: none;
			-webkit-appearance: none;
			width: 19px !important;
			height: 19px !important;
			cursor: pointer;
			background: rgba(40, 40, 40, 0.2);
			color: black;
			-moz-appearance: none;
			border: 1px solid rgba(255, 255, 255, 0.32);
			box-sizing: border-box;
			border-radius: 4px;
			margin-right: 16px;
			// margin-top: 26px;
			margin-left: -1px;
		}

		#name-checkbox {
			appearance: none;
			-webkit-appearance: none;
			width: 19px !important;
			height: 19px !important;
			cursor: pointer;
			background: rgba(40, 40, 40, 0.2);
			color: black;
			-moz-appearance: none;
			border: 1px solid rgba(255, 255, 255, 0.32);
			box-sizing: border-box;
			border-radius: 4px;
			margin-right: 16px;
			margin-top: 7px;
			.extra-padding {
				padding-top: 32;
			}
		}
		.name-checkbox-row:checked,
		#name-checkbox-row:checked,
		#name-checkbox:checked {
			background-color: #f82c5e;
			padding: 2px 3px;
			border: 0;
			&::after {
				content: '';
				width: 12px;
				height: 9px;
				background-image: url(../../../assets/images/tick.svg);
				display: inline-block;
			}
		}
	}

	tr {
		&.odd {
			background: #2a2a31;
			box-shadow: 0px 16px 20px rgba(0, 0, 0, 0.25);
			height: 96px;
			// display: flex;
			// align-items: center;
			position: relative;
		}
		&.even {
			background: #26242c;
			box-shadow: 0px 16px 20px rgba(0, 0, 0, 0.25);
			height: 96px;
			// display: flex;
			// align-items: center;
			position: relative;
		}

		td {
			font-family: 'TT-Commons-Regular';
			font-style: normal;
			font-weight: normal;
			font-size: 18px;
			line-height: 135%;
			color: rgba(255, 255, 255, 0.7);
			vertical-align: middle;
			word-break: break-word;
			position: relative;
			&:first-child {
				padding-left: 3.2%;
				border-radius: 8px 0px 0px 0px;
				width: 25% !important;
				position: relative;
			}
			&:last-child {
				padding-top: 0px;
				position: relative;
				// left: 91%;
			}
			.title-container {
				display: flex;
				align-items: center;
				width: calc(100% - 36px);

				.title-name {
					font-family: 'TT-Commons-Regular';
					font-style: normal;
					font-weight: 400;
					font-size: 18px;
					line-height: 135%;
					color: rgba(255, 255, 255, 0.7);
					display: flex;
					align-items: center;
					word-break: break-word;
					padding-right: 10px;
					position: relative;
				}
			}
			.sub-cat-title {
				cursor: pointer;
			}
			&.row-url-all {
				width: 52% !important;
				position: relative;
				// left: 25%;
				padding-right: 4px;
				color: hsla(0, 0%, 100%, 0.7);
				vertical-align: middle;
				word-break: break-word;
			}
			&.logo-row {
				width: 10% !important;
				// left: 80%;
				position: relative;
				.row-icon-img {
					max-width: 75px !important;
					max-height: 25px !important;
					border: none;
					margin-top: 3px;
					// margin-left: 5%;
				}
				.row-icon-img-none {
					display: none;
				}
			}

			&.row-options {
				margin-right: 9px;
				text-align: center;
				cursor: pointer;
				display: flex;
				justify-content: center;
				margin-top: 35px;
				.live-stream-action-button {
					cursor: pointer;
					display: flex;
					align-items: center;
					justify-content: center;
					// transform: translateX(-23px) !important;
					.live-stream-action-button-image {
						width: 20px;
						height: 18px;
					}
					.live-stream-action-button-image-exta-styling {
						width: 20px;
						height: 20px;
						margin-left: 10px;
					}
					.delete-icon {
						margin-right: 16.67px;
					}
				}
			}
		}
	}

	th {
		text-align: left;
		font-family: 'TT-Commons-Demi';
		font-style: normal;
		color: #fff;
		font-size: 22px;
		font-weight: 600;
		letter-spacing: 0;
		line-height: 164%;
		vertical-align: middle;
		padding-top: 0.5%;

		&:first-child {
			padding-left: 2.6%;
			border-radius: 8px 0px 0px 0px;
			width: 25%;
		}
		&:last-child {
			border-radius: 0px 8px 0px 0px;
		}
		&.url {
			width: 55%;
		}
		&.logo {
			width: 10%;
		}
		&.name-category {
			width: 81%;
		}
	}
}
