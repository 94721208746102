.sidebar-link {
	p {
		animation: scaleIn 0.3s forwards;
		opacity: 0;
	}
	display: flex;
	@keyframes scaleIn {
		0% {
			transform: opacity(0.2);
			transform-origin: left;
			opacity: 0;
		}
		20% {
			transform: scaleX(0.2);
			transform-origin: left;
			opacity: 0;
		}
		40% {
			transform: scaleX(0.4);
			transform-origin: left;
			opacity: 0;
		}
		60% {
			transform: scaleX(0.6);
			transform-origin: left;
			opacity: 0;
		}
		80% {
			transform: scaleX(0.8);
			transform-origin: left;
			opacity: 1;
		}
		100% {
			transform: scaleX(1);
			transform-origin: left;
			opacity: 1;
		}
	}
	.expanded-link-text {
		margin-left: 10px;
		color: #ffffffb2;
		z-index: 1;
		font-family: 'TT-Commons-Demi';
		font-size: 18px;
		line-height: 17px;
		padding-top: 5.5px !important;
	}
	@media only screen and (max-width: 1080px) {
		.expanded-link-text {
			margin-left: 5px;
		}
	}
}
.expanded-image {
	width: 20px;
	height: 22.5px;
}
