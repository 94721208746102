.reset-all-button {
  padding: 11.5px 24.5px 9.5px;
  background: #2a2a31;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  cursor: pointer;
  &:hover {
    p {
      color: #f82c5e;
    }
  }
  p {
    padding: 0;
    margin: 0;
    color: #fff;
    //styleName: Body/LG/DemiBold;
    font-family: 'TT-Commons-Demi';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    padding-top: 4.5px;
  }
}
