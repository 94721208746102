@import 'src/app/common/utils/scss/variables.scss';

.cast-right-container {
	height: 100vh;
	.cast-header-parent-block {
		.cast-header-block {
			margin-top: 20px;
			display: flex;
			align-items: center;
			h1 {
				font-family: 'TT-Commons-Demi';
				font-style: normal;
				font-weight: 700;
				font-size: 36px;
				line-height: 100%;
				display: flex;
				align-items: flex-end;
				color: rgba(255, 255, 255, 0.42);
			}
			.total-count {
				background: #2a2a31;
				margin-left: 16px;
				border-radius: 44px;
				padding: 9px 13px;
				padding-top: 13.5px;
				font-family: 'TT-Commons-Regular';
				font-style: normal;
				font-weight: 700;
				font-size: 20px;
				line-height: 115%;
				color: rgba(255, 255, 255, 0.42);
			}
		}
	}
	.cast-search-with-cancel {
		margin-top: 32px;
		width: 30%;
		display: flex;
		align-items: center;
		margin-bottom: 24px;

		.cancel-button {
			margin-left: 10px;
			color: white;
			border: none;
		}
	}
}
