@import 'src/app/common/utils/scss/main.scss';

.toast-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	vertical-align: middle;
	padding: 13px 24px 11px 24px;
	background: #26242c;
	border: 1px solid rgba(79, 212, 113, 0.1);
	box-sizing: border-box;
	border-radius: 42px;
	max-width: fit-content;
	max-height: 65px;
	position: fixed;
	top: 50px;
	z-index: 1000;
	@include transition(all 0.6s ease-in-out);
}

.notification-text {
	font-family: 'TT-Commons-Regular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 120%;
	flex: none;
	order: 0;
	flex-grow: 0;
	margin: 0px 16px;
	display: block;
	text-align: center;
	position: relative;
	z-index: 2;
	opacity: 1;
}
.error-message {
	color: #f82c5e;
}
.success-message {
	color: #4fd471;
}

.cancel-icon-wrapper {
	margin-left: 4px;
	cursor: pointer;
	z-index: 2;
	opacity: 1;
}
