.breadcrumb-container {
	margin-top: 8px;
	.breadcrumb-label {
		font-family: 'TT-Commons-Regular';
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 19px;
		color: rgba(255, 255, 255, 0.42);
	}
	.active-breadcrumb {
		color: #ffffff !important;
	}
}
