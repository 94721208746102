.published-bucket-right-container {
  margin-top: 1.9%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .icon-button {
    width: 3.25em;
    height: 3.25em;
    background-color: #26242c;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .right-button-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.6875em;
    margin-right: 10px;
    img {
      margin-right: 1.25em;
    }
    .icon-button-span {
      margin-left: 20px;
    }
    img:last-child {
      margin-right: 0;
    }

    button {
      margin-left: 40px;
    }
  }

  .tab-button-block {
    width: 100%;
    border-bottom: 1px solid #2a2a31;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.8125em;
  }

  .bucket-header-block {
    margin-bottom: 3.2%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .content-block {
    .bucket-content-block {
      padding-top: 70px;
    }
  }

  @media only screen and (max-width: 1080px) {
    margin-top: 5%;
    margin-left: 2px;
    max-height: 95vh;

    .bucket-header-block {
      margin-bottom: 3.5%;
    }

    .tab-button-block {
      margin-bottom: 0.7em;
    }
  }
}
