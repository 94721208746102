.list-for-you-right-container {
	margin-top: 1.9%;
	height: 100%;
	display: flex;
	flex-direction: column;
	.header-parent-block {
		.bucket-header-block {
			font-family: 'TT-Commons-Demi';
			font-style: normal;
			font-weight: normal;
			font-size: 24px;
			line-height: 120%;
			display: flex;
			align-items: center;
			color: rgba(255, 255, 255, 0.42);
		}
	}
	.search-with-cancel {
		margin-top: 40px;
		width: 30%;
		display: flex;
		align-items: center;
		margin-bottom: 32px;

		.cancel-button {
			margin-left: 10px;
			color: white;
			border: none;
		}
		.selection-sort {
			position: absolute;
			right: 4px;
		}
	}
	.content-block {
		position: relative;
		// hiding scroll bar
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
		max-height: fit-content;
	}
}
