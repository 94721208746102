.episodes-container {
	.episodes-detail-loader-container {
		display: flex;
		height: 350px;
		align-items: center;
		justify-content: center;
	}
	.episode-details-container {
		margin: 24px 28px;
		max-height: 300px;
		transition: max-height 0.4s linear;
		overflow: hidden;

		&::-webkit-scrollbar {
			width: 0px;
			height: 10px;
			background: transparent;
		}
		&::-webkit-scrollbar-track {
			background: transparent;
		}
		&::-webkit-scrollbar-thumb {
			background: rgba(255, 255, 255, 0.32);
		}

		.episode-details-wrapper {
			display: flex;
			margin-bottom: 16px;
			cursor: pointer;
			.episode-image {
				width: 156px;
				height: 88px;
				filter: drop-shadow(0px 18px 26px rgba(0, 0, 0, 0.35));
				border-radius: 8px;
			}
			.episode-info {
				margin: auto;
				margin-left: 20px;
				.episode-number {
					font-family: 'TT-Commons-Demi';
					font-style: normal;
					font-weight: 600;
					font-size: 12px;
					line-height: 120%;
					color: rgba(255, 255, 255, 0.32);
				}
				.episode-name {
					font-family: 'TT-Commons-Medium';
					font-style: normal;
					font-weight: 400;
					font-size: 18px;
					line-height: 135%;
					color: #ffffff;
				}
			}
		}
	}
	.episode-details-expand {
		overflow-y: auto;
		margin: 24px 28px;
		max-height: 10000px;
		transition: max-height 2s ease-in-out;

		&::-webkit-scrollbar {
			width: 0px;
			height: 10px;
			background: transparent;
		}
		&::-webkit-scrollbar-track {
			background: transparent;
		}
		&::-webkit-scrollbar-thumb {
			background: rgba(255, 255, 255, 0.32);
		}

		.episode-details-wrapper {
			display: flex;
			margin-bottom: 16px;
			cursor: pointer;
			.episode-image {
				width: 156px;
				height: 88px;
				filter: drop-shadow(0px 18px 26px rgba(0, 0, 0, 0.35));
				border-radius: 8px;
			}
			.episode-info {
				margin: auto;
				margin-left: 20px;
				.episode-number {
					font-family: 'TT-Commons-Demi';
					font-style: normal;
					font-weight: 600;
					font-size: 12px;
					line-height: 120%;
					color: rgba(255, 255, 255, 0.32);
				}
				.episode-name {
					font-family: 'TT-Commons-Medium';
					font-style: normal;
					font-weight: 400;
					font-size: 18px;
					line-height: 135%;
					color: #ffffff;
				}
			}
		}
	}

	.span-more-less {
		cursor: pointer;
		display: flex;
		justify-content: center;
		font-family: 'TT-Commons-Medium';
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 120%;
		text-align: center;
		color: #f82c5e;
	}
}
