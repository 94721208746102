.picker-list{
    display: flex;
    flex-wrap: wrap; 
    width: 358px;
    margin-top: 4px;
    margin-left: -10px;
    li{
        flex: 0 0 9.5%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 6px;
        margin-right: 6px;
        .inactive-span{
            opacity: 0.5;
        }
        .active-span{
            background: rgba(248, 44, 94, 0);
            opacity: 1;
            border-radius: 8px;
            animation: bgFade .3s forwards;
        }
        @keyframes bgFade {
        from {  background: rgba(248, 44, 94, 0);}
        to {  background: rgba(248, 44, 94, 1);}
        }
        span{
            cursor: pointer;
            width: 38px;
            height: 38px;
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover {
                transform: scale(1.1);
            }
        }
        img{
            max-width: 25px;
            max-height: 25px;

        }
    }
}