@import '../../../common/utils/scss/variables.scss';

.button-container {
	padding: 8.8px 19px;
	background-color: $primary-default;
	display: flex;
	align-items: center;
	justify-content: space-around;
	border-radius: 8px;
	p {
		color: $color-white;
		padding-top: 4px;
	}

	.disabled {
		pointer-events: none;
		opacity: 0.5;
	}

	@media only screen and (max-width: 1080px) {
		padding: 4px 20px;

		p {
			letter-spacing: 0px;
		}
	}
}
.extra-styling {
	position: absolute;
	right: 10px;
}
.setting-styling {
	max-width: 127px;
	height: 46px;
	margin-left: 12px;
}
