.edit-modal-popup-animation-digest-content {
	transition: all 0.3s ease-in-out !important;
	animation: anim 0.2s forwards !important;
}

@keyframes anim {
	from {
		transform: translateX(100%);
	}
	to {
		transform: translateX(0%);
	}
}

.popup-content-container-digest-publish {
	transform: scale(0.9);
	margin-top: 140px;
	transition: all 0.3s ease-in-out;
	animation: modalAnim 0.2s forwards;
}
@keyframes modalAnim {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.popup-content-container-digest {
	transform: scale(0.9);
	margin-top: 140px;
	transition: all 0.3s ease-in-out;
	animation: modalAnimation 0.2s forwards;
}
@keyframes modalAnimation {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes overlayAnim {
	from {
		background: rgba(0, 0, 0, 0.1);
	}
	to {
		background: rgba(0, 0, 0, 0.86);
	}
}
