@import 'src/app/common/utils/scss/main.scss';

.carousel-content-tile {
	margin-right: 26px;
	min-width: 7.5%;
	cursor: pointer;
}
.vertical-search {
	margin-right: 0px;
}

.tile-label {
	padding: 0px 0.8em 0 0em;
	overflow: hidden;
	margin-top: 3px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	p {
		font-size: 1.25rem;
		font-style: normal;
		font-weight: 600;
		line-height: 1.5rem;
		letter-spacing: 0em;
		text-align: left;
		color: #ffffffb2;
		padding: 0;
	}
}
.new-icon {
	color: $primary-default;
	padding: 0.3em 0.5em 0.07em 0.5em;
	background-color: #fff;
	border-radius: 4px;
	position: absolute;
	margin-top: 0.5em;
	margin-left: 0.5em;
	p {
		font-family: 'TT-Commons-Demi';
		font-size: 12px;
		font-style: normal;
		font-weight: 600;
		line-height: 14px;
		letter-spacing: 0em;
		padding-top: 0.5px;
	}
}
.tv-batch {
	position: absolute;
	position: absolute;
	top: 6px;
	right: 6px;
	margin: 0;
	width: 30px;
	height: 24px;
	color: #fff;
	font-size: 10px;
	font-weight: 700;
	line-height: 16px;
	background-color: rgba(0, 0, 0, 0.6);
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 3px;
	border-radius: 4px;
	opacity: 0.8;
}
.image-parent-block {
	position: relative;
	filter: drop-shadow(0px 18px 26px rgba(0, 0, 0, 0.35));
	border-radius: 6px;
	overflow: hidden;
}
.add-icon {
	position: absolute;
	right: 12px;
	bottom: 15px;
	width: 20px;
	height: 20px;
	display: inline-block;
	background-color: rgba(0, 0, 0, 0.8);
	border-radius: 12px;
	& > img {
		width: 100%;
		height: 100%;
	}
}
.cardImg {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 6px;
	aspect-ratio: 0.67;
}

.cardImgPlaceholder {
	aspect-ratio: 0.666 !important;
}
