@import 'src/app/common/utils/scss/main.scss';
.otpScreenContainer {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 2;
	transition: transform 0.5s ease-in-out;

	.otpFormWrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-bottom: 100px;
		margin-left: 6px;

		.user-num {
			font-family: 'TT-Commons-Regular';
			font-size: 15px;
			line-height: 130%;
			letter-spacing: 1px;
			text-transform: uppercase;
			color: rgba(255, 255, 255, 0.42);
			margin-bottom: 2px;
			margin-left: -10px;
		}

		.emailEdit {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-bottom: 24px;
			margin-left: -10px;

			.emailSpan {
				font-family: 'TT-Commons-Regular';
				font-style: normal;
				font-weight: 600;
				font-size: 16px;
				line-height: 19px;
				color: rgba(255, 255, 255, 0.42);
				margin-right: 4px;
			}
			.PhoneInput {
				height: 28px;
				width: 70%;
				background-color: transparent;
				border-style: solid;
				border: none;
				// margin-bottom: 58px;
				color: #f82c5e;
				--PhoneInputCountryFlag-height: 1.5em;
				.PhoneInputInput {
					background-color: transparent;
					color: #f82c5e;
					font-style: normal;
					font-weight: bold;
					font-size: 24px;
					line-height: 115%;
					font-family: 'TT-Commons-Bold';
					border: none;
					padding-top: 4px;

					::placeholder {
						color: rgba(255, 255, 255, 0.42);
					}
				}
			}

			.edit-icon {
				cursor: pointer;
			}
		}

		.otpBoxes {
			margin-bottom: 8px;
			margin-left: -8px;
		}
		.timeRemainingDiv {
			font-family: 'TT-Commons-Regular';
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 19px;
			color: rgba(255, 255, 255, 0.42);
			width: 100%;
			margin-bottom: 59px;

			.timeRemainingSpan {
				float: right;
				margin-right: 10px;
			}
		}

		.verify-button-wrapper {
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			.verify-button-container {
				width: 145px;
				margin-bottom: 32px;

				.verify-button {
					background-color: #f82c5e;
					color: white;
					font-family: 'TT-Commons-Demi';
					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: center;
					padding: 11px 28px;
					width: 145px;
					height: 46px;
					border-radius: 8px;
					font-style: normal;
					font-weight: 600;
					font-size: 20px;
					line-height: 115%;
					margin-left: -10px;

					.otp-button-style {
						margin-top: 4px;
					}
				}
			}
			.resendSpan {
				color: rgba(255, 255, 255, 0.6);
				font-family: 'TT-Commons-Demi';
				margin-left: -10px;
				cursor: pointer;
			}
			.disabled {
				pointer-events: none;
				opacity: 0.5;
				color: rgba(255, 255, 255, 0.35);
			}
		}
	}
}

.otpScreenContainerTransform {
	transform: translateX(+250%);
}

.inputStyling {
	width: 46px !important;
	height: 56px !important;
	margin-right: 8px;
	background-color: transparent;
	border: 0.5px solid rgba(255, 255, 255, 0.1);
	border-radius: 12px;
	font-size: 20px;
	color: rgba(255, 255, 255, 0.7);
}
.inputStyling::placeholder {
	transform: translateY(-4px);
}
