@import '../../../common/utils/scss/variables.scss';
.qewd-digest-auto-populate-container {
	overflow-y: auto;
	height: 80vh;
	padding-bottom: 70px;
	&::-webkit-scrollbar {
		width: 3px;
		height: 25px;
		background: transparent;
	}
	&::-webkit-scrollbar-track {
		background: transparent;
	}
	&::-webkit-scrollbar-thumb {
		background: #888;
	}
	.auto-populate-content-container {
		display: flex;
		flex-direction: column;
		background: $disabled-bg;
		border-radius: 8px;
		padding: 32px 40.71px 36px 36px;
		margin-bottom: 20px;

		.title {
			font-style: normal;
			font-weight: bold;
			font-size: 28px;
			line-height: 112%;
			color: #ffffff;
			margin-bottom: 25px;
		}
		.cards {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr;
			grid-column-gap: 15px;
		}

		.video-error-message {
			font-size: 18px;
			line-height: 112%;
			color: #ffffff;
		}
	}
}

.search-box-container {
	.header-buttons {
		display: flex;
		align-items: center;

		.edit-button {
			border: none;
			background-color: $primary-default;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 47px;
			height: 47px;
			margin-right: 16.5px;

			img {
				margin: 0;
			}
		}
	}
}

.qwed-digest-heading-wrapper {
	display: flex;
	flex-direction: row;
	.qwed-digest-frequency-header {
		cursor: pointer;
		margin-left: 12px;
		padding: 5px 10px;
		height: 28px;
		background: #2a2a31;
		border-radius: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		.qwed-digest-frequency-span-content {
			font-family: 'TT-Commons-Demi';
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 120%;
			margin-top: 4px;
			display: flex;
			align-items: flex-end;

			color: rgba(255, 255, 255, 0.7);
		}
	}
}
.manually-button-div {
	margin-top: -55px;

	.preview-qwed-digest {
		margin-bottom: 6px;
		.preview-qwed-digest-text {
			font-weight: 600;
			font-size: 20px;
			line-height: 115%;
			color: #ffffff;
			padding: 13px 17px;
			border-radius: 8px;
			background: #2a2a31;

			span {
				line-height: 20px;
				display: inline-block;
				transform: translateY(2px);
			}
		}
	}
}
