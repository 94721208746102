@import 'src/app/common/utils/scss/main.scss';

#scrollableDivWaitlist {
	max-height: 70vh;
	overflow: auto;
	.infinite-scroll-component {
		margin-top: 40px;
		padding-bottom: 20px;
	}
	&.padding-bottom-increase {
		.infinite-scroll-component {
			padding-bottom: 110px;
		}
	}

	&::-webkit-scrollbar {
		width: 3px;
		height: 25px;
		background: transparent;
	}
	&::-webkit-scrollbar-track {
		background: transparent;
	}
	&::-webkit-scrollbar-thumb {
		background: #888;
	}
	.table {
		background: '#2A2A31';
		width: 100% !important;
		margin-top: 11px;
		border-collapse: separate;
		border-spacing: 0 2px;
		border: 'none';
		table-layout: auto;
		tbody {
			tr {
				transition: transform 1s ease;
				&.translateX {
					transform: translate(-10px, -10px);
				}
			}
		}

		thead {
			tr {
				position: absolute;
				transform: translateY(-74px);
				width: 100%;
				left: 0;
				right: 0;
				display: flex;
				align-items: center;
				border-radius: 8px 8px 0 0;
				z-index: 2;
			}
		}

		.column-head {
			vertical-align: middle;
		}

		tr:first-child {
			box-shadow: none;
			height: 74px;
			background: #363541;
			z-index: 2;
		}

		tr {
			&.odd {
				background: #2a2a31;
				box-shadow: 0px 16px 20px rgba(0, 0, 0, 0.25);
				height: 64px !important;
			}
			&.even {
				background: #26242c;
				box-shadow: 0px 16px 20px rgba(0, 0, 0, 0.25);
				height: 64px;
			}
			.grab-icon {
				margin-right: 28px;
				cursor: pointer;
			}
			span.name-span {
				padding-top: 2px;
			}
			span.email-span {
				padding-left: 2px;
			}

			td {
				font-family: 'TT-Commons-Regular';
				font-style: normal;
				font-weight: normal;
				font-size: 18px;
				line-height: 135%;
				color: rgba(255, 255, 255, 0.7);
				vertical-align: middle;
				&:first-child {
					padding-left: 29px;
					width: 29.1%;
				}
				&.row-name-approved {
					padding-left: 29px;
					width: 18.5%;
					word-break: break-word;
				}
				&.row-email {
					width: 35.2%;
					// .row-num {
					// 	font-size: 20px;
					// }
					.row-mail {
						font-size: 16px;
						color: rgba(255, 255, 255, 0.6);
						margin-top: 4px;
					}
				}
				&.row-source {
					width: 25%;
				}
				&.row-invite-url {
					width: 24%;
					padding-right: 30px;
					.url-wrapper {
						display: flex;
						.invite-url-span {
							margin-top: 3px;
						}
						.copy-icon {
							margin-left: 20px;
							// margin-top: 10px;
							position: relative;
							cursor: pointer;
						}
					}
				}
				&.row-email-approved {
					width: 17%;
					position: relative;
					padding-right: 30px;
					word-break: break-word;
					// .row-num {
					// 	font-size: 20px;
					// }
					.row-mail {
						font-size: 16px;
						color: rgba(255, 255, 255, 0.6);
						margin-top: 4px;
					}
				}
				&.row-source-approved {
					width: 16.5%;
				}
				&:last-child {
					display: flex;
					justify-content: flex-start !important;
					padding-top: 20px;
					position: relative;
					color: #f82c5e;
				}
				&.waitlist-row-date {
					color: rgba(255, 255, 255, 0.7);
					font-size: 17px !important;
					width: 14%;
				}
			}

			th {
				text-align: left;
				font-family: 'TT-Commons-Demi';
				font-style: normal;
				color: #fff;
				font-size: 22px;
				font-weight: 600;
				letter-spacing: 0;
				line-height: 164%;
				vertical-align: middle;
				padding-top: 0.5%;

				&:first-child {
					padding-left: 28px;
					border-radius: 8px 0px 0px 0px;
				}
				&:last-child {
					width: 150px;
					border-radius: 0px 8px 0px 0px;
				}
				&.name {
					width: 29.1%;
				}
				&.email {
					width: 35%;
				}
				&.actions {
					width: 10%;
				}
				&.name-approved {
					width: 19%;
				}
				&.email-approved {
					width: 18%;
				}
				&.invite {
					width: 25%;
				}
				&.request-source-approved {
					width: 17%;
				}
				&.approved-date {
					width: 15.5%;
				}
				&.actions-approved {
					width: 10%;
				}
				&.request-source {
					width: 25%;
				}
			}
		}

		.checkbox {
			display: inline-flex;
			vertical-align: middle;

			.bucket-name {
				padding-top: 3px;
			}

			#name-checkbox-row {
				appearance: none;
				-webkit-appearance: none;
				width: 19px;
				height: 19px;
				cursor: pointer;
				background: rgba(40, 40, 40, 0.2);
				color: black;
				-moz-appearance: none;
				border: 1px solid rgba(255, 255, 255, 0.32);
				box-sizing: border-box;
				border-radius: 4px;
				margin-right: 16px;
				margin-top: 4px;
			}

			#name-checkbox {
				appearance: none;
				-webkit-appearance: none;
				width: 19px;
				height: 19px;
				cursor: pointer;
				background: rgba(40, 40, 40, 0.2);
				color: black;
				-moz-appearance: none;
				border: 1px solid rgba(255, 255, 255, 0.32);
				box-sizing: border-box;
				border-radius: 4px;
				margin-right: 16px;
				margin-top: 7px;
				.extra-padding {
					padding-top: 32;
				}
			}
			#name-checkbox-row:checked,
			#name-checkbox:checked {
				background-color: #f82c5e;
				padding: 2px 3px;
				border: 0;
				&::after {
					content: '';
					width: 12px;
					height: 9px;
					background-image: url(../../../../../../assets/images/tick.svg);
					display: inline-block;
				}
			}
		}
		.extra-margin {
			margin-left: 40px;
		}

		.row {
			min-height: 64px;
			border-bottom: 1px solid #181a1f;
			border-top: 1px solid #181a1f;
			background-color: #2a2a31;
		}
	}
	.table thead tr:first-child {
		box-shadow: none;
		height: 74px;
		background: #363541;
	}

	@media only screen and (max-width: 1080px) {
		max-height: 70vh;
		.table {
			tr {
				th {
					padding-top: 1.2%;
					&:first-child {
						padding-left: 17px;
					}

					&.name {
						padding-right: 64px;
					}
				}
				td {
					padding-top: 6px;
					&:first-child {
						padding-left: 17px;
					}
					&:last-child {
						padding-top: 21px;
						width: 130px;
					}
				}
			}
		}
	}

	@media only screen and (min-width: 1280px) {
		max-height: 68.2vh;
	}
}
.empty-space {
	width: 19px;
	margin-right: 24px;
}

.row-email {
	padding-top: 3px;
	padding-left: 2.5px;
}
.approve-button {
	cursor: pointer;
}
