@import 'src/app/common/utils/scss/main.scss';

.right-panel-container {
	background: #15161c;
	width: 78%;
	padding: 28px;
	.right-panel-table-wrapper {
		position: relative;
	}
}

#scrollableDivWebviewList {
	max-height: 73.5vh !important;
	overflow: auto;
	padding-top: 10px;
	.infinite-scroll-component {
		padding-top: 53px;
		&::-webkit-scrollbar {
			width: 0px;
			height: 0px;
			background: transparent;
		}
		&::-webkit-scrollbar-track {
			background: transparent;
		}
		&::-webkit-scrollbar-thumb {
			background: transparent;
		}
	}
	&::-webkit-scrollbar {
		width: 3px;
		height: 25px;
		background: transparent;
	}
	&::-webkit-scrollbar-track {
		background: transparent;
	}
	&::-webkit-scrollbar-thumb {
		background: #888;
	}

	&.padding-bottom-increase {
		.infinite-scroll-component {
			padding-bottom: 110px;
		}
	}
	.table-webview {
		background: #2a2a31;
		width: 100% !important;
		border-collapse: separate;
		border-spacing: 0 2px;
		border: none;
		table-layout: auto;

		tbody {
			tr {
				transition: transform 1s ease;
				&.translateX {
					transform: translate(10px, -10px);
				}
			}
		}

		.column-head {
			vertical-align: middle;
		}
		thead {
			tr {
				box-shadow: none;
				height: 74px;
				background: #363541;
				position: absolute;
				left: 0;
				right: 0;
				z-index: 2;
				transform: translateY(-76px);
				width: 100%;
				display: flex;
				align-items: center;
				border-radius: 8px 8px 0 0;
			}
		}
		tr {
			&.odd {
				background: #2a2a31;
				box-shadow: 0px 16px 20px rgba(0, 0, 0, 0.25);
				height: 64px !important;
			}
			&.even {
				background: #26242c;
				box-shadow: 0px 16px 20px rgba(0, 0, 0, 0.25);
				height: 64px;
			}
			td {
				font-family: 'TT-Commons-Regular';
				font-style: normal;
				font-weight: 400;
				font-size: 18px;
				line-height: 135%;
				color: rgba(255, 255, 255, 0.7);
				vertical-align: middle;
				&.services-name-row {
					width: 80%;
					.services-name-row-wrapper {
						padding-left: 5%;
						display: flex;
						.service-logo {
							width: 52px;
							height: 25px;
							border-radius: 3px;
						}
						.services-name {
							font-family: 'TT-Commons-Regular';
							font-style: normal;
							font-weight: 400;
							font-size: 18px;
							line-height: 135%;
							color: rgba(255, 255, 255, 0.7);
							vertical-align: middle;
							margin-left: 14px;
						}
					}
				}

				&:last-child {
					border-radius: 0px 8px 0px 0px;
					margin-left: -10px;
				}

				&.service-checkbox {
					.checkbox {
						display: flex;
						align-items: center;
						margin-left: 50px;

						#services-checkbox-row {
							appearance: none;
							-webkit-appearance: none;
							width: 19px;
							height: 19px;
							cursor: pointer;
							background: rgba(40, 40, 40, 0.2);
							color: black;
							-moz-appearance: none;
							border: 1px solid rgba(255, 255, 255, 0.32);
							box-sizing: border-box;
							border-radius: 4px;
							margin-right: 16px;
							margin-top: 4px;
							margin-left: -1px;
							flex-grow: 0;
							flex-shrink: 0;
						}

						#services-checkbox {
							appearance: none;
							-webkit-appearance: none;
							width: 19px;
							height: 19px;
							cursor: pointer;
							background: rgba(40, 40, 40, 0.2);
							color: black;
							-moz-appearance: none;
							border: 1px solid rgba(255, 255, 255, 0.32);
							box-sizing: border-box;
							border-radius: 4px;
							margin-right: 16px;
							margin-top: 7px;
							.extra-padding {
								padding-top: 32;
							}
						}
						#services-checkbox-row:checked,
						#services-checkbox:checked {
							background-color: #f82c5e;
							padding: 2px 3px;
							border: 0;
							&::after {
								content: '';
								width: 12px;
								height: 9px;
								background-image: url(../../../assets/images/tick.svg);
								display: inline-block;
							}
						}
					}
				}
			}
		}
		th {
			text-align: left;
			font-family: 'TT-Commons-Demi';
			font-style: normal;
			color: #fff;
			font-size: 22px;
			font-weight: 600;
			letter-spacing: 0;
			line-height: 164%;
			vertical-align: middle;
			padding-top: 0.5%;
			&:first-child {
				padding-left: 4%;
				border-radius: 8px 0px 0px 0px;
				width: 80%;
			}
			&:last-child {
				border-radius: 0px 8px 0px 0px;
			}
		}
	}
}

#scrollableDivTags {
	max-height: 73.5vh !important;
	overflow: auto;
	padding-top: 10px;
	.infinite-scroll-component {
		padding-top: 53px;
	}
	&::-webkit-scrollbar {
		width: 3px;
		height: 25px;
		background: transparent;
	}
	&::-webkit-scrollbar-track {
		background: transparent;
	}
	&::-webkit-scrollbar-thumb {
		background: #888;
	}

	&.padding-bottom-increase {
		.infinite-scroll-component {
			padding-bottom: 110px;
		}
	}

	.table-tags {
		background: #2a2a31;
		width: 100% !important;
		border-collapse: separate;
		border-spacing: 0 2px;
		border: none;
		table-layout: auto;
		tbody {
			tr {
				transition: transform 1s ease;
				&.translateX {
					transform: translate(10px, -10px);
				}
			}
		}

		.column-head {
			vertical-align: middle;
		}
		thead {
			tr {
				box-shadow: none;
				height: 74px;
				background: #363541;
				position: absolute;
				left: 0;
				right: 0;
				z-index: 2;
				transform: translateY(-76px);
				width: 100%;
				display: flex;
				align-items: center;
				border-radius: 8px 8px 0 0;
			}
		}

		.checkbox {
			display: inline-flex;
			vertical-align: middle;

			.bucket-name {
				padding-top: 3px;
				cursor: pointer;
				&:hover {
					color: #f82c5e;
				}
			}

			#name-checkbox-row,
			.name-checkbox-row {
				appearance: none;
				-webkit-appearance: none;
				width: 19px;
				height: 19px;
				cursor: pointer;
				background: rgba(40, 40, 40, 0.2);
				color: black;
				-moz-appearance: none;
				border: 1px solid rgba(255, 255, 255, 0.32);
				box-sizing: border-box;
				border-radius: 4px;
				margin-right: 16px;
				margin-top: 12px;
				margin-left: -1px;
			}

			#name-checkbox {
				appearance: none;
				-webkit-appearance: none;
				width: 19px;
				height: 19px;
				cursor: pointer;
				background: rgba(40, 40, 40, 0.2);
				color: black;
				-moz-appearance: none;
				border: 1px solid rgba(255, 255, 255, 0.32);
				box-sizing: border-box;
				border-radius: 4px;
				margin-right: 16px;
				margin-top: 7px;
				.extra-padding {
					padding-top: 32;
				}
			}
			.name-checkbox-row:checked,
			#name-checkbox-row:checked,
			#name-checkbox:checked {
				background-color: #f82c5e;
				padding: 2px 3px;
				border: 0;
				&::after {
					content: '';
					width: 12px;
					height: 9px;
					background-image: url(../../../assets/images/tick.svg);
					display: inline-block;
				}
			}
		}

		tr {
			&.odd {
				background: #2a2a31;
				box-shadow: 0px 16px 20px rgba(0, 0, 0, 0.25);
				height: 64px !important;
			}
			&.even {
				background: #26242c;
				box-shadow: 0px 16px 20px rgba(0, 0, 0, 0.25);
				height: 64px;
			}

			td {
				font-family: 'TT-Commons-Regular';
				font-style: normal;
				font-weight: normal;
				font-size: 18px;
				line-height: 135%;
				color: rgba(255, 255, 255, 0.7);
				vertical-align: middle;
				word-break: break-word;
				&:first-child {
					// padding-left: 2.6%;
					padding-left: 3.2%;
					border-radius: 8px 0px 0px 0px;
					width: 33%;
				}
				&:last-child {
					padding-top: 0px;
					// position: relative;
				}
				.tag-name-container {
					display: flex;
					align-items: center;
					.tag-image-container {
						width: 40px;
						height: 40px;
						background: #181a1f;
						border-radius: 8px;
						display: flex;
						justify-content: center;
						align-items: center;
						img {
							width: 40px;
							height: 40px;
							border-radius: 8px;
						}
						.tag-icon-error {
							width: 24px;
							height: 24px;
							border-radius: 8px;
						}
					}

					.tag-name {
						font-family: 'TT-Commons-Medium';
						font-style: normal;
						font-weight: 400;
						font-size: 18px;
						line-height: 135%;
						color: rgba(255, 255, 255, 0.7);
						margin-left: 16px;
						display: flex;
						align-items: center;
					}
				}
				&.bucket-count-row {
					width: 21%;
					.bucket-count {
						width: 120px;
						position: relative;
						text-align: right;
					}
				}
				&.bucket-name {
					width: 36.5%;
					position: relative;
					.bucket-name-container {
						display: flex;
						.bucket-name-wrapper {
							display: flex;
							align-items: center;
							position: relative;
							max-width: 235px;
							&:hover {
								cursor: pointer;

								text-decoration: underline;
							}

							.bucket-names {
								padding-top: 4px;
								text-overflow: ellipsis;
								white-space: nowrap;
								overflow: hidden;
							}
						}
						.remain-count {
							margin-left: 6px;
							border: 2px solid #383746;
							border-radius: 12px;
							font-family: 'TT-Commons-Regular';
							font-style: normal;
							font-weight: 400;
							font-size: 16px;
							line-height: 120%;
							color: rgba(255, 255, 255, 0.7);
							cursor: pointer;
							display: flex;
							justify-content: center;
							align-items: center;
							padding-top: 3.5px;
							padding-right: 12px;
							padding-left: 12px;
						}
					}
				}
				&.row-options {
					position: relative;
					margin-right: 9px;
					text-align: center;
					.options {
						display: flex;
						align-items: center;
						justify-content: center;
						img {
							cursor: pointer;
						}
						.selected-dots {
							margin-right: 8px;
						}
					}
					.padding-more {
						margin-left: 9px;
					}
					.kebab-dropdown {
						z-index: 1;
						position: absolute;
						top: 37px;
						left: -68px;
						cursor: pointer;
					}
				}
			}
		}

		th {
			text-align: left;
			font-family: 'TT-Commons-Demi';
			font-style: normal;
			color: #fff;
			font-size: 22px;
			font-weight: 600;
			letter-spacing: 0;
			line-height: 164%;
			vertical-align: middle;
			padding-top: 0.5%;
			&:first-child {
				padding-left: 2.6%;
				border-radius: 8px 0px 0px 0px;
				width: 33%;
			}
			&:last-child {
				border-radius: 0px 8px 0px 0px;
				width: 8%;
			}
			&.bucket-count {
				width: 21%;
			}
			&.bucket-name {
				width: 38%;
			}
		}
	}
	@media screen and (max-width: 1495px) {
		.table-tags {
			tr {
				td {
					&:first-child {
						width: 31%;
					}
					&.bucket-count-row {
						width: 21%;
					}
					&.bucket-name {
						width: 32%;
					}
				}
			}
			th {
				&:first-child {
					width: 31%;
				}
				&.bucket-count {
					width: 20%;
				}
				&.bucket-name {
					width: 36%;
				}
			}
		}
	}
	@media only screen and (max-width: 1440px) {
		.table-tags {
			tr {
				td {
					&.bucket-name {
						.bucket-name-container {
							.bucket-name-wrapper {
								max-width: 175px;
							}
						}
					}
				}
			}
		}
	}
	@media only screen and (min-width: 700px) and (max-width: 1439px) {
		.table-tags {
			tr {
				td {
					&.bucket-name {
						.bucket-name-container {
							.bucket-name-wrapper {
								max-width: 125px;
							}
						}
					}
				}
			}
		}
	}
	@media only screen and (min-width: 1650px) {
		.table-tags {
			tr {
				td {
					&.bucket-name {
						.bucket-name-container {
							.bucket-name-wrapper {
								max-width: 315px;
							}
						}
					}
				}
			}
		}
	}
	@media only screen and (min-width: 2000px) {
		.table-tags {
			tr {
				td {
					&.bucket-name {
						.bucket-name-container {
							.bucket-name-wrapper {
								max-width: 415px;
							}
						}
					}
				}
			}
		}
	}
}
