/*MEDIA QUERIES*/

@mixin for-screen($point, $breakpoints: $breakpoints) {
    @if $point==mobile {
        @media (max-width: map-get($breakpoints, mobile)) {
            @content;
        }
    }
    @else if $point==tablet {
        @media (max-width: map-get($breakpoints, tablet)) {
            @content;
        }
    }
    @else if $point==smalldesktop {
        @media (max-width: map-get($breakpoints, smalldesktop)) {
            @content
        }
    }
}

@mixin pos-abs($top, $right, $bottom, $left) {
    position: absolute;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}

@mixin pos-fix($top, $right, $bottom, $left) {
    position: fixed;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}

@mixin transform($val) {
    -webkit-transform: $val;
    -moz-transform: $val;
    -ms-transform: $val;
    -o-transform: $val;
    transform: $val;
}

@mixin transition($time) {
    -webkit-transition: $time;
    -moz-transition: $time;
    -ms-transition: $time;
    -o-transition: $time;
    transition: $time;
}

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}