@import 'src/app/common/utils/scss/variables.scss';
.verify-container {
	width: 100%;
	height: 100%;
	padding: 40px;
	overflow-y: auto;
	&::-webkit-scrollbar {
		width: 3px;
		height: 25px;
		background: transparent;
	}
	&::-webkit-scrollbar-track {
		background: transparent;
	}
	&::-webkit-scrollbar-thumb {
		background: #888;
	}
	.error {
		font-family: 'TT-Commons-Medium';
		font-style: normal;
		font-size: 16px;
		line-height: 164%;
		text-align: center;
		color: #f82c5e;
		margin-top: -28px;
	}

	.cross-icon-popup {
		position: absolute;
		right: 14px;
		top: 14px;
		cursor: pointer;
	}

	.outer-border {
		width: 117px;
		height: 117px;
		margin: 0px auto;
		margin-top: 20px;
		border: none;
		border-radius: 50%;
		box-shadow: 0px 0px 0px 10px #1f1f23, 0px 0px 0px 20px rgba(31, 31, 35, 0.4);
		.avatar {
			border: none;
			border-radius: 50%;
			width: 117px !important;
			height: 117px !important;
		}
		.user-icon-error {
			display: flex;
			justify-content: center;
			align-items: center;
			.user-name-error-span {
				margin-top: 4px;
				font-family: 'TT-Commons-Medium';
				font-style: normal;
				font-size: 40px;
				line-height: 164%;
				text-align: center;
				color: #ffffff;
			}
		}
	}
	.user-info {
		margin-top: 1.5rem;
		.username {
			font-family: 'TT-Commons-Demi';
			font-style: normal;
			font-weight: 600;
			font-size: 22px;
			line-height: 164%;
			text-align: center;
			color: #ffffff;
		}
		.contact-info {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-top: 10px;
			.mobile {
				display: flex;
				.contact-icon {
					width: 20px;
					height: 20px;
					margin-right: 8px;
				}
				.contact-number {
					font-family: 'TT-Commons-Medium';
					font-style: normal;
					font-weight: 400;
					font-size: 18px;
					line-height: 135%;
					color: rgba(255, 255, 255, 0.7);
				}
			}
			.email {
				display: flex;
				.contact-icon {
					width: 20px;
					height: 20px;
					margin-right: 8px;
				}
				.email-address {
					font-family: 'TT-Commons-Medium';
					font-style: normal;
					font-weight: 400;
					font-size: 18px;
					line-height: 135%;
					color: rgba(255, 255, 255, 0.7);
				}
			}
			.vertical-seprator {
				width: 16px;
				height: 0px;
				border: 1px solid rgba(14, 14, 23, 0.2);
				transform: rotate(90deg);
			}
		}

		.qewd-info {
			margin-top: 1.625rem;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 1.5rem;
			.qewd-info-subcontainer {
				width: 101px;
				height: 63px;
				background: #1f1f23;
				border-radius: 12px;
				align-items: center;
				padding-top: 12px;
				margin-right: 8px;
				.info-count {
					font-family: 'TT-Commons-Demi';
					font-style: normal;
					font-weight: 600;
					font-size: 22px;
					line-height: 115%;
					text-align: center;
					color: rgba(255, 255, 255, 0.7);
				}
				.info-type {
					font-family: 'TT-Commons-Medium';
					font-style: normal;
					font-weight: 400;
					font-size: 16px;
					line-height: 115%;
					text-align: center;
					color: rgba(255, 255, 255, 0.3);
				}
			}
		}
		.seprator {
			width: 512px;
			height: 0px;
			border: 1px solid rgba(14, 14, 23, 0.2);
		}
	}

	.user-desc {
		margin-top: 24px;
		height: 48px;
		overflow-y: auto;
		padding-right: 10px;
		margin-top: 24px;
		height: 48px;
		overflow-y: auto;
		overflow-x: hidden;
		word-break: break-word;

		&::-webkit-scrollbar {
			width: 3px;
			height: 3px;
			background: transparent;
		}
		&::-webkit-scrollbar-track {
			background: transparent;
		}
		&::-webkit-scrollbar-thumb {
			background: #888;
		}
		.user-desc-span {
			font-family: 'TT-Commons-Medium';
			font-style: normal;
			font-weight: 400;
			font-size: 20px;
			line-height: 120%;
			color: rgba(255, 255, 255, 0.7);
		}
	}

	.tag-container {
		margin-top: 1.5rem;
		.tag-heading {
			font-family: 'TT-Commons-Medium';
			font-style: normal;
			font-weight: 400;
			font-size: 15px;
			line-height: 130%;
			letter-spacing: 1px;
			text-transform: uppercase;
			color: rgba(255, 255, 255, 0.42);
		}

		.pill-block {
			width: 100%;
			max-height: 95px;
			overflow-y: auto;
			padding: 0 20px 20px;
			padding-left: 0px;
			margin-top: 5px;

			.no-tags {
				display: flex;
				height: 75px;
				font-family: 'TT-Commons-Medium';
				font-style: normal;
				font-weight: 400;
				font-size: 22px;
				line-height: 130%;
				letter-spacing: 1px;
				color: rgba(255, 255, 255, 0.7);
				align-items: center;
				width: 100%;
			}

			&::-webkit-scrollbar {
				width: 3px;
				height: 25px;
				background: transparent;
			}
			&::-webkit-scrollbar-track {
				background: transparent;
			}
			&::-webkit-scrollbar-thumb {
				background: #888;
			}
		}
	}

	.switch-wrapper {
		display: flex;
		.business-account {
			margin-top: 2rem;
			.disable-input {
				opacity: 0.5;
				pointer-events: none;
			}
			.ba-heading {
				font-family: 'TT-Commons-Medium';
				font-style: normal;
				font-weight: 400;
				font-size: 15px;
				line-height: 130%;
				letter-spacing: 1px;
				text-transform: uppercase;
				color: rgba(255, 255, 255, 0.42);
			}
			.switch {
				position: relative;
				display: inline-block;
				width: 60px;
				height: 34px;
			}
			.switch input {
				opacity: 0;
				width: 0;
				height: 0;
			}
			.slider {
				position: absolute;
				cursor: pointer;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: #ccc;
				-webkit-transition: 0.4s;
				transition: 0.4s;
			}

			.slider:before {
				position: absolute;
				content: '';
				height: 26px;
				width: 26px;
				left: 4px;
				bottom: 4px;
				background-color: #181a1f;
				-webkit-transition: 0.4s;
				transition: 0.4s;
			}

			input:checked + .slider {
				background-color: #f82c5e;
			}

			input:focus + .slider {
				box-shadow: 0 0 1px #f82c5e;
			}

			input:checked + .slider:before {
				-webkit-transform: translateX(26px);
				-ms-transform: translateX(26px);
				transform: translateX(26px);
			}

			/* Rounded sliders */
			.slider.round {
				border-radius: 34px;
			}

			.slider.round:before {
				border-radius: 50%;
			}
		}

		.verify-user {
			margin-top: 2rem;
			margin-left: 70px;
			.disable-input {
				opacity: 0.5;
				pointer-events: none;
			}
			.vu-heading {
				font-family: 'TT-Commons-Medium';
				font-style: normal;
				font-weight: 400;
				font-size: 15px;
				line-height: 130%;
				letter-spacing: 1px;
				text-transform: uppercase;
				color: rgba(255, 255, 255, 0.42);
			}
			.switch {
				position: relative;
				display: inline-block;
				width: 60px;
				height: 34px;
			}
			.switch input {
				opacity: 0;
				width: 0;
				height: 0;
			}
			.slider {
				position: absolute;
				cursor: pointer;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: #ccc;
				-webkit-transition: 0.4s;
				transition: 0.4s;
			}

			.slider:before {
				position: absolute;
				content: '';
				height: 26px;
				width: 26px;
				left: 4px;
				bottom: 4px;
				background-color: #181a1f;
				-webkit-transition: 0.4s;
				transition: 0.4s;
			}

			input:checked + .slider {
				background-color: #f82c5e;
			}

			input:focus + .slider {
				box-shadow: 0 0 1px #f82c5e;
			}

			input:checked + .slider:before {
				-webkit-transform: translateX(26px);
				-ms-transform: translateX(26px);
				transform: translateX(26px);
			}

			/* Rounded sliders */
			.slider.round {
				border-radius: 34px;
			}

			.slider.round:before {
				border-radius: 50%;
			}
		}
	}
	.action-button {
		margin-top: 2.5rem;
		display: flex;
		align-items: center;
		justify-content: center;
		.sk-circle {
			margin: 0;
		}
		.cancel {
			margin-right: 24px;
			background: $sidebar-bg;
			border: 1px solid rgba(255, 255, 255, 0.1);
		}
		button {
			color: rgb(255, 255, 255);
			border: none;
			background: $primary-default;
			border-radius: 8px;
			padding: 7.5px 23px;
			font-weight: 600;
			font-size: 18px;
		}
		.disable {
			opacity: 0.5;
			pointer-events: none;
		}
	}
}
