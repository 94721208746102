.edit-button-container {
  background: #26242c;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 15px 14px 16px;
  border-radius: 12px;
  margin: 0px 12px;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}
