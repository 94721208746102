@import '../../common/utils/scss/variables.scss';

.filter-container {
	padding: 28px;
	overflow: hidden;
	background: $filter-background;
	border-radius: 8px 0px 0px 8px;
	isolation: isolate;
	color: $color-white;
	max-width: 22.8%;

	&-header {
		width: 100%;
		.heading {
			font-style: normal;
			font-weight: normal;
			font-size: 15px;
			line-height: 130%;
			letter-spacing: 1px;
			text-transform: uppercase;
			color: $filter-heading;
			margin-bottom: 8px;
		}
		.filter-buttons {
			display: flex;
			align-items: center;
			margin-bottom: 10px;
			animation: openPop 0.2s ease-in;
			@keyframes openPop {
				from {
					opacity: 0;
					transform: scale(0.7);
				}
				to {
					opacity: 1;
					transform: scale(1);
				}
			}
			.filter-button {
				line-height: 115%;
				color: $primary-default;
				padding: 8.5px 15px 8.5px 15px;
				background: $filter-button;
				border: 1px solid $gray-border;
				box-sizing: border-box;
				border-radius: 8px;
				font-weight: 600;
				font-size: 10px;
			}
			.opacity-40 {
				opacity: 0.4;
			}
			.margin-right {
				margin-right: 8px;
			}
		}
	}

	.content {
		padding-bottom: 50px;
		.sortByDropDown {
			position: relative;
			.select-span {
				width: 100%;
			}
			.option-box-wrapper {
				background: #181a1f;
				border-radius: 10px;
				width: 100%;
				padding-top: 7px;
				.sortByItems {
					border: none;
				}
				.sortByItems:hover {
					background: #26242c;
				}
			}
		}
		.header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			position: relative;
			.search-icon {
				margin-top: 26px;
				cursor: pointer;
			}

			.services-search-container {
				position: absolute;
				right: 0px;
				top: 26px;
				width: 0%;
				background: $body-background-color;
				border: 1px solid $primary-default;
				border-radius: 8px;

				&.hidden {
					visibility: hidden;
					width: 1%;
					opacity: 0;
					transition: opacity 0.3s linear, visibility 0.3s linear, width 0.3s linear;
				}

				&.visible {
					visibility: visible;
					width: 100%;
					opacity: 1;
					transition: opacity 0.3s linear, visibility 0.3s linear, width 0.3s linear;
				}

				#services-search {
					background: $body-background-color;
					height: 40px;
					width: 90%;
				}
			}
		}
		.Services-container {
			position: relative;
			.Services-3 {
				height: 264px;
				overflow: hidden;
			}
		}
		.Directors {
			transform: translateY(4px);
		}
		.MovieCast {
			transform: translateY(4px);
		}
		&-title {
			letter-spacing: 1px;
			font-style: normal;
			font-weight: 600;
			font-size: 22px;
			line-height: 115%;
			margin: 11px 0px;
			margin-top: 40px;
			margin-left: 1.1px;
		}
		.PopularMovies {
			margin-top: 0px;
		}
		.extra-options {
			display: flex;
			align-items: center;
			position: absolute;
			bottom: 0px;
			z-index: 2;
			transform: translateY(28px);
			.spacer {
				width: 20px;
				height: 20px;
				margin-right: 20px;
			}
			.more-than-10 {
				font-weight: 600;
				font-size: 16px;
				line-height: 120%;
				color: $primary-default;
				cursor: pointer;
			}
		}
	}
	@media only screen and (min-width: 1080px) and (max-width: 1499px) {
		max-width: 25%;
		.dashboard-search {
			width: 95%;
			.filter-box {
				width: 93%;

				&::placeholder {
					font-size: 14px;
				}
			}
		}
		&-header {
			.filter-buttons {
				.filter-button {
					font-size: 14px;
				}
				.margin-right {
					margin-right: 8px;
				}
			}
		}
	}
	@media only screen and (min-width: 1500px) {
		.dashboard-search {
			width: 95%;
			.filter-box {
				width: 93%;

				&::placeholder {
					font-size: 14px;
				}
			}
		}
		&-header {
			.filter-buttons {
				.filter-button {
					font-size: 16px;
				}
				.margin-right {
					margin-right: 10px;
				}
			}
		}
	}
	@media only screen and (max-width: 1080px) {
		max-width: 30%;
		.dashboard-search .filter-box {
			width: 13vw;

			&::placeholder {
				font-size: 14px;
			}
		}
		&-header {
			.filter-buttons {
				.filter-button {
					font-size: 13px;
				}
				.margin-right {
					margin-right: 8px;
				}
			}
		}
	}
}

.contentWrapper::-webkit-scrollbar {
	width: 3px;
	height: 3px;
}
.contentWrapper::-webkit-scrollbar-track {
	background: transparent;
}
.contentWrapper::-webkit-scrollbar-thumb {
	background: #888;
}

.contentWrapper {
	overflow: auto !important;
	max-height: 100%;
}

.fix-height {
	max-height: 73.5vh !important;
}

.disable-button {
	pointer-events: none;
	opacity: 0.5;
}
