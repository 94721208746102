.waitlist-right-container {
	margin-top: 1.5%;
	height: 100%;
	display: flex;
	flex-direction: column;

	.icon-button {
		width: 3.25em;
		height: 3.25em;
		background-color: #26242c;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.right-button-block {
		align-items: center;
		margin-bottom: 2.6875em;
		margin-top: 23px;
		img {
			margin-right: 1.25em;
		}
		.icon-button-span {
			margin-left: 20px;
		}
		img:last-child {
			margin-right: 0;
		}
		.waitlist-search-with-cancel {
			width: 30%;
			display: flex;
			align-items: center;
			margin-bottom: 24px;
			.cancel-button {
				margin-left: 10px;
				color: white;
				border: none;
			}
		}
		.tabs-pending {
			width: 100%;
			display: flex;
		}
	}
	.bucket-header-block {
		margin-bottom: 2.9%;
	}

	@media only screen and (max-width: 1080px) {
		margin-top: 5%;
		margin-left: 2px;
		max-height: 95vh;

		.bucket-header-block {
			margin-bottom: 3.5%;
		}

		.tab-button-block {
			margin-bottom: 0.7em;
		}
	}
}
