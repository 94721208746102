@import '../../common/utils/scss/variables.scss';
.big-card-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	min-width: 0;
	padding: 30px 1.2rem 53.08px 1.2rem;
	cursor: pointer;
	border: 2px solid transparent;
	background: linear-gradient(
		238.19deg,
		rgba(98, 99, 237, 0.1) 0%,
		rgba(99, 99, 236, 0.1) 6.67%,
		rgba(103, 97, 232, 0.1) 13.33%,
		rgba(110, 95, 225, 0.1) 20%,
		rgba(120, 91, 216, 0.1) 26.67%,
		rgba(133, 86, 204, 0.1) 33.33%,
		rgba(148, 81, 190, 0.1) 40%,
		rgba(164, 75, 174, 0.1) 46.67%,
		rgba(182, 68, 157, 0.1) 53.33%,
		rgba(198, 62, 141, 0.1) 60%,
		rgba(213, 57, 127, 0.1) 66.67%,
		rgba(226, 52, 115, 0.1) 73.33%,
		rgba(236, 48, 106, 0.1) 80%,
		rgba(243, 46, 99, 0.1) 86.67%,
		rgba(247, 44, 95, 0.1) 93.33%,
		rgba(248, 44, 94, 0.1) 100%
	);
	border-radius: 8px;
	&:hover {
		border: 2px solid $primary-default;
	}
	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 17px;
		.count {
			background: -webkit-linear-gradient(#6263ed, #f82c5e);
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-stroke: 3px transparent;
			color: #181a1f;
			padding: 1px;
			padding-top: 6px;
			font-size: 44px;
		}
	}
	.big-card-container-image {
		width: 100%;
		height: 197.58px;
		.big-card-img {
			filter: drop-shadow(0px 18px 26px rgba(0, 0, 0, 0.35));
			border-radius: 8px;
			width: 100%;
			height: inherit;
			object-fit: cover;
		}
	}
	.big-card-label {
		margin-top: 25.08px;
		p {
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 115%;
			color: #ffffff;
		}
	}
	.big-card-sub-title {
		p {
			font-family: 'TT-Commons-Regular';
			font-style: normal;
			font-weight: normal;
			font-size: 18px;
			line-height: 135%;
			display: flex;
			color: rgba(255, 255, 255, 0.7);
			transition: all 0.3s linear;
		}
		.collapse-description {
			height: 100px;
			overflow: hidden;
		}
		.show-description {
			height: fit-content;
			overflow: visible;
		}
		.more {
			font-size: 18px;
			font-weight: bold;
			color: $primary-default;
			border: none;
		}
	}
}
