@import 'src/app/common/utils/scss/variables.scss';
.edit-cast-container {
	padding: 32px;
	.edit-cast-heading {
		font-family: 'TT-Commons-Regular';
		font-style: normal;
		font-weight: 600;
		font-size: 22px;
		line-height: 124%;
		color: #ffffff;
		margin-bottom: 14px;
	}
	.cross-icon-popup {
		position: absolute;
		right: 14px;
		top: 14px;
		cursor: pointer;
		height: 12px;
		width: 12px;
	}
	.seprator {
		border: 1px solid rgba(14, 14, 23, 0.2);
	}
	.browse-file-wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-top: 30px;
		margin-bottom: 6px;
		.browse-heading {
			font-family: 'TT-Commons-Regular';
			font-style: normal;
			font-weight: 400;
			font-size: 20px;
			line-height: 120%;
			text-align: center;
			color: rgba(255, 255, 255, 0.7);
		}
		.browser {
			margin-top: 6px;
			.cast-file-picker-input {
				display: none;
			}
			.cast-file-picker {
				cursor: pointer;
				box-sizing: border-box;
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				padding: 17px 28px 15px;
				gap: 8px;
				width: 145px;
				height: 32px;
				background: #2a2a31;
				border: 1px solid rgba(255, 255, 255, 0.1);
				border-radius: 8px;
				.file-picker-cast-outerdiv {
					.file-picker-span {
						font-family: 'TT-Commons-Demi';
						font-style: normal;
						font-weight: 600;
						font-size: 18px;
						line-height: 96%;
						display: flex;
						align-items: flex-end;
						color: #f82c5e;
					}
				}
			}
		}
		.cors-error {
			color: #f82c5e;
			font-family: 'TT-Commons-Regular';
			margin-top: 10px;
			font-size: 16px;
			padding-left: 4px;
			text-align: center;
		}
	}
	.or-option {
		font-family: 'TT-Commons-Regular';
		font-style: normal;
		font-weight: 400;
		font-size: 20px;
		line-height: 120%;
		text-align: center;
		color: rgba(255, 255, 255, 0.32);
		margin-top: 16px;
		margin-bottom: 10px;
	}
	.copy-url {
		margin-bottom: 32px;
		.browse-heading {
			font-family: 'TT-Commons-Regular';
			font-style: normal;
			font-weight: 400;
			font-size: 20px;
			line-height: 120%;
			text-align: center;
			color: rgba(255, 255, 255, 0.7);
		}
		.add-url-input {
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 115%;
			color: #f82c5e;
			border: none;
			outline: none;
			background: transparent;
			width: 100%;
			transform: translateX(-2px);
			text-align: center;
		}
		.cors-error {
			color: #f82c5e;
			font-family: 'TT-Commons-Regular';
			margin-top: 10px;
			font-size: 16px;
			padding-left: 4px;
			text-align: center;
		}
	}
	.profile-container {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 32px;

		.image-profile {
			width: 100px;
			height: 100px;
			border-radius: 300px;
		}
		.image-profile-anim {
			animation: zoom-in-zoom-out-image 1.5s ease;
			@keyframes zoom-in-zoom-out-image {
				0% {
					transform: scale(0.2, 0.2);
				}
				100% {
					transform: scale(1, 1);
				}
			}
		}
		.cast-icon-error {
			display: flex;
			justify-content: center;
			align-items: center;
			.cast-name-error-span {
				font-size: 36px;
				margin-top: 8px;
				color: #fff;
			}
		}
	}
	.profile-deamed {
		margin-top: 0px;
		opacity: 0.3;
	}
	.progress-bar-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 32px;

		.cast-icon-error {
			display: flex;
			justify-content: center;
			align-items: center;
			.cast-name-error-span {
				font-size: 36px;
				margin-top: 8px;
				color: #fff;
			}
		}
		.progress-bar-div {
			width: 100px;
			height: 100px;
			margin-left: -100px;
			.progress-style {
				font-size: 26px;
				display: flex;
				justify-content: center;
				align-items: center;
				color: #ffffff;
				text-align: center;
				margin-top: 6px;
			}
			.tick-class {
				width: 40px;
				height: 40px;
			}

			.tick-class-open {
				animation: zoom-in-zoom-out 1s ease-in-out;
			}
			@keyframes zoom-in-zoom-out {
				0% {
					transform: scale(1, 1);
				}
				50% {
					transform: scale(1.5, 1.5);
				}
				100% {
					transform: scale(1, 1);
				}
			}
			.tick-class-close {
				animation: zoom-out-anim 1s ease-out;
			}
			@keyframes zoom-out-anim {
				0% {
					transform: scale(1, 1);
				}
				100% {
					transform: scale(0, 0);
				}
			}
		}
	}

	.buttons {
		display: flex;
		justify-content: center;
		.cancel {
			margin-right: 24px;
			background: $sidebar-bg;
			border: 1px solid rgba(255, 255, 255, 0.1);
		}
		button {
			color: rgb(255, 255, 255);
			border: none;
			background: $primary-default;
			border-radius: 8px;
			padding: 7.5px 23px;
			font-weight: 600;
			font-size: 18px;
		}
		.disable-button {
			pointer-events: none;
			opacity: 0.5;
		}
	}
}
