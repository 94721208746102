@import 'src/app/common/utils/scss/main.scss';
.loginFormWrapper {
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1;
	transition: transform 0.5s ease-in-out;

	.login-form {
		display: flex;
		flex-direction: column;

		.login-field-wrapper {
			margin-left: 100px;

			.fieldLabel {
				font-family: 'TT-Commons-Regular';
				font-size: 15px;
				line-height: 130%;
				letter-spacing: 1px;
				text-transform: uppercase;
				color: rgba(255, 255, 255, 0.42);
				margin-bottom: 2px;
				padding-left: 2px;
			}

			.PhoneInput {
				height: 28px;
				width: 100%;
				background-color: transparent;
				border-style: solid;
				border: none;
				margin-bottom: 58px;
				color: #f82c5e;
				--PhoneInputCountryFlag-height: 1.5em;
				.PhoneInputInput {
					background-color: transparent;
					color: #f82c5e;
					font-style: normal;
					font-weight: bold;
					font-size: 24px;
					line-height: 115%;
					font-family: 'TT-Commons-Bold';
					border: none;
					padding-top: 4px;

					::placeholder {
						color: rgba(255, 255, 255, 0.42);
					}
				}
			}
			.error-verbose {
				color: #eb2c5b;
				font-family: 'TT-Commons-Regular';
				font-style: normal;
				font-weight: 400;
				font-size: 16px;
				line-height: 120%;
				position: absolute;
				top: 38%;
			}
		}

		.login-button-wrapper {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;

			.login-button-container {
				width: 115px;

				.login-button {
					background-color: #f82c5e;
					color: white;
					font-family: 'TT-Commons-Demi';
					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: center;
					padding: 11px 28px;
					width: 124px;
					height: 46px;
					border-radius: 8px;
					font-style: normal;
					font-weight: 600;
					font-size: 20px;
					line-height: 115%;
					margin-bottom: 60px;
					margin-left: -10px;

					.login-button-screen {
						margin-top: 4px;
					}
				}

				.disabled {
					pointer-events: none;
					opacity: 0.5;
				}
			}
		}
	}

	.social-login-container {
		.top-head {
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 28px;
			.seprator-left,
			.seprator-right {
				background: rgba(255, 255, 255, 0.1);
				width: 30%;
				height: 1px;
				margin-left: 4px;
				margin-right: 4px;
				margin-top: -3px;
			}
			.social-login-heading {
				font-family: 'TT-Commons-Medium';
				font-style: normal;
				font-weight: 400;
				font-size: 15px;
				line-height: 130%;
				text-align: center;
				letter-spacing: 1px;
				text-transform: uppercase;
				color: rgba(255, 255, 255, 0.42);
			}
		}
		.body-container {
			display: flex;
			justify-content: center;
			align-items: center;
			.social-button-outer-wrapper {
				box-sizing: border-box;
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				padding: 16px 28px;
				gap: 8px;
				width: 100px;
				height: 56px;
				left: -0.5px;
				top: 0px;
				border: 1px solid rgba(255, 255, 255, 0.1);
				border-radius: 8px;
				margin-right: 20px;
				margin-left: 20px;
				cursor: pointer;
			}
		}
	}
}

.loginFormWrapperTransform {
	transform: translateX(-150%);
}
