.tag-popup-container {
	.tag-popup-cross {
		right: 14px;
		top: 14px;
		position: absolute;
		cursor: pointer;
		img {
			width: 12px;
			height: 12px;
		}
	}
	.tag-main-block {
		margin: 35px;
		position: relative;
		width: 640px;
		max-height: 357px;
		.tag-header {
			display: flex;
			justify-content: space-between;
			font-style: normal;
			font-weight: 600;
			font-size: 22px;
			line-height: 124%;
			.heading {
				color: #ffffff;
				font-family: 'TT-Commons-Medium';
			}
			.add-tags {
				font-family: 'TT-Commons-Bold';
				color: #f82c5e;
				cursor: pointer;
			}
		}
		.seprator {
			width: 100%;
			margin-top: 16px;
			border: 1px solid rgba(14, 14, 23, 0.2);
		}
		.pill-block {
			width: 100%;
			max-height: 306px;
			margin-top: 20px;
			overflow-y: auto;
			text-align: center;
			padding: 20px;
			&::-webkit-scrollbar {
				width: 3px;
				height: 25px;
				background: transparent;
			}
			&::-webkit-scrollbar-track {
				background: transparent;
			}
			&::-webkit-scrollbar-thumb {
				background: #888;
			}
		}
	}
}
