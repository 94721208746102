@import 'src/app/common/utils/scss/main.scss';

.tab-container {
	margin-top: 15px;
}

#scrollableDivReported {
	max-height: 70.5vh !important;
	overflow: auto;
	padding-top: 10px;
	margin-top: 40px;
	.infinite-scroll-component {
		padding-top: 53px;
	}
	&::-webkit-scrollbar {
		width: 3px;
		height: 25px;
		background: transparent;
	}
	&::-webkit-scrollbar-track {
		background: transparent;
	}
	&::-webkit-scrollbar-thumb {
		background: #888;
	}

	&.padding-bottom-increase {
		.infinite-scroll-component {
			padding-bottom: 110px;
		}
	}
	.table-reported-videos {
		background: #2a2a31;
		width: 100%;
		border-collapse: separate;
		border-spacing: 0 2px;
		border: none;
		table-layout: auto;
		tbody {
			tr {
				transition: transform 1s ease;
				&.translateX {
					transform: translate(10px, -10px);
				}
			}
		}

		.column-head {
			vertical-align: middle;
		}
		thead {
			tr {
				box-shadow: none;
				height: 74px;
				background: #363541;
				position: absolute;
				left: 0;
				right: 0;
				z-index: 2;
				transform: translateY(-76px);
				width: 100%;
				display: flex;
				align-items: center;
				border-radius: 8px 8px 0 0;
			}
		}
		tr {
			&.odd {
				background: #2a2a31;
				box-shadow: 0px 16px 20px rgba(0, 0, 0, 0.25);
				// min-height: 94px !important;
			}
			&.even {
				background: #26242c;
				box-shadow: 0px 16px 20px rgba(0, 0, 0, 0.25);
				// min-height: 94px !important;
			}
			td {
				font-family: 'TT-Commons-Regular';
				font-style: normal;
				font-weight: normal;
				font-size: 18px;
				line-height: 135%;
				color: rgba(255, 255, 255, 0.7);
				vertical-align: middle;
				padding-top: 15px;
				padding-bottom: 15px;
				position: relative;
				&:first-child {
					padding-left: 5px;
					width: 34.6%;
					position: relative;
					#name-checkbox-row {
						margin-top: 18px;
					}
					.title-video-url-wrapper {
						display: -webkit-inline-box;
						// width: 48%;
						.title-poster {
							width: 93.25px;
							height: 60.16px;
							border-radius: 8px;
						}
						.wrapper-title {
							margin-left: 3.75%;
							display: flex;
							flex-direction: column;
							justify-content: center;
							// width: 65%;
							position: relative;
							.heading-title {
								font-family: 'TT-Commons-Demi';
								font-style: normal;
								font-weight: 600;
								font-size: 18px;
								line-height: 96%;
								color: #ffffff;
								width: 70%;
								position: relative;
								word-break: break-word;
							}
							.url-title {
								cursor: pointer;
								font-family: 'TT-Commons-Regular';
								font-style: normal;
								font-weight: 400;
								font-size: 16px;
								line-height: 120%;
								color: rgba(255, 255, 255, 0.7);
								margin-top: 3px;
								width: 70%;
								position: relative;
								word-break: break-word;
							}
						}
					}
					.extra-spacing {
						padding-left: 6%;
					}
				}
				&.row-reason {
					width: 20%;
					padding-right: 3.5%;
					padding-left: 2%;
				}
				&.row-reported-time {
					padding-left: 1.5px;
					width: 17%;
				}
				&.row-frequency {
					width: 11%;
					padding-left: 3%;
				}
				&.row-reported-by {
					margin-top: 8%;
					display: inline-block;
					// transform: scale(1, -1);
					cursor: pointer;

					.reporters-avatar {
						position: relative;
						display: inline-block;
						border: 2px solid #2a2a31;
						border-radius: 50%;
						overflow: hidden;
						width: 32px;
						height: 32px;
					}
					.user-icon-error {
						height: 32px;
						font-family: 'TT-Commons-Regular';
						font-style: normal;
						font-weight: 400;
						font-size: 15px;
						line-height: 130%;
						text-align: center;
						letter-spacing: 1px;
						text-transform: uppercase;
						color: #ffffff;
						.user-name-error-span {
							display: flex;
							justify-content: center;
							align-items: center;
							height: 100%;
							margin-top: 2px;
						}
					}

					.reporters-avatar:not(:first-child) {
						margin-left: -10px;
					}

					.reporters-avatar img {
						width: 28px;
						height: 28px;
						display: block;
						// transform: scale(1, -1);
					}
					.more-wrapper {
						display: flex;
						align-items: center;
					}
					.remaining-count {
						margin-left: 12px;
						font-family: 'TT-Commons-Demi';
						font-style: normal;
						font-weight: 600;
						font-size: 16px;
						line-height: 120%;
						text-align: center;
						color: rgba(255, 255, 255, 0.7);
						margin-top: 4px;
					}
				}
				&.row-actions {
					width: 6%;
				}
			}
		}
		th {
			text-align: left;
			font-family: 'TT-Commons-Demi';
			font-style: normal;
			color: #fff;
			font-size: 22px;
			font-weight: 600;
			letter-spacing: 0;
			line-height: 164%;
			vertical-align: middle;
			padding-top: 0.5%;
			&:first-child {
				border-radius: 8px 0px 0px 0px;
				width: 36.6%;
			}
			&:last-child {
				border-radius: 0px 8px 0px 0px;
			}
			&.reason {
				width: 18%;
			}
			&.last-reported {
				width: 17%;
			}
			&.frequency {
				width: 11%;
			}
			&.reported-by {
				width: 11%;
			}
		}

		.checkbox {
			display: inline-flex;
			vertical-align: middle;
			margin-left: 24px;
			width: 100%;

			.title-span {
				padding-left: 109px; //22%;
			}

			#name-checkbox-row {
				appearance: none;
				-webkit-appearance: none;
				width: 19px;
				height: 19px;
				cursor: pointer;
				background: rgba(40, 40, 40, 0.2);
				color: black;
				-moz-appearance: none;
				border: 1px solid rgba(255, 255, 255, 0.32);
				box-sizing: border-box;
				border-radius: 4px;
				margin-right: 16px;
				margin-top: 4px;
				margin-left: -1px;
				flex-grow: 0;
				flex-shrink: 0;
			}

			#name-checkbox {
				appearance: none;
				-webkit-appearance: none;
				width: 19px;
				height: 19px;
				cursor: pointer;
				background: rgba(40, 40, 40, 0.2);
				color: black;
				-moz-appearance: none;
				border: 1px solid rgba(255, 255, 255, 0.32);
				box-sizing: border-box;
				border-radius: 4px;
				margin-right: 16px;
				margin-top: 7px;
				.extra-padding {
					padding-top: 32;
				}
			}
			#name-checkbox-row:checked,
			#name-checkbox:checked {
				background-color: #f82c5e;
				padding: 2px 3px;
				border: 0;
				&::after {
					content: '';
					width: 12px;
					height: 9px;
					background-image: url(../../../assets/images/tick.svg);
					display: inline-block;
				}
			}
		}
	}
	@media only screen and (max-width: 1330px) {
		.table-reported-videos {
			tr {
				td {
					&:first-child {
						width: 30%;
						.title-video-url-wrapper {
							.title-poster {
								display: none;
							}
						}
					}
					&.row-frequency {
						width: 13%;
					}
					&.row-actions {
						width: 8%;
					}
				}
			}
			th {
				&:first-child {
					width: 30%;
					.extra-margin-report {
						margin-left: 38.25px;
					}
				}
				&.frequency {
					width: 13%;
				}
				&.reported-by {
					width: 13%;
				}
			}
			.checkbox {
				.title-span {
					padding-left: 4px;
				}
				#name-checkbox-row {
					margin-right: 4px;
				}
			}
		}
	}
}
