@import 'src/app/common/utils/scss/variables.scss';

#scrollableDivVideoList {
	max-height: 73.5vh !important;
	overflow: auto;
	padding-top: 70px;
	padding-bottom: 40px;

	&::-webkit-scrollbar {
		width: 3px;
		height: 25px;
		background: transparent;
	}
	&::-webkit-scrollbar-track {
		background: transparent;
	}
	&::-webkit-scrollbar-thumb {
		background: #888;
	}
}
.table-bucket-details-yt {
	background: #2a2a31;
	width: 100% !important;
	border-collapse: separate;
	border-spacing: 0 2px;
	border: none;
	table-layout: auto;
	margin-left: 0px;

	&::-webkit-scrollbar {
		width: 0px;
		height: 0px;
		background: transparent;
	}
	&::-webkit-scrollbar-track {
		background: transparent;
	}
	&::-webkit-scrollbar-thumb {
		background: transparent;
	}

	tbody {
		tr {
			transition: transform 1s ease;
			&.translateX {
				transform: translate(0px, 0px);
			}
		}
		&::-webkit-scrollbar {
			width: 0px;
			height: 0px;
			background: transparent;
		}
		&::-webkit-scrollbar-track {
			background: transparent;
		}
		&::-webkit-scrollbar-thumb {
			background: transparent;
		}
	}

	.column-head {
		vertical-align: middle;
	}
	thead {
		tr {
			box-shadow: none;
			height: 74px;
			background: #363541;
			position: absolute;
			left: 0;
			right: 0;
			z-index: 2;
			transform: translateY(-72px);
			width: 100%;
			display: flex;
			align-items: center;
			border-radius: 8px 8px 0 0;
			margin: 0px;
		}
	}

	.checkbox {
		display: inline-flex;
		vertical-align: middle;

		.grab-icon {
			margin-right: 28px;
			cursor: pointer;
		}

		.bucket-name {
			padding-top: 3px;
			cursor: pointer;
			&:hover {
				color: #f82c5e;
			}
		}

		#name-checkbox-row {
			appearance: none;
			-webkit-appearance: none;
			width: 19px;
			height: 19px;
			cursor: pointer;
			background: rgba(40, 40, 40, 0.2);
			color: black;
			-moz-appearance: none;
			border: 1px solid rgba(255, 255, 255, 0.32);
			box-sizing: border-box;
			border-radius: 4px;
			margin-right: 16px;
			margin-top: 26px;
			margin-left: -1px;
		}
		.name-checkbox-row {
			appearance: none;
			-webkit-appearance: none;
			width: 19px;
			height: 19px;
			cursor: pointer;
			background: rgba(40, 40, 40, 0.2);
			color: black;
			-moz-appearance: none;
			border: 1px solid rgba(255, 255, 255, 0.32);
			box-sizing: border-box;
			border-radius: 4px;
			margin-right: 16px;
			margin-top: 26px;
			margin-left: -1px;
		}

		#name-checkbox {
			appearance: none;
			-webkit-appearance: none;
			width: 19px;
			height: 19px;
			cursor: pointer;
			background: rgba(40, 40, 40, 0.2);
			color: black;
			-moz-appearance: none;
			border: 1px solid rgba(255, 255, 255, 0.32);
			box-sizing: border-box;
			border-radius: 4px;
			margin-right: 16px;
			margin-top: 7px;
			.extra-padding {
				padding-top: 32;
			}
		}
		.name-checkbox-row:checked,
		#name-checkbox-row:checked,
		#name-checkbox:checked {
			background-color: #f82c5e;
			padding: 2px 3px;
			border: 0;
			&::after {
				content: '';
				width: 12px;
				height: 9px;
				background-image: url(../../../assets/images/tick.svg);
				display: inline-block;
			}
		}
	}

	tr {
		&.odd {
			background: #2a2a31;
			box-shadow: 0px 16px 20px rgba(0, 0, 0, 0.25);
			height: 96px;
			display: flex;
			align-items: center;
			position: relative;
		}
		&.even {
			background: #26242c;
			box-shadow: 0px 16px 20px rgba(0, 0, 0, 0.25);
			height: 96px;
			display: flex;
			align-items: center;
			position: relative;
		}

		td {
			font-family: 'TT-Commons-Regular';
			font-style: normal;
			font-weight: normal;
			font-size: 18px;
			line-height: 135%;
			color: rgba(255, 255, 255, 0.7);
			vertical-align: middle;
			word-break: break-word;
			position: relative;
			&:first-child {
				padding-left: 3.2%;
				border-radius: 8px 0px 0px 0px;
				width: 45% !important;
				position: absolute;
				padding-right: 12px;
			}
			&:last-child {
				padding-top: 0px;
				position: absolute;
				left: 95%;
			}
			.title-container {
				display: flex;
				align-items: center;
				.title-image-container {
					width: 100px !important;
					height: 70px !important;
					background: #181a1f;
					border-radius: 8px;
					display: flex;
					justify-content: center;
					align-items: center;
					img {
						width: 100px !important;
						height: 70px !important;
						border-radius: 8px;
					}
					.icon-error {
						width: 100px !important;
						height: 70px !important;
						border-radius: 8px;
					}
				}

				.title-name {
					font-family: 'TT-Commons-Regular';
					font-style: normal;
					font-weight: 400;
					font-size: 18px;
					line-height: 135%;
					color: rgba(255, 255, 255, 0.7);
					margin-left: 112px;
					display: flex;
					align-items: center;
					word-break: break-word;
					padding-right: 2rem;
					position: absolute;
					cursor: pointer;
				}
			}
			&.channel-row {
				width: 30% !important;
				position: absolute;
				left: 45%;
				cursor: pointer;
				.channel {
					position: relative;
				}
			}
			&.publishTime-row {
				width: 20% !important;
				left: 76%;
				position: absolute;
				.publishTimeRow {
					font-family: 'TT-Commons-Regular';
					font-style: normal;
					font-weight: 400;
					font-size: 18px;
					line-height: 135%;
					color: rgba(255, 255, 255, 0.7);
				}
			}
			&.row-options {
				margin-right: 9px;
				text-align: center;
				cursor: pointer;
			}
		}
	}

	th {
		text-align: left;
		font-family: 'TT-Commons-Demi';
		font-style: normal;
		color: #fff;
		font-size: 22px;
		font-weight: 600;
		letter-spacing: 0;
		line-height: 164%;
		vertical-align: middle;
		padding-top: 0.5%;

		&:first-child {
			padding-left: 2.6%;
			border-radius: 8px 0px 0px 0px;
			width: 45%;
		}
		&:last-child {
			border-radius: 0px 8px 0px 0px;
		}
		&.channel {
			width: 30%;
		}
		&.publishTime {
			width: 20%;
		}
		&.add {
			width: 5%;
		}
	}
}
