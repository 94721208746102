.video-popup-container {
	img {
		max-width: 16px;
		height: 16px;
		position: absolute;
		right: 10px;
		top: 18px;
		z-index: 10;
		cursor: pointer;
	}
}
