.custom-file-input-wrapper {
  .placeholder {
    //styleName: Caps / Medium;
    color: #ffffff6b;
    font-family: 'TT-Commons-Medium';
    font-size: 15px;
    line-height: 19px;
    letter-spacing: 1px;
    margin-bottom: 4px;
  }
  .input-box {
    max-width: 79px;
    color: #fff;
    font-family: 'TT-Commons-Regular';
    text-align: left;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;

  }
  .file-input-browse-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    scroll-behavior: smooth;
    & ul {
      opacity: 0;
      animation: opacList 0.4s 0.4s forwards;
    }
    
    @keyframes opacList {
      from {opacity: 0;}
      to {opacity: 1;}
    }
    
  }
}
.file-input-span {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 16px 11px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  /* Secondary/Low */
  background: #2A2A31;
  /* UI-base/Divider on Light */
  box-sizing: border-box;
  border-radius: 4px;
  img{
    margin-right: 12px;
  }
}
.hidden-input-box {
  display: none;
}