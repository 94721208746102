@import 'src/app/common/utils/scss/main.scss';

#scrollableDivCast {
	max-height: 73.5vh !important;
	overflow: auto;
	padding-top: 10px;
	.infinite-scroll-component {
		padding-top: 53px;
	}
	&::-webkit-scrollbar {
		width: 3px;
		height: 25px;
		background: transparent;
	}
	&::-webkit-scrollbar-track {
		background: transparent;
	}
	&::-webkit-scrollbar-thumb {
		background: #888;
	}

	.table-cast {
		background: #2a2a31;
		width: 100% !important;
		border-collapse: separate;
		border-spacing: 0 2px;
		border: none;
		table-layout: auto;
		tbody {
			tr {
				transition: transform 1s ease;
				&.translateX {
					transform: translate(10px, -10px);
				}
			}
		}

		.column-head {
			vertical-align: middle;
		}
		thead {
			tr {
				box-shadow: none;
				height: 74px;
				background: #363541;
				position: absolute;
				left: 0;
				right: 0;
				z-index: 2;
				transform: translateY(-76px);
				width: 100%;
				display: flex;
				align-items: center;
				border-radius: 8px 8px 0 0;
			}
		}

		tr {
			&.odd {
				background: #2a2a31;
				box-shadow: 0px 16px 20px rgba(0, 0, 0, 0.25);
				height: 64px !important;
			}
			&.even {
				background: #26242c;
				box-shadow: 0px 16px 20px rgba(0, 0, 0, 0.25);
				height: 64px;
			}
			&.deamed {
				opacity: 0.4;
			}
			td {
				font-family: 'TT-Commons-Regular';
				font-style: normal;
				font-weight: normal;
				font-size: 18px;
				line-height: 135%;
				color: rgba(255, 255, 255, 0.7);
				vertical-align: middle;
				&.cast-name-row {
					width: 25.5%;
					.cast-name-wrapper {
						display: inline-flex;
						align-items: center;
						margin-top: 2px;
						margin-left: 36px;
						cursor: pointer;
						position: relative;

						.cast-name {
							text-transform: capitalize;
							margin-top: 4px;
						}
						.extra-margin-top {
							margin-top: 4px;
						}
						.image-profile {
							width: 36px;
							height: 36px;
							border-radius: 300px;
							margin-right: 16.5px;
						}
						.edit-cast-wrapper {
							height: 22px;
							width: 22px;
							background: #2a2a31;
							position: absolute;
							left: 22px;
							top: 20px;
							border-radius: 50%;
							display: flex;
							padding-left: 4px;
							padding-top: 4px;
							.edit-cast-profile {
								width: 12px;
								height: 12px;
							}
						}

						.cast-icon-error {
							display: flex;
							justify-content: center;
							align-items: center;
							.cast-name-error-span {
								margin-top: 4px;
								color: #fff;
							}
						}
					}
				}
				&.cast-movies-shows-row {
					width: 60.5%;
					.cast-movies-shows {
						// font-size: 20px;
						margin-top: -1px;
						padding-right: 20%;
						word-break: break-word;
					}
				}
				&.popularity-row {
					.popularity-container {
						display: flex;
						align-items: center;
						cursor: pointer;
						.popularity-wrapper {
							display: flex;
							align-items: center;
							width: 90px;
							justify-content: end;
							.cast-popularity-score {
								font-family: 'TT-Commons-Regular';
								font-style: normal;
								font-weight: 400;
								font-size: 18px;
								line-height: 135%;
								text-align: right;
								color: rgba(255, 255, 255, 0.7);
							}
						}
					}

					.edit-icon {
						cursor: pointer;
						margin-top: 4px;
						margin-left: 16px;
					}

					.cast-popularity-count-input {
						background: transparent;
						border: 1px solid #5b5b79;
						box-sizing: border-box;
						border-radius: 8px;
						height: 38px;
						font-family: 'TT-Commons-Regular';
						font-style: normal;
						font-weight: 400;
						font-size: 18px;
						line-height: 135%;
						text-align: center;
						color: #f82c5e;
						width: 65px;
						padding-top: 4px;
						animation: modalAnim 2s forwards;
					}
					@keyframes modalAnim {
						from {
							opacity: 0;
						}
						to {
							opacity: 1;
						}
					}

					.loader-input {
						background-image: url('../../../assets/images/WhiteLoader.gif');
						background-size: 25px 25px;
						background-position: center center;
						background-repeat: no-repeat;
						font-size: 0px;
					}
				}
				&:last-child {
					border-radius: 0px 8px 0px 0px;
					margin-left: -10px;
				}
			}
		}

		th {
			text-align: left;
			font-family: 'TT-Commons-Demi';
			font-style: normal;
			color: #fff;
			font-size: 22px;
			font-weight: 600;
			letter-spacing: 0;
			line-height: 164%;
			vertical-align: middle;
			padding-top: 0.5%;
			.down-arrow {
				margin-left: 10px;
				cursor: pointer;
				margin-top: -1px;
			}
			.select-box-down-arrow-rotate {
				transform: rotate(180deg);
				transition: transform 0.3s linear;
			}
			.select-box-down-arrow-unrotate {
				transform: rotate(0);
				transition: transform 0.3s linear;
			}
			.change-color {
				color: #f82c5e;
			}
			&:first-child {
				padding-left: 4%;
				border-radius: 8px 0px 0px 0px;
				width: 25.5%;
			}
			&:last-child {
				border-radius: 0px 8px 0px 0px;
				width: 96px;
			}
			&.movies-shows {
				width: 60.5%;
			}
		}
	}
}
