@import '../../common/utils/scss/variables.scss';
.reported-right-container {
	margin-top: 1.5%;
	height: 100%;
	display: flex;
	flex-direction: column;
	.reported-header-block {
		display: flex;
		align-items: center;
	}

	.content-block {
		position: relative;
		// hiding scroll bar
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
		max-height: fit-content;
		.disable-button {
			opacity: 0.4;
			pointer-events: none;
			cursor: pointer;
		}
		.button-wrapper {
			margin-bottom: 16px;
			margin-top: 20px;
			.tabs-pending {
				width: 90%;
			}
			.reported-delete-button {
				position: absolute;
				width: 119px;
				height: 46px;
				border: 1px solid rgba(255, 255, 255, 0.1);
				box-sizing: border-box;
				border-radius: 8px;
				cursor: pointer;
				right: -4px;
				top: 20px;
			}
			.reported-delete-label {
				font-family: 'TT-Commons-Demi';
				font-style: normal;
				font-weight: 600;
				font-size: 20px;
				line-height: 115%;
				color: #fff;
				flex: none;
				order: 2;
				flex-grow: 0;
				margin: 0px 8px;
			}
		}
	}
}
