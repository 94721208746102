@import 'src/app/common/utils/scss/main.scss';

.loginScreenWrapper {
	background-size: cover;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	position: relative;
	height: 100vh;
	overflow: hidden;

	.leftLoginBlock {
		width: 49%;
		height: 100%;
		position: relative;

		.logoHeader {
			width: 100%;
			z-index: 2;
		}
		.parentContainer {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
			margin-top: -20.5%;
		}
	}

	.rightImageBlock {
		width: 51%;
		height: 100%;
		display: flex;
		justify-content: center;
		position: relative;
		z-index: 2;

		.login-screen-image {
			width: 100%;
		}
	}
}
