.header-wrapper {
	display: flex;
	flex-direction: row;
	.count-header-wrapper {
		font-family: 'TT-Commons-Demi';
		font-style: normal;
		font-weight: normal;
		font-size: 24px;
		line-height: 120%;
		display: flex;
		align-items: center;

		color: rgba(255, 255, 255, 0.42);
	}
}
