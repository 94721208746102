@import 'src/app/common/utils/scss/variables.scss';
.live-category-right-container {
	margin-top: 2%;
	height: 100%;
	display: flex;
	flex-direction: column;
	.live-category-header {
		font-family: 'TT-Commons-Demi';
		font-style: normal;
		font-weight: bold;
		font-size: 36px;
		line-height: 36px;
		color: rgba(255, 255, 255, 0.42);
		width: fit-content;
	}
	.live-category-header-buttons {
		display: flex;
		align-items: center;
		height: 47px;
		margin-left: auto;
		margin-right: 0px;
		justify-content: flex-end;
		margin-top: -25px;
		#tooltip.bottom-side {
			margin-top: 88px;
			right: 145px;
			left: auto;
		}

		#tooltip.bottom-side .tooltip-arrow {
			top: 0;
			left: 50%;
			margin-left: -5px;
			border-width: 0 5px 5px;
			border-bottom-color: #2a2a31;
		}

		.button-wrapper {
			display: flex;
			flex-direction: row;
		}
		.unselect-all-cards-icon {
			margin-left: 12px;
			cursor: pointer;
			transition: all 0.4s ease-in !important;
			animation: anim 0.4s forwards !important;
		}

		@keyframes anim {
			from {
				transform: translateX(100%);
			}
			to {
				transform: translateX(0%);
			}
		}

		.live-category-delete-button {
			width: 201px;
			height: 46px;
			background: #2a2a31;
			border: 1px solid rgba(255, 255, 255, 0.1);
			box-sizing: border-box;
			border-radius: 8px;
		}
		.disable-delete-button {
			pointer-events: none;
			opacity: 0.5;
		}
		.animate-button {
			transition: all 0.4s ease-in !important;
			animation: animOne 0.4s forwards !important;
		}
		@keyframes animOne {
			from {
				transform: translateX(0%);
			}
			to {
				transform: translateX(-2%);
			}
		}
		.live-category-delete-button-label {
			font-style: normal;
			font-family: 'TT-Commons-Demi';
			font-weight: 600;
			font-size: 20px;
			line-height: 23px;
			color: rgba(255, 255, 255, 0.7);
		}
		.live-category-delete-button-label-active {
			font-style: normal;
			font-family: 'TT-Commons-Demi';
			font-weight: 600;
			font-size: 20px;
			line-height: 23px;
			color: #f82c5e;
		}
		.live-category-add-button {
			border: none;
			background-color: $primary-default;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 47px;
			height: 47px;
			margin-right: 16px;
			img {
				margin: 0;
				border: none;
			}
		}
		.live-app-button {
			margin-right: 16px;
			.live-app-button-text {
				font-weight: 600;
				font-size: 20px;
				line-height: 115%;
				color: #ffffff;
				padding: 13px 15px;
				border-radius: 8px;

				span {
					line-height: 20px;
					display: inline-block;
					transform: translateY(2px);
				}
			}
			&:last-child {
				margin-right: 0px;
			}
		}
	}

	@media only screen and (max-width: 1080px) {
		margin-top: 5%;
		margin-left: 2px;
		max-height: 95vh;

		.bucket-header-block {
			margin-bottom: 3.5%;
		}

		.tab-button-block {
			margin-bottom: 0.7em;
		}
	}
}
