@import "../../common/utils/scss/variables.scss";

.inactive-dot {
  width: 6px;
  height: 6px;
  border: 1px solid rgba(255, 255, 255, 0.32);
  border-radius: 50%;
  margin: 0px 3px 0px 3px;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 0.3s linear;
}

.active-dot {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: 0px 3px 0px 3px;
  background-color: $primary-default;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s linear;
}
