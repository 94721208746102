@import 'src/app/common/utils/scss/main.scss';

@mixin sharedStyles($width, $paddingLeft) {
	display: flex;
	width: 100%;
	padding-left: $paddingLeft;
	position: relative;
	div {
		position: relative;
		z-index: 1;
		width: $width;
		left: 0;
		&.active {
			border-bottom: 0;
			position: relative;

			p {
				color: #f82c5e;
			}
		}
		border-radius: 8px 8px 0 0;
		padding: 17.5px 0 11.5px 0;
		p {
			color: #ffffff6b;
			cursor: pointer;
			text-align: center;
			width: 100%;
		}
	}
	.borderLine {
		position: absolute;
		width: $width;
		height: 58px;
		border: 1px solid #2a2a31;
		border-bottom: 0;
		top: 0;
		display: block;
		left: 0;
		border-radius: 8px 8px 0 0;
		transition: all 0.3s ease-in-out;
		&::after {
			content: '';
			display: inline-block;
			height: 4px;
			bottom: -2px;
			position: absolute;
			width: 100%;
			left: 0;
			background-color: $body-background-color;
		}
	}
}

.tab-global-container {
	@include sharedStyles(160px, 35px);
}

.qwed-digest-tabs {
	@include sharedStyles(150px, 35px);
	border-bottom: 1px solid #2a2a31;
}

.waitlist-tabs {
	@include sharedStyles(220px, 35px);
	border-bottom: 1px solid #2a2a31;
}
.live-channels-tab {
	@include sharedStyles(180px, 35px);
	border-bottom: 1px solid #2a2a31;
	margin-bottom: 20px;
}

$menuWidth: 160px;
$qwedDigestMenuWidth: 150px;
$waitlistWidth: 220px;
$liveMenuWidth: 180px;

@for $i from 1 through 4 {
	// when the Nav item "is active"
	.tab-global-container > div.active:nth-child(#{$i}) ~ .borderLine {
		left: (($menuWidth) * $i) - 125;
	}
	.qwed-digest-tabs > div.active:nth-child(#{$i}) ~ .borderLine {
		left: (($qwedDigestMenuWidth * $i) - 115);
	}
	.waitlist-tabs > div.active:nth-child(#{$i}) ~ .borderLine {
		left: (($waitlistWidth * $i) - 185);
	}
	.live-channels-tab > div.active:nth-child(#{$i}) ~ .borderLine {
		left: (($liveMenuWidth * $i) - 145);
	}
}
