@import '../../../../../../common/utils/scss/variables.scss';

.card-container {
	display: flex;
	flex-direction: column;
	border-radius: 5px;
	.tile-label {
		margin-top: 11.36px;
	}
	.on-delete-all {
		border: 3px solid #f82c5e;
		border-radius: 6px;
	}
	.on-unselecting {
		border: none;
	}
	.blur-image-progress {
		opacity: 0.6;
	}
	&-image {
		position: relative;
		width: 173px;
		height: 258.64px;
		img {
			max-width: 100%;
			max-height: 100%;
			box-shadow: 0px 18px 26px rgba(0, 0, 0, 0.35);
			border-radius: 6px;
		}
		.overlay {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			display: block;
			background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
			cursor: pointer;

			.show-progress {
				position: relative;
				display: flex;
				justify-content: center;
				height: 100%;
				align-items: center;

				.inprogress {
					display: flex;
					flex-direction: column;
					span {
						font-family: 'TT-Commons-Regular';
						font-style: normal;
						font-weight: normal;
						font-size: 16px;
						line-height: 120%;
						text-align: center;
						color: #ffffff;
						width: 111px;
						height: 19px;
					}
					img {
						margin: auto;
						margin-top: 4px;
						width: 21px;
						height: 5.5px;
					}
				}

				.failed {
					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: center;
					padding: 4px 12px;
					// position: absolute;
					width: 64px;
					height: 27px;
					background: #ff9129;
					border-radius: 20px;

					.failed-mssg {
						font-family: 'TT-Commons-Bold';
						font-style: normal;
						font-weight: 600;
						font-size: 16px;
						line-height: 120%;
						color: #ffffff;
						margin-top: 4px;
					}
				}
			}
		}
		.add-icon {
			position: absolute;
			right: 10.64px;
			bottom: 10.64px;
			z-index: 1;
			cursor: pointer;
			background-color: rgba(0, 0, 0, 0.8);
			border-radius: 12px;
		}
		.tv-batch {
			position: absolute;
			top: 6px;
			right: 6px;
			margin: 0;
			width: 30px;
			height: 24px;
			color: #fff;
			font-size: 10px;
			font-weight: 700;
			line-height: 16px;
			background-color: rgba(0, 0, 0, 0.6);
			display: flex;
			justify-content: center;
			align-items: center;
			padding-top: 3px;
			border-radius: 4px;
			opacity: 0.8;
		}
		.selection-button {
			input[type='checkbox'] {
				position: absolute;
				appearance: none;
				background: $filter-tag-color;
				border: 1px solid $filter-tag-color;
				width: 20px;
				height: 20px;
				border-radius: 50%;
				left: 5px;
				top: 5px;
				z-index: 2;
				cursor: pointer;

				&:checked {
					background: url('../../../../../../assets/images/Subtract.svg') no-repeat;
					background-position: center;
					border: 1px solid $primary-default;
				}
			}
		}
	}
	.card-container-image-yt {
		position: relative;
		aspect-ratio: 1.7 !important;
		img {
			max-width: 100%;
			max-height: 100%;
			box-shadow: 0px 18px 26px rgba(0, 0, 0, 0.35);
			border-radius: 6px;
		}
		.videoDetail {
			aspect-ratio: 1.7 !important;
		}

		.add-icon {
			position: absolute;
			right: 10.64px;
			bottom: 10.64px;
			z-index: 1;
			cursor: pointer;
			background-color: rgba(0, 0, 0, 0.8);
			border-radius: 12px;
		}
		.selection-button {
			input[type='checkbox'] {
				position: absolute;
				appearance: none;
				background: $filter-tag-color;
				border: 1px solid $filter-tag-color;
				width: 20px;
				height: 20px;
				border-radius: 50%;
				left: 5px;
				top: 5px;
				z-index: 2;
				cursor: pointer;

				&:checked {
					background: url('../../../../../../assets/images/Subtract.svg') no-repeat;
					background-position: center;
					border: 1px solid $primary-default;
				}
			}
		}
	}

	@media only screen and (min-width: 1151px) and (max-width: 1250px) {
		.tile-label {
			width: 88%;
		}
	}

	@media only screen and (min-width: 1251px) and (max-width: 1300px) {
		.tile-label {
			width: 80%;
		}
	}

	@media only screen and (min-width: 1410px) and (max-width: 1510px) {
		.tile-label {
			width: 90%;
		}
	}

	@media only screen and (min-width: 1510px) and (max-width: 1560px) {
		.tile-label {
			width: 85%;
		}
	}

	@media only screen and (min-width: 1301px) and (max-width: 1340px) {
		.tile-label {
			width: 76%;
		}
	}

	@media only screen and (min-width: 1680px) and (max-width: 1749px) {
		.tile-label {
			width: 90%;
		}
	}

	@media only screen and (min-width: 1750px) and (max-width: 1900px) {
		.tile-label {
			width: 87%;
		}
	}

	@media only screen and (min-width: 1901px) and (max-width: 2050px) {
		.tile-label {
			width: 82%;
		}
	}

	@media only screen and (min-width: 2051px) and (max-width: 2150px) {
		.tile-label {
			width: 75%;
		}
	}

	@media only screen and (min-width: 2151px) and (max-width: 2250px) {
		.tile-label {
			width: 70%;
		}
	}

	@media only screen and (min-width: 2251px) and (max-width: 2400px) {
		.tile-label {
			width: 65%;
		}
	}

	@media only screen and (min-width: 2401px) and (max-width: 2500px) {
		.tile-label {
			width: 60%;
		}
	}

	@media only screen and (min-width: 2501px) {
		.tile-label {
			width: 55%;
		}
	}
}
