@import '../../common/utils/scss/variables.scss';
.add-url-popup-content {
	.add-url-popup-content-container {
		.add-url-popup-message {
			color: #ffffff !important;
			font-size: 20px;
			font-weight: 600;
			line-height: 120%;
			padding: 32px;
			padding-bottom: 0px !important;
			font-family: 'TT-Commons-Demi';
			margin-bottom: 14px;
		}
		.add-url-popup-seperator {
			width: 100%;
			height: 0px;
			border: 1px solid rgba(14, 14, 23, 0.2);
			margin-bottom: 16px;
		}

		.add-url-channel-name,
		.add-url-name {
			font-style: normal;
			font-weight: normal;
			font-size: 15px;
			line-height: 130%;
			letter-spacing: 1px;
			text-transform: uppercase;
			color: rgba(255, 255, 255, 0.42);
		}

		.add-url-inputs {
			padding: 32px;
			padding-top: 20px;

			.disabled {
				opacity: 0.5;
			}

			input {
				font-style: normal;
				font-weight: bold;
				font-size: 24px;
				line-height: 115%;
				color: #ffffff;
				border: none;
				outline: none;
				background: transparent;
				width: 100%;
				transform: translateX(-2px);
			}

			.add-url-name-input {
				margin-bottom: 36px;
			}

			.error {
				color: $primary-default;
				font-style: normal;
				font-weight: bold;
				font-size: 18px;
				margin-top: 5px;
			}
		}
		.create-live-popup-file-picker {
			margin-left: 40px;
			margin-bottom: 30px;
			.file-picker-outerdiv {
				.file-picker-span {
					font-size: 18px !important;
				}
			}
			.create-live-popup-progressbar {
				margin-top: -10px;
			}
			.create-live-popup-progressbar-category {
				margin-top: -14px;
				z-index: 1000;
			}
			.upload-error-div {
				color: #f82c5e;
				font-family: 'TT-Commons-Regular';
				margin-top: 10px;
				font-size: 16px;
				padding-left: 4px;
			}
		}
		.file-picker-on-error {
			margin-bottom: 20px !important;
		}

		.buttons {
			display: flex;
			margin-right: 22px;
			justify-content: flex-end;
			.cancel {
				margin-right: 24px;
				background: $sidebar-bg;
				border: 1px solid rgba(255, 255, 255, 0.1);
			}
			button {
				color: rgb(255, 255, 255);
				border: none;
				background: $primary-default;
				border-radius: 8px;
				padding: 7.5px 23px;
				font-weight: 600;
				font-size: 18px;
			}
			.disable-button {
				pointer-events: none;
				opacity: 0.5;
			}
		}
	}
}
