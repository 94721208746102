@import 'src/app/common/utils/scss/main.scss';

#scrollableDivLiveStream::-webkit-scrollbar {
	width: 0px;
	height: 0px;
}

#scrollableDivLiveStream {
	max-height: 75vh;
	min-height: 50vh;
	overflow: auto;
	margin-top: 73px;
	height: 60vh; //erase in case any UI fluctuation.
	.infinite-scroll-component {
		padding-bottom: 110px;
	}

	&::-webkit-scrollbar {
		width: 3px;
		height: 25px;
		background: transparent;
	}
	&::-webkit-scrollbar-track {
		background: transparent;
	}
	&::-webkit-scrollbar-thumb {
		background: #888;
	}

	.table-live-stream {
		background: '#2A2A31';
		width: 100% !important;
		border-collapse: separate;
		border-spacing: 0 2px;
		border: 'none';
		table-layout: auto;
		tbody {
			border-radius: 8px 8px 0px 0px;
			tr {
				transition: transform 1s ease;
				&.translateX {
					transform: translate(-10px, -10px);
				}
			}
		}

		.column-head {
			vertical-align: middle;
		}

		tr:first-child {
			box-shadow: none;
			height: 74px;
			background: #363541;
			position: absolute;
			left: 0;
			right: 0;
			transform: translateY(-75px);
			z-index: 2;
			border-radius: 8px 8px 0px 0px;
		}

		tr {
			&.odd {
				background: #2a2a31;
				box-shadow: 0px 16px 20px rgba(0, 0, 0, 0.25);
				height: 64px !important;

				position: relative;
			}
			&.even {
				background: #26242c;
				box-shadow: 0px 16px 20px rgba(0, 0, 0, 0.25);
				height: 64px;

				position: relative;
			}
			.grab-icon {
				margin-right: 28px;
				cursor: pointer;
			}
			span.name-span {
				padding-top: 2px;
			}
			span.category-span {
				padding-left: 10px;
			}

			td {
				font-family: 'TT-Commons-Regular';
				font-style: normal;
				font-weight: normal;
				font-size: 18px;
				line-height: 135%;
				color: rgba(255, 255, 255, 0.7);
				vertical-align: middle;
				word-break: break-word;
				&:first-child {
					padding-left: 32px;
					width: 20%;
				}
				&:last-child {
					display: flex;
					justify-content: flex-start !important;
					padding-top: 20px;
					position: relative;
					width: 120px;
				}

				&.row-icon {
					width: 12%;
				}
				&.row-icon-all {
					width: 5%;
				}
				&.row-url {
					padding-right: 20px;
					width: 56%;
					cursor: pointer;
					&:hover {
						color: #f82c5e;
					}
				}
				&.row-url-popup {
					width: 70%;
					padding-right: 40px;
					cursor: pointer;
					&:hover {
						color: #f82c5e;
					}
				}
				&.row-url-all {
					padding-right: 20px;
					width: 36%;
					cursor: pointer;
					&:hover {
						color: #f82c5e;
					}
				}
				&.row-actions {
					padding-left: 30%;
				}
				&.extra-padding {
					padding-left: 50% !important;
				}
				&.row-category {
					width: 24%;
				}
			}

			th {
				text-align: left;
				font-family: 'TT-Commons-Demi';
				font-style: normal;
				color: #fff;
				font-size: 22px;
				font-weight: 600;
				letter-spacing: 0;
				line-height: 164%;
				vertical-align: middle;
				padding-top: 0.5%;
				width: 20%;
				padding-top: 25px;

				&:first-child {
					padding-left: 28px;
					border-radius: 8px 0px 0px 0px;
				}
				&:last-child {
					width: 120px;
					border-radius: 0px 8px 0px 0px;
				}
				&.name {
					// left: 0;
					width: 20%;
					position: absolute;
				}
				&.stream-url {
					left: 20%;
					position: absolute;
				}
				&.icon {
					left: 80%;
					position: absolute;
				}
				&.popup-icon {
					left: 90%;
					position: absolute;
				}
				&.actions {
					left: 91%;
					position: absolute;
				}
				&.sub-category {
					left: 57%;
					position: absolute;
				}
			}
		}

		.checkbox {
			display: inline-flex;
			vertical-align: middle;

			.bucket-name {
				padding-top: 3px;
				padding-right: 10px;
			}

			#name-checkbox-row {
				appearance: none;
				-webkit-appearance: none;
				width: 19px;
				height: 19px;
				cursor: pointer;
				background: rgba(40, 40, 40, 0.2);
				color: black;
				-moz-appearance: none;
				border: 1px solid rgba(255, 255, 255, 0.32);
				box-sizing: border-box;
				border-radius: 4px;
				margin-right: 16px;
				margin-top: 4px;
				margin-left: -1px;
				flex-grow: 0;
				flex-shrink: 0;
			}

			#name-checkbox {
				appearance: none;
				-webkit-appearance: none;
				width: 19px;
				height: 19px;
				cursor: pointer;
				background: rgba(40, 40, 40, 0.2);
				color: black;
				-moz-appearance: none;
				border: 1px solid rgba(255, 255, 255, 0.32);
				box-sizing: border-box;
				border-radius: 4px;
				margin-right: 16px;
				margin-top: 7px;
				.extra-padding {
					padding-top: 32;
				}
			}
			#name-checkbox-row:checked,
			#name-checkbox:checked {
				background-color: #f82c5e;
				padding: 2px 3px;
				border: 0;
				&::after {
					content: '';
					width: 12px;
					height: 9px;
					background-image: url(../../../assets/images/tick.svg);
					display: inline-block;
				}
			}
		}

		.row {
			min-height: 64px;
			border-bottom: 1px solid #181a1f;
			border-top: 1px solid #181a1f;
			background-color: #2a2a31;
		}
	}
	.table-live-stream thead tr:first-child {
		box-shadow: none;
		height: 74px;
		background: #363541;
	}

	@media only screen and (max-width: 1080px) {
		max-height: 77vh;
		.table-live-stream {
			tr {
				th {
					padding-top: 1.2%;
					&:first-child {
						padding-left: 17px;
					}

					&.name {
						padding-right: 64px;
						width: 305px !important;
					}
					&.icon {
						left: 75%;
						width: 180px !important;
					}
					&.popup-icon {
						left: 86%;
						width: 180px !important;
					}
					&.actions {
						left: 85%;
					}
				}
				td {
					padding-top: 6px;
					&:first-child {
						padding-left: 22px;
					}
					&:last-child {
						padding-top: 21px;
						width: 130px;
					}
				}
			}
		}
	}

	@media only screen and (min-width: 1280px) {
		max-height: 77.2vh;
	}
	@media only screen and (min-width: 1400px) {
		tr {
			th {
				&.icon {
					left: 78%;
				}
			}
		}
	}
	@media only screen and (min-width: 1599px) {
		.table-live-stream {
			tr {
				th {
					&.actions {
						left: 92%;
					}
				}
				td {
					&.row-url {
						padding-right: 0px;
						width: 56%;
						cursor: pointer;
					}
					&.row-url-popup {
						width: 70%;
					}
					&.row-actions {
						padding-left: 40%;
						width: auto;
					}
				}
			}
		}
	}
	@media only screen and (min-width: 1699px) {
		.stream-icon-deleted {
			left: 91.5% !important;
		}
	}
	@media only screen and (min-width: 2000px) {
		.stream-icon-deleted {
			left: 92.3% !important;
		}
	}

	@media only screen and (min-width: 2100px) {
		.table-live-stream {
			tr {
				td {
					&.row-url {
						padding-right: 0px;
						width: 55%;
					}
				}
			}
		}
		.stream-icon-deleted {
			left: 93% !important;
		}
	}
	@media only screen and (min-width: 2350px) {
		.stream-icon-deleted {
			left: 94% !important;
		}
	}
	@media only screen and (max-width: 1399px) {
		.table-live-stream {
			tr {
				td {
					&.row-url-all {
						width: 30%;
					}
					&.row-category {
						width: 28%;
					}
				}
				th {
					&.sub-category {
						left: 52%;
					}
				}
			}
		}
	}
	@media only screen and (max-width: 1249px) {
		.table-live-stream {
			tr {
				td {
					&.row-url-all {
						width: 25%;
					}
					&.row-category {
						width: 32%;
					}
				}
				th {
					&.sub-category {
						left: 47%;
					}
				}
			}
		}
	}
	@media only screen and (max-width: 1100px) {
		.table-live-stream {
			tr {
				td {
					&.row-url-all {
						width: 0%;
						display: none;
					}
					&.row-category {
						width: 37%;
					}
					&.row-icon-all {
						width: 15%;
					}
					&:first-child {
						width: 30%;
					}
					&.extra-padding {
						padding-left: 22% !important;
					}
				}
				th {
					&.sub-category {
						left: 30%;
					}
					&.stream-url {
						left: 20%;
						position: absolute;
						display: none;
					}
					&.icon {
						left: 72%;
					}
				}
			}
		}
	}
}
.category-capsule {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 4px 12px;
	border: 2px solid #383746;
	box-sizing: border-box;
	border-radius: 12px;
	width: fit-content;
	margin-left: 10px;
	color: rgba(255, 255, 255, 0.7);
}
.row-actions {
	padding-left: 20px;
	.live-stream-action-button {
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		// transform: translateX(-23px) !important;
		.live-stream-action-button-image {
			width: 20px;
			height: 18px;
		}
		.live-stream-action-button-image-exta-styling {
			width: 20px;
			height: 20px;
			margin-left: 10px;
		}
		.delete-icon {
			margin-right: 16.67px;
		}
	}
}
.row-category-div {
	display: flex;
	flex-direction: row;
	position: relative;
}
.category-more {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 10px;
	color: #f82c5e;
	font-family: 'TT-Commons-Regular';
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	font-weight: 600;
	letter-spacing: 0;
	line-height: 164%;
	margin-top: 7px;
	cursor: pointer;
}
.row-icon-img {
	max-width: 75px !important;
	max-height: 25px !important;
	border: none;
	margin-top: 3px;
	// margin-left: 5%;
}
.row-icon-img-none {
	display: none;
}
.increaseheight {
	height: 75vh !important;
}
.extra-margin-name {
	padding-left: 65px !important;
	width: 28% !important;
}
.stream-url-delete {
	left: 26% !important;
}
@media screen and (max-width: 1500px) {
	.tooltip {
		right: 64px !important;
	}
	.t-one:before {
		margin-left: -65px !important;
		-webkit-transform: rotate(90deg) !important;
		-moz-transform: rotate(90deg) !important;
		-ms-transform: rotate(90deg) !important;
		-o-transform: rotate(90deg) !important;
		transform: rotate(90deg) !important;
	}
}
