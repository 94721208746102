@import 'src/app/common/utils/scss/variables.scss';

.modal-container {
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	position: relative;
	padding: 32px 40px;
	border-radius: 12px;
	transition: max-height 0.5s linear;
	&-content {
		overflow-y: auto;
		overflow-x: hidden;
		width: 150%;
		height: 100%;
		transform: translateX(-30%);
		transition: all 0.3s ease-in-out;
		scroll-behavior: smooth;
		-ms-overflow-style: none;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			width: 3px;
			height: 25px;
			background: transparent;
		}
		&::-webkit-scrollbar-track {
			background: transparent;
		}
		&::-webkit-scrollbar-thumb {
			background: #888;
		}
		.switch-container-with-heading {
			.switch-container {
				transform: translateX(-6px);
			}
		}
		.custom-input-wrapper {
			background: transparent;
			left: 30%;
		}
		.screen-to-label {
			color: #ffffff6b;
			font-family: 'TT-Commons-Medium';
			font-size: 15px;
			line-height: 19px;
			letter-spacing: 1px;
			transform: translateX(30%);
			text-transform: uppercase;
			margin-bottom: 3px;
		}
		.status-container {
			transform: translateX(30%);
		}
		.custom-radio-buttons {
			transform: translateX(30%);
			display: flex;
			margin-bottom: 36px;
			.home-radio-buttons {
				background: #1f1f23;
				padding: 13px 20px 12px 20px;
				line-height: initial;
				margin-right: 32px;

				:nth-child(2) {
					margin-top: 7px;
				}
			}
			.discover-radio-buttons {
				background: #1f1f23;
				padding: 13px 20px 12px 20px;
				line-height: initial;
				:nth-child(2) {
					margin-top: 7px;
				}
			}
			.add-screen {
				margin-left: 32px;
			}
			.home-heading,
			.discover-heading {
				font-size: 24px;
				line-height: 115%;
				color: #ffffff;
			}
		}
		.custom-select-popup-wrapper {
			left: 30%;
		}
		.custom-file-input-wrapper {
			transform: translateX(30%);
		}

		.date-range-container {
			position: relative;
			left: 30%;
			z-index: 1;
			.date-range-div {
				left: -80%;
			}
		}

		.container-checkbox {
			margin-top: 5px;
			position: relative;
			left: 30%;
			margin-left: 2.5px;
			display: grid;
			margin-bottom: 36px;

			.switch {
				position: relative;
				display: inline-block;
				width: 44px;
				height: 24px;
			}
			.switch input {
				opacity: 0;
				width: 0;
				height: 0;
			}
			.slider {
				position: absolute;
				cursor: pointer;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: #ccc;
				-webkit-transition: 0.4s;
				transition: 0.4s;
			}

			.slider:before {
				position: absolute;
				content: '';
				height: 22px;
				width: 22px;
				left: 3px;
				bottom: 1px;
				background-color: #181a1f;
				-webkit-transition: 0.4s;
				transition: 0.4s;
			}

			input:checked + .slider {
				background-color: #f82c5e;
			}

			input:focus + .slider {
				box-shadow: 0 0 1px #f82c5e;
			}

			input:checked + .slider:before {
				-webkit-transform: translateX(16px);
				-ms-transform: translateX(16px);
				transform: translateX(16px);
			}

			/* Rounded sliders */
			.slider.round {
				border-radius: 34px;
			}

			.slider.round:before {
				border-radius: 50%;
			}
			.letter {
				font-family: 'TT-Commons-Medium';
				font-style: normal;
				font-weight: 600;
				font-size: 15px;
				line-height: 130%;
				letter-spacing: 1px;
				text-transform: uppercase;
				color: rgba(255, 255, 255, 0.42);
				margin-left: 25.5%;
			}
			.span-checkbox {
				font-family: 'TT-Commons-Medium';
				font-style: normal;
				font-weight: 600;
				font-size: 15px;
				line-height: 36px;
				letter-spacing: 1px;
				text-transform: uppercase;
				color: rgba(255, 255, 255, 0.42);
				margin-top: 4px;
			}
		}
		.ranked-bucket-checkbox {
			margin-top: 5px;
			position: relative;
			left: 30%;
			margin-left: 2.5px;
			margin-bottom: 36px;
			display: flex;
			align-items: center;

			.checkbox {
				display: flex;
				align-items: center;

				#ranked-check {
					appearance: none;
					-webkit-appearance: none;
					width: 19px;
					height: 19px;
					cursor: pointer;
					background: rgba(40, 40, 40, 0.2);
					color: black;
					-moz-appearance: none;
					border: 1px solid rgba(255, 255, 255, 0.32);
					box-sizing: border-box;
					border-radius: 4px;
				}

				#ranked-check:checked {
					background-color: #f82c5e;
					padding: 2px 3px;
					border: 0;
					&::after {
						content: '';
						width: 12px;
						height: 9px;
						background-image: url(../../../assets/images/tick.svg);
						display: inline-block;
					}
				}

				.ranked-label {
					font-style: normal;
					font-weight: 600;
					font-size: 20px;
					line-height: 100%;
					font-family: 'TT-Commons-Medium';
					color: $filter-heading;
					margin-left: 6px;
					margin-top: 4px;
				}
			}
		}
	}
	.close-button {
		color: #fff;
		position: absolute;
		top: 22px;
		right: 22px;
		cursor: pointer;
	}
	.modal-header {
		padding-top: 0;
		color: #fff;
		font-style: normal;
		font-weight: 600;
		font-size: 22px;
		line-height: 164%;
		padding-bottom: 12px;
		border-bottom: 1px solid #0e0e171f;
		margin-bottom: 28px;
		z-index: 1;
		position: relative;
		background-color: #2a2a31;
	}
	.action-button {
		display: flex;
		justify-content: flex-end;
		margin-top: 30px;
		span:last-child {
			margin-left: 24px;
		}
	}

	// @media only screen and (max-height: 768px) {
	//   max-height: max-content;
	// }
}
.d-block {
	display: block;
}
.d-none {
	display: none;
}
.popup-content-container {
	transform: scale(0.9);
	margin-top: 140px;
	transition: all 0.3s ease-in-out;
	animation: modalAnim 0.2s forwards;
}
@keyframes modalAnim {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
.popup-overlay {
	background: rgba(0, 0, 0, 0.1);
	animation: overlayAnim 0.3s forwards;
}
@keyframes overlayAnim {
	from {
		background: rgba(0, 0, 0, 0.1);
	}
	to {
		background: rgba(0, 0, 0, 0.86);
	}
}
