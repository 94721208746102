.custom-radio-button-container {
	position: relative;
    margin-top: 12px;
	transition: all 0.4s linear;
	animation: openPop 0.3s forwards;
	@keyframes openPop {
		from {
			opacity: 0;
			transform: scale(0.5);
		}
		to {
			opacity: 1;
			transform: scale(1);
		}
	}
	input[type='radio'] {
		position: absolute;
		visibility: hidden;
	}

	label {
		display: block;
		position: relative;
		font-weight: 300;
		font-size: 18px;
		padding-left: 28px;
		z-index: 1;
		cursor: pointer;
		transition: all 0.25s linear;
		color: rgba(255, 255, 255, 0.7);
	}

	:hover label {
		color: #ffffff;
	}

	.check {
		display: block;
		position: absolute;
		border: 1px solid rgba(255, 255, 255, 0.1);
		border-radius: 100%;
		height: 17px;
		width: 17px;
		top: 0px;
		left: 0px;
		z-index: 5;
		transition: all 0.25s linear;
		-webkit-transition: all 0.25s linear;
        z-index: 0;
	}

	:hover .check {
		border: 5px solid #ffffff;
	}

	input[type='radio']:checked ~ .check {
		border: 4px solid #f82c5e;
	}

	input[type='radio']:checked ~ label {
		color: #ffffff;
        font-weight: 600;
	}
}
