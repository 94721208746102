@import 'src/app/common/utils/scss/variables.scss';

.video-detail-screen-container {
	width: 100%;
	position: relative;
	margin-top: 1.2rem;
	padding-right: 7px;
	display: flex;
	flex-direction: column;
	height: 100%;
	.header-parent-block {
		display: flex;
		flex-direction: column;
	}
	.header-block {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		margin-bottom: 25px;
		.view-tags {
			font-family: 'TT-Commons-Medium';
			font-style: normal;
			font-weight: 600;
			font-size: 20px;
			line-height: 115%;
			color: #f82c5e;
			cursor: pointer;
		}
	}
	.dashboard-button-block {
		display: flex;
		align-items: center;
		justify-content: space-between;
		transform: translate(0px, -2px);
		button:first-child {
			padding: 8.8px 16px;
		}
	}
	.search-box-block {
		margin-bottom: 25px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		.aside-buttons {
			display: flex;
			align-items: center;
			#tooltip.bottom-side {
				margin-top: 120px;
				right: 195px;
				left: auto;
			}

			#tooltip.bottom-side .tooltip-arrow {
				top: 0;
				left: 50%;
				margin-left: -5px;
				border-width: 0 5px 5px;
				border-bottom-color: #2a2a31;
			}

			.unselect-all-cards-icon {
				margin-left: 18px;
				cursor: pointer;
			}
			.change-layout-icon {
				height: 46px;
				width: 46px;
				margin-left: 12px;
				cursor: pointer;
			}
			.no-margin {
				margin-right: 0px;
			}
		}
	}
	.header-parent-block {
		display: flex;
		flex-direction: column;
	}
	.popup-overlay {
		background: rgba(0, 0, 0, 0.1);
		animation: overlayAnim 0.3s forwards;
	}

	.empty-state-container {
		// width: 100vh;
		overflow: hidden;
		height: 80vh;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		.loader-container {
			transform: translateX(15%);
			margin-top: -20%;
		}
	}
	.empty-image {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		// transform: translateX(15%);

		img {
			margin-bottom: 15.3px;
		}
		.no-filter-found {
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 115%;
			color: #ffffff;
		}
		.please-change-filter {
			font-style: normal;
			font-weight: normal;
			font-size: 18px;
			line-height: 135%;
			text-align: center;
			color: rgba(255, 255, 255, 0.7);
		}
	}

	@keyframes overlayAnim {
		from {
			background: rgba(0, 0, 0, 0.1);
		}
		to {
			background: rgba(0, 0, 0, 0.86);
		}
	}

	.yt-video-container {
		position: relative;
		overflow-y: scroll;
		margin-top: 14px;
		height: 70vh;
		width: 100%;
		.infinite-scroll-component::-webkit-scrollbar {
			width: 3px;
			height: 10px;
		}
		.infinite-scroll-component::-webkit-scrollbar-track {
			background: transparent;
		}
		.infinite-scroll-component::-webkit-scrollbar-thumb {
			background: #ccc;
		}
		&::-webkit-scrollbar {
			width: 3px;
			height: 25px;
			background: transparent;
		}
		&::-webkit-scrollbar-track {
			background: transparent;
		}
		&::-webkit-scrollbar-thumb {
			background: #888;
		}
		.selection-button {
			font-style: normal;
			font-weight: 600;
			font-size: 18px;
			line-height: 96%;
			font-family: 'TT-Commons-Regular';
			color: #ffffff;
			// margin-top: -20px;

			.span-delete-selected {
				font-style: normal;
				font-weight: 600;
				font-size: 18px;
				line-height: 96%;
				font-family: 'TT-Commons-Bold';
				color: #f82c5e;
			}
			.span-delete-unselected {
				font-style: normal;
				font-weight: 600;
				font-size: 18px;
				line-height: 96%;
				font-family: 'TT-Commons-Medium';
				color: #ffffff;
			}

			input[type='checkbox'] {
				position: absolute;
				appearance: none;
				background: $filter-tag-color;
				border: 1px solid $filter-tag-color;
				width: 20px;
				height: 20px;
				border-radius: 50%;
				left: 10px;
				top: 12px;
				z-index: 2;
				cursor: pointer;
				margin-right: 12px;

				&:checked {
					background: url('../../../assets/images/Subtract.svg') no-repeat;
					background-position: center;
					border: 1px solid $primary-default;
				}
			}
			#check {
				position: relative;
				appearance: none;
				background: $filter-tag-color;
				border: 1px solid $filter-tag-color;
				width: 20px;
				height: 20px;
				border-radius: 50%;
				left: 5px;
				top: 8px;
				z-index: 2;
				cursor: pointer;
				margin-right: 12px;

				&:checked {
					background: url('../../../assets/images/Subtract.svg') no-repeat;
					background-position: center;
					border: 1px solid $primary-default;
				}
			}
		}
		.gridContainer {
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
			row-gap: 24px;
			column-gap: 24px;
			margin-top: 20px;
		}
		&::-webkit-scrollbar {
			display: none;
		}
	}
	.loadWrap {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		position: absolute;
		height: 100%;
	}

	.shuffle-mode-none {
		display: none;
	}
	.shuffle-mode {
		display: flex;
		align-items: center;
		.ordinary {
			font-family: 'TT-Commons-Regular';
			font-style: normal;
			font-weight: 400;
			font-size: 18px;
			line-height: 135%;
			color: #ffffff;
		}
		.highlighted {
			font-family: 'TT-Commons-Regular';
			font-style: normal;
			font-weight: 400;
			font-size: 18px;
			line-height: 135%;
			color: #f82c5e;
			cursor: pointer;
		}
	}
}
