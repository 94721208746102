@import 'src/app/common/utils/scss/main.scss';

#scrollableDiv {
	max-height: 75vh;
	overflow: auto;
	.infinite-scroll-component {
		padding-bottom: 250px;
		padding-top: 40px;
		min-width: 50vh;
		min-height: 70vh;
	}
	.infinite-scroll-live-box {
		padding-bottom: 400px;
	}
	&::-webkit-scrollbar {
		width: 3px;
		height: 25px;
		background: transparent;
	}
	&::-webkit-scrollbar-track {
		background: transparent;
	}
	&::-webkit-scrollbar-thumb {
		background: #888;
	}
	.table {
		background: '#2A2A31';
		width: 100% !important;
		margin-top: 4.5px;
		border-collapse: separate;
		border-spacing: 0 2px;
		border: 'none';
		table-layout: auto;
		tbody {
			tr {
				transition: transform 1s ease;
				&.translateX {
					transform: translate(-10px, -10px);
				}
			}
		}

		.column-head {
			vertical-align: middle;
		}

		tr:first-child {
			box-shadow: none;
			height: 74px;
			background: #363541;
			position: absolute;
			left: 0;
			right: 0;
			z-index: 2;
			transform: translateY(-74px);
			width: 100%;
			display: flex;
			align-items: center;
			border-radius: 8px 8px 0 0;
		}

		tr {
			&.odd {
				background: #2a2a31;
				box-shadow: 0px 16px 20px rgba(0, 0, 0, 0.25);
				height: 64px !important;
				cursor: pointer;
			}
			&.even {
				background: #26242c;
				box-shadow: 0px 16px 20px rgba(0, 0, 0, 0.25);
				height: 64px;
				cursor: pointer;
			}
			.grab-icon {
				margin-right: 28px;
				cursor: pointer;
			}
			span.name-span {
				padding-top: 2px;
			}
			span.status-span {
				padding-top: 4.5px;
				padding-left: 5px;
			}
			span.video-span {
				padding-top: 4.5px;
			}
			span.screen-span {
				padding-top: 4.5px;
			}

			td {
				font-family: 'TT-Commons-Regular';
				font-style: normal;
				font-weight: normal;
				font-size: 18px;
				line-height: 135%;
				color: rgba(255, 255, 255, 0.7);
				vertical-align: middle;
				&:first-child {
					padding-left: 29px;
					width: 22%;
				}
				&.status-field {
					width: 16%;
				}
				&.row-dateRange {
					width: 20%;
				}
				&.row-dateRange-archived {
					width: 20%;
				}
				&.row-description {
					width: 30.5%;
					word-break: break-word;

					.collapse-description {
						height: 50px;
						overflow: hidden;
						font-family: 'TT-Commons-Regular';
						font-style: normal;
						font-weight: 400;
						font-size: 18px;
						line-height: 135%;
						color: rgba(255, 255, 255, 0.7);
						padding-right: 3px;
					}
					.show-description {
						height: fit-content;
						overflow: visible;
						font-family: 'TT-Commons-Regular';
						font-style: normal;
						font-weight: 400;
						font-size: 18px;
						line-height: 135%;
						color: rgba(255, 255, 255, 0.7);
						padding-right: 3px;
					}
					.more {
						font-size: 18px;
						font-weight: bold;
						color: #f82c5e;
						border: none;
					}
					.in-center {
						display: flex;
						align-items: center;
					}
				}
				&.row-description-archived {
					width: 36.5%;
					word-break: break-word;

					.collapse-description {
						height: 50px;
						overflow: hidden;
						font-family: 'TT-Commons-Regular';
						font-style: normal;
						font-weight: 400;
						font-size: 18px;
						line-height: 135%;
						color: rgba(255, 255, 255, 0.7);
						padding-right: 3px;
					}
					.show-description {
						height: fit-content;
						overflow: visible;
						font-family: 'TT-Commons-Regular';
						font-style: normal;
						font-weight: 400;
						font-size: 18px;
						line-height: 135%;
						color: rgba(255, 255, 255, 0.7);
						padding-right: 3px;
					}
					.more {
						font-size: 18px;
						font-weight: bold;
						color: #f82c5e;
						border: none;
					}
					.in-center {
						display: flex;
						align-items: center;
					}
				}
				&.row-videoCount {
					text-align: right;
					width: 116px !important;
					padding-right: 58px;
				}
				&.row-liveCount {
					text-align: right;
					width: 176px !important;
					padding-right: 58px;
				}
				&:nth-last-child(2) {
					padding-left: 4px;
					width: 10%;
				}
				&:last-child {
					position: relative;
				}
				.three-dots {
					display: flex;
					justify-content: center;
					position: absolute;
					right: 60px;
					margin-top: -12px;

					.table-kebab-dropdown {
						right: 92%;
					}
				}
				&:last-child:hover {
					color: #f82c5e;
				}
				&.status-field {
					.img-down-arrow-div {
						text-align: right;
						width: 100%;
						margin-right: 8.75px;
						margin-top: -2px;
					}
					.img-down-arrow {
						margin-left: 4px;
					}
					.img-down-arrow-div {
						text-align: right;
						width: 100%;
						margin-top: -2px;
					}
				}
				&.row-icon {
					position: relative;
					padding-left: 24px;
				}
				&.remove-transform {
					position: relative;
					padding-left: 20px;
				}
			}

			th {
				text-align: left;
				font-family: 'TT-Commons-Demi';
				font-style: normal;
				color: #fff;
				font-size: 22px;
				font-weight: 600;
				letter-spacing: 0;
				line-height: 164%;
				vertical-align: middle;
				padding-top: 0.5%;

				&:first-child {
					padding-left: 28px;
					border-radius: 8px 0px 0px 0px;
				}
				&:last-child {
					border-radius: 0px 8px 0px 0px;
				}
				&.name {
					left: 0;
					position: relative;
					width: 22%;
				}
				&.status {
					position: relative;
					width: 16%;
				}
				&.dateRange {
					position: relative;
					width: 20%;
				}
				&.dateRange-archived {
					position: relative;
					width: 20%;
				}
				&.description {
					width: 30.5%;
				}
				&.description-archived {
					width: 36.5%;
				}
				&.videoCount {
					position: relative;
					width: 116px !important;
				}
				&.liveCount {
					position: relative;
					width: 156px !important;
				}
				&.screens {
					position: relative;
					// padding-left: 50px;
					width: 10%;
				}
				&.actions-buck {
					padding-top: 12px;
					position: absolute;
					right: 40px;
				}
			}
		}

		.checkbox {
			display: inline-flex;
			vertical-align: middle;

			.bucket-name {
				padding-top: 3px;
			}
			.ranked-name {
				color: $primary-default;
				font-size: 12px;
				margin-left: 4px;
			}

			#name-checkbox-row,
			.name-checkbox-row {
				appearance: none;
				-webkit-appearance: none;
				width: 19px;
				height: 19px;
				cursor: pointer;
				background: rgba(40, 40, 40, 0.2);
				color: black;
				-moz-appearance: none;
				border: 1px solid rgba(255, 255, 255, 0.32);
				box-sizing: border-box;
				border-radius: 4px;
				margin-right: 16px;
				margin-top: 4px;
			}
			.disable {
				opacity: 0.3;
			}

			#name-checkbox {
				appearance: none;
				-webkit-appearance: none;
				width: 19px;
				height: 19px;
				cursor: pointer;
				background: rgba(40, 40, 40, 0.2);
				color: black;
				-moz-appearance: none;
				border: 1px solid rgba(255, 255, 255, 0.32);
				box-sizing: border-box;
				border-radius: 4px;
				margin-right: 16px;
				margin-top: 7px;
				.extra-padding {
					padding-top: 32;
				}
			}
			.name-checkbox-row:checked,
			#name-checkbox-row:checked,
			#name-checkbox:checked {
				background-color: #f82c5e;
				padding: 2px 3px;
				border: 0;
				&::after {
					content: '';
					width: 12px;
					height: 9px;
					background-image: url(../../../../../assets/images/tick.svg);
					display: inline-block;
				}
			}
		}

		.row {
			min-height: 64px;
			border-bottom: 1px solid #181a1f;
			border-top: 1px solid #181a1f;
			background-color: #2a2a31;
		}
	}
	.table thead tr:first-child {
		box-shadow: none;
		height: 74px;
		background: #363541;
	}

	@media only screen and (max-width: 1080px) {
		max-height: 77vh;
		.table {
			tr {
				th {
					padding-top: 1.2%;
					&:first-child {
						padding-left: 17px;
					}

					&.name {
						padding-right: 64px;
					}
					&.name {
						position: relative;
						width: 25%;
					}
					&.status {
						position: relative;
						width: 17%;
					}
					&.dateRange {
						position: relative;
						width: 17%;
					}
					&.dateRange-archived {
						position: relative;
						width: 20%;
					}
					&.description {
						width: 25.5%;
					}
					&.description-archived {
						width: 36.5%;
					}
					&.videoCount {
						position: relative;
						width: 200px;
					}
					&.screens {
						position: relative;
					}
				}
				td {
					padding-top: 6px;
					&:first-child {
						padding-left: 17px;
						width: 23%;
					}
					&:last-child {
						padding-top: 21px;
					}
					&.row-videoCount {
						width: 210px;
						text-align: right;
						padding-right: 8%;
					}
					&:last-child {
						.kebab-menu-icon-container {
							transform: translateX(0px);
						}
					}
				}
			}
		}
	}

	@media only screen and (min-width: 1080px) and (max-width: 1150px) {
		max-height: 77vh;
		.table {
			tr {
				th {
					padding-top: 1.2%;
					&:first-child {
						padding-left: 17px;
					}
					&.dateRange {
						width: 15% !important;
					}

					&.description {
						width: 25% !important;
					}
					&.description-archived {
						width: 30%;
					}
				}
				td {
					padding-top: 6px;
					&:first-child {
						padding-left: 20px;
						width: 25.5%;
					}
					&:last-child {
						padding-top: 21px;
					}
					&.status-field {
						width: 17%;
					}
					&.row-dateRange {
						width: 15%;
					}
					&.row-description {
						width: 24%;
					}
					&.row-description-archived {
						width: 28%;
					}
					&.row-dateRange-archived {
						width: 19% !important;
					}
					&.row-videoCount {
						padding: 0;
						width: 62px !important;
					}
					&:nth-last-child(2) {
						padding-left: 64px;
					}
					&:last-child {
						.kebab-menu-icon-container {
							transform: translateX(0px);
						}
					}
				}
			}
		}
	}

	@media only screen and (min-width: 1280px) {
		max-height: 71.5vh;
	}
}
.empty-space {
	width: 19px;
	margin-right: 24px;
}

#empty-state-loading {
	td {
		&:last-child {
			display: table-cell !important;
		}
	}
}
.reported-loader {
	td {
		&:first-child {
			padding-top: 32px !important;
			width: 37% !important;
			padding-left: 178px !important;
		}
	}
}

.extra-margin-left {
	margin-left: 40px;
}
.extra-margin-report {
	padding-left: 9.75%;
	margin-left: 98.25px;
}
@media only screen and (max-width: 1330px) {
	.extra-margin-report {
		padding-left: 0%;
		margin-left: 30.25px;
	}
}

@media only screen and (min-width: 1151px) and (max-width: 1400px) {
	.table {
		tr {
			td {
				padding-top: 6px;
				&:first-child {
					padding-left: 20px;
					width: 22%;
				}
				&.status-field {
					width: 17%;
				}
				&.row-dateRange {
					width: 16% !important;
				}
				&.row-dateRange-archived {
					width: 23%;
				}
				&.row-videoCount {
					padding: 0;
					width: 50px;
				}
				&.row-description {
					width: 24% !important;
				}
				&:nth-last-child(2) {
					padding-left: 49px;
				}
				&:last-child {
					.kebab-menu-icon-container {
						transform: translateX(0px);
					}
				}
			}
		}
		th {
			padding-top: 1.2%;
			&:first-child {
				padding-left: 17px;
			}
			&.dateRange {
				width: 15% !important;
			}
			&.description {
				width: 25% !important;
			}
		}
	}
}
