@import "src/app/common/utils/scss/main.scss";

.select-box-wrapper {
  height: 50px;
  border-radius: 8px;
  padding: 16px;
  background-color: $body-background-color;
  color: #fff;
  font-size: 1.125em;
  option {
    font-size: 1.125em;
    font-style: normal;
    font-weight: 600;
    line-height: 1.125em;
    letter-spacing: 0em;
    text-align: left;
    height: 57px;
    border-radius: 9px;
  }
}
.select-span-container {
  .select-span {
    height: 40px;
    width: 311px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 8px 16px 8px 16px;
    background-color: $body-background-color;

    &.disabled {
      pointer-events: none;
      p {
        color: rgba(255, 255, 255, 0.42);
      }
      div {
        img {
          cursor: none;
        }
      }
    }
    p {
      font-family: "TT-Commons-Regular";
      font-size: 18px !important;
      color: #ffffff6b;
      margin-right: 10px;
      letter-spacing: 0;
      font-weight: 400;
    }
  }
  .select-span {
    display: flex;
    align-items: center;
    justify-content: space-between;
    div {
      img {
        cursor: pointer;
      }
    }
  }
}

.option-box {
  position: absolute;
  margin-top: 0.5em;
  z-index: 2;
}
