@import 'src/app/common/utils/scss/variables.scss';

.video-detail-container {
	overflow-y: auto;
	height: 600px;
	width: 500px;

	&::-webkit-scrollbar {
		width: 0px;
		height: 0px;
		background: transparent;
	}
	&::-webkit-scrollbar-track {
		background: transparent;
	}
	&::-webkit-scrollbar-thumb {
		background: transparent;
	}

	.video-detail-wrapper {
		transition: transform 0.6s ease-in-out;

		&::-webkit-scrollbar {
			width: 3px;
			height: 25px;
			background: transparent;
		}
		&::-webkit-scrollbar-track {
			background: transparent;
		}
		&::-webkit-scrollbar-thumb {
			background: #888;
		}

		.data-image {
			width: 500px;
			height: 280px;
			border-top-right-radius: 12px;
			border-top-left-radius: 12px;
		}

		.cross-icon-popup {
			max-width: 16px;
			height: 16px;
			position: absolute;
			left: 473px;
			top: 10px;
			z-index: 10;
			cursor: pointer;
		}

		.video-title {
			font-family: 'TT-Commons-Medium';
			font-style: normal;
			font-weight: 700;
			font-size: 30px;
			line-height: 100%;
			display: flex;
			align-items: center;
			color: rgba(255, 255, 255, 0.7);
			text-align: initial;
			margin-bottom: -10px;
			width: 100%;
			background: #2a2a31;
			padding-left: 30px;
			position: sticky;
			top: 0px;
			z-index: 9;
			padding-top: 10px;
		}

		.video-details {
			margin: 6%;
			margin-top: 16px;

			.video-info-wrapper {
				.ratings {
					display: flex;
					align-items: center;
					margin-bottom: 8px;
					.imdb-ratings {
						display: flex;
						align-items: center;
						background: rgba(79, 212, 113, 0.4);
						border-radius: 12px;
						padding-right: 8px;
						padding-left: 8px;
						height: 20px;
						margin-right: 8px;
						.green-dot {
							width: 8px;
							height: 8px;
							margin-right: 4px;
						}
						.detail-heading {
							font-family: 'TT-Commons-Medium';
							font-style: normal;
							font-weight: 500;
							font-size: 15px;
							line-height: 130%;
							margin-top: 4px;
							letter-spacing: 1px;
							text-transform: uppercase;
							color: #ffffff;
						}
					}
				}

				.date-season-rating {
					margin-top: 4px;
					display: flex;
					align-items: center;
					.content {
						font-family: 'TT-Commons-Regular';
						font-style: normal;
						font-weight: 400;
						font-size: 16px;
						line-height: 120%;
						color: rgba(255, 255, 255, 0.7);
						margin-right: 8px;
					}
					.dot {
						width: 8px;
						height: 8px;
						background: rgba(255, 255, 255, 0.32);
						border-radius: 15px;
						margin-right: 8px;
						margin-top: -4px;
					}
				}

				.genre {
					margin-top: 4px;
					margin-bottom: 10px;
					.content {
						font-family: 'TT-Commons-Regular';
						font-style: normal;
						font-weight: 400;
						font-size: 16px;
						line-height: 120%;
						color: rgba(255, 255, 255, 0.7);
						margin-right: 8px;
					}
				}
			}

			.video-detail-watch {
				margin-top: 28px;
				.watch-on {
					font-family: 'TT-Commons-Regular';
					font-style: normal;
					font-weight: 400;
					font-size: 16px;
					line-height: 120%;
					color: rgba(255, 255, 255, 0.32);
				}

				.watch-on-image-wrapper {
					display: grid;
					grid-template-columns: repeat(5, 1fr);
					grid-gap: 12px 4px;
					.provider-image-div {
						background: #2a2a31;
						border-radius: 8px;
						height: 50px;
						padding: 8px;
						padding-left: 0px;
						width: 80px;
						cursor: pointer;
						.provider-image {
							width: 100%; /* or any custom size */
							height: 100%;
							object-fit: contain;
						}
						.provider-availibility {
							font-family: 'TT-Commons-Medium';
							font-style: normal;
							font-weight: 500;
							font-size: 12px;
							line-height: 120%;
							color: rgba(255, 255, 255, 0.7);
							text-align: center;
							margin-top: 2px;
						}
					}
				}
			}
		}
		.seprator {
			margin-top: 8px;
			margin-bottom: 8px;
			width: 100%;
			height: 0px;
			border: 1px solid rgba(255, 255, 255, 0.1);
		}
		.cast-and-crew {
			.cast-wrapper {
				.cast-heading {
					font-family: 'TT-Commons-Demi';
					font-style: normal;
					font-weight: 700;
					font-size: 20px;
					line-height: 115%;
					color: #ffffff;
					margin: 6%;
					margin-top: 0;
					margin-bottom: 12px;
				}
				.actors {
					margin-bottom: 12px;
					.sub-heading {
						font-family: 'TT-Commons-Regular';
						font-style: normal;
						font-weight: 400;
						font-size: 20px;
						line-height: 120%;
						color: rgba(255, 255, 255, 0.32);
						margin-top: 10px;
						margin: 6%;
						margin-top: 0;
						margin-bottom: 0;
					}
					.actor-container {
						display: flex;
						align-items: center;
						.cast-container {
							display: flex;
							flex-direction: row;
							align-items: center;
							overflow-y: hidden;
							overflow-x: scroll;
							scroll-behavior: smooth;
							&::-webkit-scrollbar {
								width: 0px;
								height: 0px;
								background: transparent;
							}
							&::-webkit-scrollbar-track {
								background: transparent;
							}
							&::-webkit-scrollbar-thumb {
								background: transparent;
							}

							.block {
								display: flex;
								flex-direction: row;
								align-items: center;
								justify-content: center;
								padding: 15px 2px;
								height: 46px;
								width: 140px;
								cursor: pointer;
								flex-shrink: 0;
							}
						}
						.more-margin {
							margin-left: 16px;
						}
					}
					.right-arrow {
						width: 16px;
						height: 16px;
						margin-right: 16px;
						margin-top: -12px;
						cursor: pointer;
					}
					.left-arrow {
						-webkit-transform: rotate(180deg);
						-moz-transform: rotate(180deg);
						-ms-transform: rotate(180deg);
						-o-transform: rotate(180deg);
						transform: rotate(180deg);
						width: 16px;
						height: 16px;
						cursor: pointer;
						margin-left: 16px;
						margin-top: -12px;
					}
					.deamed {
						pointer-events: none;
						opacity: 0.4;
					}
				}
				.director {
					margin-bottom: 12px;
					.sub-heading {
						font-family: 'TT-Commons-Regular';
						font-style: normal;
						font-weight: 400;
						font-size: 20px;
						line-height: 120%;
						color: rgba(255, 255, 255, 0.32);
						margin-top: 10px;
						margin: 6%;
						margin-top: 0;
						margin-bottom: 0;
					}
					.director-container {
						display: flex;
						align-items: center;
						.cast-container {
							display: flex;
							flex-direction: row;
							align-items: center;
							overflow-y: hidden;
							overflow-x: scroll;
							scroll-behavior: smooth;
							&::-webkit-scrollbar {
								width: 0px;
								height: 0px;
								background: transparent;
							}
							&::-webkit-scrollbar-track {
								background: transparent;
							}
							&::-webkit-scrollbar-thumb {
								background: transparent;
							}

							.block {
								display: flex;
								flex-direction: row;
								align-items: center;
								justify-content: center;
								padding: 15px 2px;
								height: 46px;
								width: 140px;
								cursor: pointer;
								flex-shrink: 0;
							}
						}
						.more-margin {
							margin-left: 16px;
						}
					}
					.right-arrow {
						width: 16px;
						height: 16px;
						margin-right: 16px;
						margin-top: -12px;
						cursor: pointer;
					}
					.left-arrow {
						-webkit-transform: rotate(180deg);
						-moz-transform: rotate(180deg);
						-ms-transform: rotate(180deg);
						-o-transform: rotate(180deg);
						transform: rotate(180deg);
						width: 16px;
						height: 16px;
						cursor: pointer;
						margin-left: 16px;
						margin-top: -12px;
					}
					.deamed {
						pointer-events: none;
						opacity: 0.4;
					}
				}
			}
		}
	}

	.episode-wrapper {
		transition: transform 0.6s ease-in-out;
		overflow-x: hidden;

		&::-webkit-scrollbar {
			width: 3px;
			height: 2px;
			background: transparent;
		}
		&::-webkit-scrollbar-track {
			background: transparent;
		}
		&::-webkit-scrollbar-thumb {
			background: #888;
		}

		.data-image {
			width: 500px;
			height: 280px;
			border-top-right-radius: 12px;
			border-top-left-radius: 12px;
		}

		.back-icon-popup {
			max-width: 16px;
			height: 16px;
			position: relative;
			left: 10px;
			top: -270px;
			z-index: 10;
			cursor: pointer;
		}

		.cross-popup {
			max-width: 16px;
			height: 16px;
			position: relative;
			left: 453px;
			top: -270px;
			z-index: 10;
			cursor: pointer;
		}
		.video-title {
			font-family: 'TT-Commons-Medium';
			font-style: normal;
			font-weight: 700;
			font-size: 30px;
			line-height: 100%;
			display: flex;
			align-items: center;
			color: rgba(255, 255, 255, 0.7);
			text-align: initial;
			margin-bottom: 10px;
			width: 100%;
			background: #2a2a31;
			padding-left: 30px;
			position: sticky;
			top: 0px;
			z-index: 9;
			padding-top: 10px;
		}
		.video-details {
			margin: 6%;
			margin-top: 16px;
			.episode-info {
				margin-top: 10px;
				display: flex;
				justify-content: space-between;
				.show-wrap {
					.detail-head {
						font-family: 'TT-Commons-Demi';
						font-style: normal;
						font-weight: 600;
						font-size: 16px;
						line-height: 120%;
						color: rgba(255, 255, 255, 0.42);
					}
					.detail-content {
						font-family: 'TT-Commons-Demi';
						font-style: normal;
						font-weight: 600;
						font-size: 16px;
						line-height: 120%;
						color: rgba(255, 255, 255, 0.7);
						margin-top: 4px;
					}
				}
				.episode-season {
					display: flex;
					.detail-head {
						font-family: 'TT-Commons-Demi';
						font-style: normal;
						font-weight: 600;
						font-size: 16px;
						line-height: 120%;
						color: rgba(255, 255, 255, 0.42);
					}
					.season-num {
						font-family: 'TT-Commons-Demi';
						font-style: normal;
						font-weight: 600;
						font-size: 16px;
						line-height: 120%;
						color: rgba(255, 255, 255, 0.7);
						margin-top: 4px;
						text-align: end;
						padding-right: 6px;
					}
					.season-info {
						margin-right: 8px;
					}
				}
			}

			.video-info-wrapper {
				.ratings {
					display: flex;
					align-items: center;
					margin-bottom: 8px;
					.imdb-ratings {
						display: flex;
						align-items: center;
						background: rgba(79, 212, 113, 0.4);
						border-radius: 12px;
						padding-right: 8px;
						padding-left: 8px;
						height: 20px;
						.green-dot {
							width: 8px;
							height: 8px;
							margin-right: 4px;
						}
						.detail-heading {
							font-family: 'TT-Commons-Medium';
							font-style: normal;
							font-weight: 500;
							font-size: 15px;
							line-height: 130%;
							letter-spacing: 1px;
							text-transform: uppercase;
							color: #ffffff;
						}
					}
				}
			}

			.video-detail-watch {
				margin-top: 28px;
				.watch-on {
					font-family: 'TT-Commons-Regular';
					font-style: normal;
					font-weight: 400;
					font-size: 16px;
					line-height: 120%;
					color: rgba(255, 255, 255, 0.32);
				}

				.watch-on-image-wrapper {
					display: grid;
					grid-template-columns: repeat(5, 1fr);
					grid-gap: 12px 4px;
					.provider-image-div {
						background: #2a2a31;
						border-radius: 8px;
						height: 50px;
						padding: 8px;
						padding-left: 0px;
						width: 80px;
						cursor: pointer;
						.provider-image {
							width: 100%; /* or any custom size */
							height: 100%;
							object-fit: contain;
						}
						.provider-availibility {
							font-family: 'TT-Commons-Medium';
							font-style: normal;
							font-weight: 500;
							font-size: 12px;
							line-height: 120%;
							color: rgba(255, 255, 255, 0.7);
							text-align: center;
							margin-top: 2px;
						}
					}
				}
			}
		}
	}

	.slide {
		transform: translateX(-150%);
	}
	.slide-two {
		transform: translateX(+150%);
	}

	.video-detail-loader-container {
		display: flex;
		height: 625px;
		align-items: center;
		justify-content: center;
	}
	.big-card-sub-title {
		.content-style {
			height: fit-content;
			font-family: 'TT-Commons-Regular';
			font-style: normal;
			font-weight: normal;
			font-size: 18px;
			line-height: 135%;
			overflow: visible;
			color: rgba(255, 255, 255, 0.7);
		}
		.collapse-description {
			height: 100px;
			overflow: hidden;
		}
		.show-description {
			height: fit-content;
			overflow: visible;
		}
		.more {
			font-size: 18px;
			font-weight: bold;
			color: white;
			border: none;
		}
	}
}
