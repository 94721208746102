.preview-popup-content {
	overflow-y: auto;
	height: 100%;
	display: flex;
	justify-content: center;
	.preview-content {
		display: flex;
		justify-content: center;
		overflow-y: auto;
		height: 87%;
		width: 55%;
		margin: auto;
		margin-top: 85px;

		.heading {
			position: absolute;
			top: 0;
			font-style: normal;
			font-weight: bold;
			font-size: 28px;
			line-height: 112%;
			color: #ffffff;
			margin-top: 36px;
		}
		.close {
			position: absolute;
			top: 0;
			right: 20px;
			margin-top: 30px;
			width: 12px;
			height: 12px;
			cursor: pointer;
		}
		.fixed-preview-image {
			position: absolute;
			width: 375px;
			height: 86%;
			z-index: 1;
			background: #26242c;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.content {
			display: flex;
			justify-content: center;
			width: 100%;
			.preview-popup-content-container {
				position: relative;
				width: 100%;
				top: 5%;
				height: 88%;
				overflow: auto;

				.icon-header {
					position: absolute;
					display: flex;
					align-items: center;
					justify-content: space-between;
					z-index: 2;
					width: 100%;
					top: 0;
				}

				&::-webkit-scrollbar {
					width: 3px;
					height: 15px;
				}

				&::-webkit-scrollbar-track {
					width: 3px;
					height: 15px;
					background: transparent;
				}

				&::-webkit-scrollbar-thumb {
					width: 3px;
					height: 15px;
					background: lightgray;
				}

				.sub-header {
					display: flex;
					flex-direction: column;
					z-index: 2;
					margin-top: 30px;
					.sub-heading {
						display: flex;
						z-index: 2;
						margin-bottom: 16px;
					}
					.sub-heading-description-container {
						display: flex;
						justify-content: space-between;
						.tag {
							z-index: 2;
							background: rgba(255, 255, 255, 0.1);
							padding: 5px;
							border-radius: 5px;
							display: flex;
							align-items: center;
							justify-content: center;
							width: 85px;
							height: 31px;
							font-family: 'TT-Commons-Regular';
							font-style: normal;
							font-weight: 600;
							font-size: 16px;
							line-height: 120%;
							color: rgba(255, 255, 255, 0.42);
							padding-top: 8px;
						}
						.sub-heading-description {
							display: flex;
							z-index: 2;
							font-style: normal;
							font-weight: normal;
							line-height: 135%;
							color: rgba(255, 255, 255, 0.7);
							width: 60%;
							font-family: 'TT-Commons-Regular';
							font-size: 16px;
							line-height: 120%;
						}
					}
				}
				.cards {
					display: flex;
					align-self: center;
					flex-direction: column;
					z-index: 2;
					.big-card-container {
						z-index: 2;
						border: none;
						background: linear-gradient(
							238.19deg,
							rgba(98, 99, 237, 0.3) 0%,
							rgba(99, 99, 236, 0.3) 6.67%,
							rgba(103, 97, 232, 0.3) 13.33%,
							rgba(110, 95, 225, 0.3) 20%,
							rgba(120, 91, 216, 0.3) 26.67%,
							rgba(133, 86, 204, 0.3) 33.33%,
							rgba(148, 81, 190, 0.3) 40%,
							rgba(164, 75, 174, 0.3) 46.67%,
							rgba(182, 68, 157, 0.3) 53.33%,
							rgba(198, 62, 141, 0.3) 60%,
							rgba(213, 57, 127, 0.3) 66.67%,
							rgba(226, 52, 115, 0.3) 73.33%,
							rgba(236, 48, 106, 0.3) 80%,
							rgba(243, 46, 99, 0.3) 86.67%,
							rgba(247, 44, 95, 0.3) 93.33%,
							rgba(248, 44, 94, 0.3) 100%
						);
						background: none;
						padding: 0;
						margin-top: 30px;

						.big-card-container-image {
							height: 186px;
						}

						.big-card-label p {
							font-size: 18px;
						}
						.big-card-sub-title {
							p {
								font-size: 16px;
							}

							.collapse-description {
								height: 70px;
							}
						}
					}
					.big-card-container:hover {
						border: none;
					}
				}
			}
		}
	}
}
