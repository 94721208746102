.content-grid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	row-gap: 40px;
	column-gap: 24px;
}

.content-grid-category {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	row-gap: 25px;
	column-gap: 21.25px;
}

.content-grid-search {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
	row-gap: 40px;
	column-gap: 24px;
}

@media only screen and (max-width: 1300px) {
	.content-grid-category {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		row-gap: 25px;
		column-gap: 21.25px;
	}
}
@media only screen and (min-width: 1850px) {
	.content-grid-category {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
		row-gap: 25px;
		column-gap: 21.25px;
	}
}
@media only screen and (min-width: 2250px) {
	.content-grid-category {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
		row-gap: 25px;
		column-gap: 21.25px;
	}
}
@media only screen and (min-width: 2550px) {
	.content-grid-category {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
		row-gap: 25px;
		column-gap: 21.25px;
	}
}
