@import 'src/app/common/utils/scss/main.scss';
.date-range-div {
	position: absolute;
	top: 0;
	left: -2px;
	z-index: 3;
	background-color: $body-background-color;
	color: #fff;
	padding: 30px 40px 28px;
	width: 698px;
	height: 465px;
	margin-top: 6px;
	border-radius: 10px;
	opacity: 0;
	transform: scale(1.01);
	transform-origin: center;
	animation: calenderAnim 0.5s 0.5s forwards;
	@keyframes calenderAnim {
		0% {
			opacity: 0;
			transform: scale(1.01);
		}
		// 25% {
		//   opacity: 0;
		//   transform: scale(1);
		// }
		// 50% {
		//   opacity: 0;
		//   transform: scale(0.7);
		// }
		// 75% {
		//   opacity: 0;
		//   transform: scale(0.7);
		// }
		100% {
			opacity: 1;
			transform: scale(1);
		}
	}
	button {
		color: #fff;
	}
	.action-buttons {
		width: 100%;
		display: flex;
		justify-content: flex-end;
		margin-top: 0;
		margin-bottom: 28px;
		span:first-child {
			margin-right: 20px;
		}
		span:second-child {
			color: #fff;
		}
	}
}
.rdrDayDisabled {
	background: transparent;
	opacity: 0.2;
}
.rdrMonthAndYearWrapper {
	position: absolute;
	margin: auto;
	width: calc(100% + 70px);
	top: 0;
	left: -40px;
	bottom: 0;
	z-index: 1;
}
.rdrNextPrevButton {
	background-color: transparent;
	&:hover {
		background-color: transparent;
	}
	& i {
		background-image: url('../../../assets/images/arrow.svg');
		border: 0;
		width: 24px;
		height: 24px;
	}
}
.rdrNextButton i {
	transform: rotate(180deg);
}
.rdrNextButton {
	padding-left: 6px;
}
.dateRangeWrapper {
	background: $body-background-color;
	position: relative;
	.rdrCalendarWrapper {
		color: red !important;
	}
	div.rdrDateDisplayWrapper {
		display: none !important;
	}
	.rdrMonthAndYearPickers {
		display: none;
	}
	.rdrMonthName {
		display: flex;
		justify-content: center;
		height: 50px;
		padding-right: 50px;
		color: #fff;
	}

	.rdrDayNumber span {
		color: rgba($color: #ffffff, $alpha: 0.7);
		padding-top: 2px;
	}
	.rdrDay {
		height: 4.5em;
	}
	span.rdrStartEdge {
		background: $primary-default !important;
		border-radius: 40px;
		left: 0;
		height: 32px;
		width: 32px;
		top: 0;
		margin: auto;
		bottom: 0;
	}

	span.rdrEndEdge {
		border-radius: 35px;
		background: $primary-default !important;
		right: 0;
		height: 32px;
		width: 32px;
		top: 0;
		bottom: 0;
		margin: auto;
		z-index: 2;
	}

	.rdrMonthName {
		font-family: 'TT-Commons-Bold';
		font-size: 20px;
		font-style: normal;
		font-weight: 700;
		line-height: 23px;
		letter-spacing: 0em;
		text-align: center;
	}
	.rdrWeekDay {
		font-family: 'TT-Commons-Demi';
		font-size: 12px;
		font-style: normal;
		font-weight: 600;
		line-height: 14px;
		letter-spacing: 0em;
		text-align: center;
		width: 32px !important;
		flex-basis: unset;
		margin: 0 4px 17px;
		color: #fff;
	}

	.rdrDayNumber {
		font-family: 'TT-Commons-Regular';
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 22px;
		letter-spacing: 0.2199999988079071px;
		text-align: center;
	}

	.rdrInRange {
		background-color: #1f1f23;
	}

	.rdrDayEndPreview.rdrDayStartPreview {
		top: 0;
		bottom: 0;
		width: 32px;
		margin: auto;
		left: 0;
	}
}
.rdrDayInPreview,
.rdrDayEndPreview,
.rdrDayStartPreview {
	width: 40px;
	top: 0;
	bottom: 0;
	height: 32px;
	margin: auto;
	left: 0;
}

.rdrDay {
	height: 32px !important;
	width: 40px !important;
	margin-bottom: 10px;
	text-align: center;
}
.rdrMonth {
	width: 50%;
	padding: 0 14px 28px 14px;
}
span.rdrDayNumber {
	height: 32px !important;
	bottom: 0;
	top: 0;
	left: 0px;
	z-index: 2;
}
span.rdrInRange {
	height: 32px !important;
	bottom: 0;
	top: 0;
}
span.rdrDayStartOfMonth span.rdrInRange {
	border-top-left-radius: 2.3em;
	border-bottom-left-radius: 2.3em;
}

span.rdrDayEndOfMonth span.rdrInRange {
	border-top-right-radius: 1.3em;
	border-bottom-right-radius: 1.3em;
}
.rdrMonth {
	height: 360px;
}
.rdrDayPassive {
	opacity: 0.2;
}

.date-range-div-all-users {
	top: 50px;
	right: 0px;
	left: auto !important;
}
