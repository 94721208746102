@import '../../common/utils/scss/variables.scss';
.content-management-container {
	margin-top: 1.9%;
	.header-block {
		margin-bottom: 32px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		.publish-button {
			border-radius: 8px;
			span {
				font-weight: 600;
				font-size: 20px;
				line-height: 115%;
				transform: translateY(2px);
				display: inline-block;
			}
		}
	}
	.content-block {
		max-height: 80vh;
		overflow: auto;
		&::-webkit-scrollbar {
			width: 3px;
			height: 25px;
			background: transparent;
		}
		&::-webkit-scrollbar-track {
			background: transparent;
		}
		&::-webkit-scrollbar-thumb {
			background: #888;
		}

		&::-webkit-scrollbar {
			width: 3px;
			height: 25px;
			background: transparent;
		}
		&::-webkit-scrollbar-track {
			background: transparent;
		}
		&::-webkit-scrollbar-thumb {
			background: #888;
		}

		.loader {
			height: 80vh;
			// transform: translateY(13%);
			z-index: 3;
		}

		.infinite-scroll {
			padding-bottom: 100px;
		}
		.content {
			display: grid;
			grid-template-columns: 50% 1fr 268px;
			background: rgba(14, 14, 23, 0.32);
			border-radius: 8px;
			padding: 32px 40px;

			.editable-container {
				word-break: break-word;
				border: 0.5px solid transparent;
			}

			.sub-heading {
				margin-bottom: 28px;
				color: rgba(255, 255, 255, 0.42);
				font-size: 16px;
				line-height: 120%;
			}
			.screen-details {
				grid-column: span 1;
				.heading {
					margin-bottom: 4px;
				}
				.screens {
					.border {
						border: 2px solid $primary-default;
						border-radius: 12px;
						padding: 22px 30px 22px 20px;
						transition: border 0.3s linear;
					}
					.no-border {
						border: 2px solid transparent;
						transition: all 0.3s ease-in-out;
					}
					.screen-name {
						font-style: normal;
						font-weight: 600;
						font-size: 20px;
						line-height: 115%;
						color: #ffffff;
						background: #3e3d66;
						border-radius: 8px;
						display: inline-block;
						padding: 6px 13px 3px 13px;
						margin-bottom: 8px;
					}
					.screen-content {
						display: flex;
						flex-direction: column;
						margin-bottom: 32px;
						transition: all 0.3s ease-in-out;
						.screen-text {
							font-family: inherit;
							outline: none;
							font-style: normal;
							font-weight: normal;
							font-size: 18px;
							line-height: 135%;
							color: $filter-tag-color;
							background-color: inherit;
							resize: none;
							border: none !important;
							&.hidden {
								visibility: hidden;
								height: 0 !important;
							}

							&.visible {
								visibility: visible;
								height: max-content;
							}

							.privacy-policy-link {
								font-size: 18px;
								line-height: 96%;
								color: $primary-default;
								cursor: pointer;
							}
						}
					}
				}
			}
			.right-content {
				grid-column: span 1;

				.react-multi-carousel-track {
					padding-bottom: 20px;
				}
				.react-multi-carousel-item--active {
					position: relative;
				}
				.carousel-item {
					.image-content {
						position: absolute;
						top: 80px;
						padding: 20px;
						color: white;
						word-break: break-word;
					}
				}
				img {
					top: 0;
					object-fit: contain;
					height: inherit;
					max-height: 535px;
					width: 268px;
				}

				.only-previous {
					bottom: 0;
					right: 2%;
					position: absolute;
					transform: translateY(15px);
					transition: all 0.2s linear;
					z-index: 3;
				}

				.Next {
					bottom: 0;
					right: 2%;
					position: absolute;
					transform: translateY(15px);
					z-index: 3;
				}
				.Previous {
					bottom: 0;
					right: 25%;
					position: absolute;
					transform: translateY(15px);
					transition: all 0.2s linear;
					z-index: 3;
				}
				.slide-button {
					color: $primary-default;
					background-color: transparent;
					padding: 10px 0px 10px 0px;
				}
				.react-multi-carousel-dot-list {
					justify-content: left;
					transform: translateX(1%);
					.inactive-dot {
						width: 6px;
						height: 6px;
						border: 1px solid rgba(255, 255, 255, 0.32);
						border-radius: 50%;
						margin: 0px 3px 0px 3px;
						background-color: transparent;
						cursor: pointer;
						transition: background-color 0.3s linear;
					}

					.active-dot {
						width: 6px;
						height: 6px;
						border-radius: 50%;
						margin: 0px 3px 0px 3px;
						background-color: $primary-default;
						border: none;
						cursor: pointer;
						transition: background-color 0.3s linear;
					}
				}
			}
		}
	}
}
