.custom-select-popup-wrapper {
	margin-bottom: 36px;
	height: 49px;
	position: relative;
	.screen-error-div {
		margin-left: 30px;
	}
	.select-popup-error-wrapper {
		display: flex;
		align-items: center;
		.error-state-field {
			margin-left: 30px;
		}
	}
	.width-fit-content {
		width: fit-content;
	}
}

.custom-select-dropdown-wrapper {
	margin-top: 2px;
	height: 28px;
	background-color: #2a2a31;
	display: flex;
	align-items: baseline;
	justify-content: flex-start;

	.names {
		.pill {
			padding: 5px 10px 2px 8px;
			border: 1px solid rgba(255, 255, 255, 0.12);
			border-radius: 20px;
			display: inline-block;
			font-size: 18px;
			margin: 3px;

			.cross {
				margin-left: 8.5px;
			}
		}
	}
	.color-white {
		color: #fff;
		text-transform: capitalize;
	}
	div:first-child {
		margin-right: 12px;
	}
	div {
		img {
			cursor: pointer;
		}
		p {
			padding-top: 2px;
		}
	}
	p {
		color: rgba(255, 255, 255, 0.42);
		font-family: 'TT-Commons-Bold';
		font-size: 24px;
		font-style: normal;
		font-weight: 700;
		line-height: 28px;
	}
	input {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 0;
		width: 270px;
		background: #2a2a31;
		border: 0;
		border-radius: 8px;
		color: rgba(255, 255, 255, 0.42);
		font-family: 'TT-Commons-Bold';
		font-size: 24px;
		font-style: normal;
		font-weight: 700;
		line-height: 28px;
	}
	.select-box-down-arrow-rotate {
		transform: rotate(180deg);
		transition: transform 0.3s linear;
	}
	.select-box-down-arrow-unrotate {
		transform: rotate(0);
		transition: transform 0.3s linear;
	}
}
.popup-select-label {
	p {
		color: #ffffff6b;
		font-family: 'TT-Commons-Medium';
		font-size: 15px;
		line-height: 19px;
		letter-spacing: 1px;
	}
}
