.digest-card-wrapper {
	width: 100%;
	// height: 374px !important;
	padding: 18px 20px;
	background: rgba(24, 25, 31, 0.4);
	// opacity: 0.4;
	border-radius: 8px;
	margin-bottom: 16px;
	&-video-thumb {
		font-family: 'TT-Commons-Medium';
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		line-height: 19px;
		letter-spacing: 1px;
		text-transform: uppercase;
		color: rgba(255, 255, 255, 0.42);
	}
	.digest-card-wrapper-image-div {
		margin-top: 8px;
	}
	.digest-card-wrapper-image {
		width: 258px !important;
		height: 144px !important;
		filter: drop-shadow(0px 18px 26px rgba(0, 0, 0, 0.35));
		border-radius: 8px;
	}
	.digest-card-wrapper-title-div {
		margin-top: 18px;
		display: flex;
		font-family: 'TT-Commons-Demi';
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		line-height: 115%;
		color: #ffffff;
		padding-top: 3px;

		.digest-card-wrapper-title-image {
			margin-right: 16px;
			background: -webkit-linear-gradient(#6263ed, #f82c5e);
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-stroke: 3px transparent;
			color: #181a1f;
		}
	}
	.digest-card-wrapper-desc {
		margin-top: 4px;
		.digest-card-wrapper-desc-text {
			width: 100%;
			background: transparent;
			border: none;
			resize: none;
			font-family: 'TT-Commons-Regular';
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 115%;
			color: #ffffff;
			outline: none;
			&::-webkit-scrollbar {
				width: 3px;
				height: 25px;
				background: transparent;
			}
			&::-webkit-scrollbar-track {
				background: transparent;
			}
			&::-webkit-scrollbar-thumb {
				background: #888;
			}
		}
		.focusText {
			color: #f82c5e !important;
		}
	}
}
