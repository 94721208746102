.thumbnail-container {
	position: relative;
	margin-right: 20px;
	animation: thumbnailAnimation 0.5s ease-in-out;
	flex-shrink: 0;

	@keyframes thumbnailAnimation {
		from {
			opacity: 0.3;
		}
		to {
			opacity: 1;
		}
	}

	.thumbnail-image {
		width: 68px;
		height: 40px;
		background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
		box-shadow: 0px 18px 26px rgba(0, 0, 0, 0.35);
		border-radius: 4px;
	}
	.cross-icon {
		position: absolute;
		z-index: 2;
		top: -7px;
		right: -7px;
		border: 2px solid #181a1f;
		box-shadow: 0px 18px 26px rgba(0, 0, 0, 0.35);
		background: #ffffff;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		padding: 7px;
		img {
			width: 9px;
			height: 9px;
		}
	}
	.cross-icon:hover {
		background: rgba(248, 44, 94, 1);
		padding: 4px;
		transition: background 0.2s linear;
		img {
			width: inherit;
			height: inherit;
		}
	}
}
