@import 'src/app/common/utils/scss/main.scss';

.popup-options {
	border-radius: 12px;
	background-color: #2a2a31;
	color: #fff;
	z-index: 2;
	margin-top: 4px;
	box-shadow: 0px 16px 20px rgba(0, 0, 0, 0.25);
	opacity: 0;
	transform: scale(0.7);
	transform-origin: top right;
	animation: openPop 0.15s forwards;
	@keyframes openPop {
		from {
			opacity: 0;
			transform: scale(0.7);
		}
		to {
			opacity: 1;
			transform: scale(1);
		}
	}
	ul {
		height: 100%;
		li:last-child {
			border: 0;
		}
		li:hover {
			p {
				color: $primary-default;
			}
		}
		li {
			// height: 33%;
			display: flex;
			padding: 20px 16px 15px 20px;
			border-bottom: 1px solid #ffffff1a;
			p {
				padding-top: 2px;
				cursor: pointer;
				display: flex;
				align-items: center;
				font-size: 18px;
				font-weight: 600;
				line-height: 17px;
			}
		}
		.active-option {
			color: $primary-default;
		}
	}
}

.option-wrapper-size {
	width: 136px;
}
.dashboard-option-wrapper-size {
	width: 197px;
}

.more-option-box-wrapper-status {
	z-index: 9;
	position: absolute;
	border-radius: 12px;
	background-color: #2a2a31;
	color: #fff;
	top: 30px;
	right: 0px;
	box-shadow: 0px 16px 20px rgba(0, 0, 0, 0.25);
	opacity: 0;
	transform: scale(0.7);
	transform-origin: top right;
	animation: openPop 0.15s forwards;
	@keyframes openPop {
		from {
			opacity: 0;
			transform: scale(0.7);
		}
		to {
			opacity: 1;
			transform: scale(1);
		}
	}
	ul {
		height: 100%;
		li:last-child {
			border: 0;
		}
		li:hover {
			p {
				color: $primary-default;
			}
		}
		li {
			// height: 33%;
			display: flex;
			padding: 20px 8px 15px 20px;
			border-bottom: 1px solid #ffffff1a;
			p {
				padding-top: 2px;
				cursor: pointer;
				display: flex;
				align-items: center;
				font-size: 18px;
				font-weight: 600;
				line-height: 17px;
			}
		}
		.active-option {
			color: $primary-default;
		}
	}
}

.more-option-box-wrapper-screen {
	z-index: 9;
	position: absolute;
	border-radius: 12px;
	background-color: #2a2a31;
	color: #fff;
	top: 20px;
	right: 0px;
	box-shadow: 0px 16px 20px rgba(0, 0, 0, 0.25);
	opacity: 0;
	transform: scale(0.7);
	transform-origin: top right;
	animation: openPop 0.15s forwards;
	@keyframes openPop {
		from {
			opacity: 0;
			transform: scale(0.7);
		}
		to {
			opacity: 1;
			transform: scale(1);
		}
	}
	ul {
		height: 100%;
		li:last-child {
			border: 0;
		}
		li:hover {
			p {
				color: $primary-default;
			}
		}
		li {
			// height: 33%;
			display: flex;
			padding: 20px 8px 15px 20px;
			border-bottom: 1px solid #ffffff1a;
			p {
				padding-top: 2px;
				cursor: pointer;
				display: flex;
				align-items: center;
				font-size: 18px;
				font-weight: 600;
				line-height: 17px;
			}
		}
		.active-option {
			color: $primary-default;
		}
	}
}

.status-wrapper-size {
	width: 156px;
}
.status-option-wrapper-size {
	width: 197px;
}
