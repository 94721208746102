.custom-input-wrapper {
	padding-top: 0;
	width: 100%;
	background-color: #2a2a31;
	height: 50px;
	margin-bottom: 36px;
	position: relative;
	p {
		//styleName: Caps / Medium;
		color: #ffffff6b;
		font-family: 'TT-Commons-Medium';
		font-size: 15px;
		line-height: 19px;
		letter-spacing: 1px;
	}
	.input-box {
		margin-right: 30px;
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 0;
		width: 60%;
		height: 27px;
		background: #2a2a31;
		border: 0;
		border-radius: 8px;
		color: rgba(255, 255, 255, 0.42);
		//styleName: Heading / 4;
		font-family: 'TT-Commons-Bold';
		font-size: 24px;
		font-style: normal;
		font-weight: 700;
		line-height: 28px;
	}
	.input-live {
		width: 84% !important;
		margin-right: 0px;
	}
	.desc-style {
		width: 68%;
		background: transparent;
		border: none;
		resize: none;
		font-family: 'TT-Commons-Regular';
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		line-height: 115%;
		color: #ffffff;
		outline: none;
		margin-left: -2.5px;
		display: relative;
		overflow: hidden;
		resize: none;

		&::-webkit-scrollbar {
			width: 3px;
			height: 25px;
			background: transparent;
		}
		&::-webkit-scrollbar-track {
			background: transparent;
		}
		&::-webkit-scrollbar-thumb {
			background: #888;
		}
	}
}

.desc-input-wrapper {
	height: auto;
}
.input-error-wrapper {
	margin-top: 4px;
	display: flex;

	.color-white {
		color: #fff;
	}
}
.error-state-field {
	p {
		color: #eb2c5b;
		font-family: 'TT-Commons-Regular';
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 120%;
		position: absolute;
		left: 45%;
	}
}

.disableInput {
	pointer-events: none;
	opacity: 0.5;
}
