@import 'src/app/common/utils/scss/main.scss';

#scrollableDivListForYou {
	max-height: 72.5vh !important;
	overflow: auto;
	padding-top: 10px;
	.infinite-scroll-component {
		padding-top: 53px;
	}
	&::-webkit-scrollbar {
		width: 3px;
		height: 25px;
		background: transparent;
	}
	&::-webkit-scrollbar-track {
		background: transparent;
	}
	&::-webkit-scrollbar-thumb {
		background: #888;
	}

	&.padding-bottom-increase {
		.infinite-scroll-component {
			padding-bottom: 110px;
		}
	}

	.table-list-for-you {
		background: #2a2a31;
		width: 100% !important;
		border-collapse: separate;
		border-spacing: 0 2px;
		border: none;
		table-layout: auto;
		tbody {
			tr {
				transition: transform 1s ease;
				&.translateX {
					transform: translate(10px, -10px);
				}
			}
		}

		.column-head {
			vertical-align: middle;
		}
		thead {
			tr {
				box-shadow: none;
				height: 74px;
				background: #363541;
				position: absolute;
				left: 0;
				right: 0;
				z-index: 2;
				transform: translateY(-76px);
				width: 100%;
				display: flex;
				align-items: center;
				border-radius: 8px 8px 0 0;
			}
		}
	}

	tr {
		&.odd {
			background: #2a2a31;
			box-shadow: 0px 16px 20px rgba(0, 0, 0, 0.25);
			height: 64px;
		}
		&.even {
			background: #26242c;
			box-shadow: 0px 16px 20px rgba(0, 0, 0, 0.25);
			height: 64px;
		}
		&.deamed {
			opacity: 0.4;
		}
		td {
			font-family: 'TT-Commons-Regular';
			font-style: normal;
			font-weight: normal;
			font-size: 18px;
			line-height: 135%;
			color: rgba(255, 255, 255, 0.7);
			vertical-align: middle;

			&.row-dateRange {
				width: 30%;
			}

			&.row-bucketName {
				width: 40%;
				.checkbox {
					margin-left: 24px;
					display: flex;
					align-items: center;
					.grab-icon {
						margin-right: 28px;
						cursor: pointer;
					}
					.bucket-name {
						margin-top: 4px;
						cursor: pointer;
					}
				}
			}

			&.row-videoCount {
				width: 120px;
				text-align: right;
				padding-right: 8px;
			}

			&:last-child {
				border-radius: 0px 8px 0px 0px;
			}
			&.row-icon {
				cursor: pointer;
				position: relative;
				// width: 10%;
				width: calc(30% - 120px);
				text-align: revert;
				.options {
					display: flex;
					align-items: center;
					justify-content: center;
					// margin-left: 47%;
					img {
						cursor: pointer;
					}
					.selected-dots {
						margin-right: 8px;
					}
				}
				.padding-more {
					margin-left: 5px;
				}
				.kebab-dropdown {
					z-index: 1;
					position: absolute;
					top: 37px;
					display: flex;
					left: -75px;
					cursor: pointer;
					align-items: center;
					justify-content: center;
					width: 100%;
				}
			}
		}
	}
	th {
		text-align: left;
		font-family: 'TT-Commons-Demi';
		font-style: normal;
		color: #fff;
		font-size: 22px;
		font-weight: 600;
		letter-spacing: 0;
		line-height: 164%;
		vertical-align: middle;
		padding-top: 0.5%;

		&:first-child {
			border-radius: 8px 0px 0px 0px;
			width: 40%;
		}
		&:last-child {
			border-radius: 0px 8px 0px 0px;
			width: calc(30% - 120px);
			text-align: revert;
		}
		&.dateRange {
			width: 30%;
		}
		&.videoCount {
			width: 120px;
		}
		&.name {
			.name-span {
				margin-left: 36px;
			}
		}
	}
}
