.custom-status-field {
	background-color: #1f1f23;
	color: #ffffff;
	height: 26px !important;
	border-radius: 26px;
	display: flex;
	margin: auto;
	margin-top: -5px;
	margin-left: 0px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 8px;
	p {
		font-family: 'TT-Commons-Regular';
		padding-top: 3.5px;
		font-size: 18px;
		font-weight: 400;
		line-height: 24.3px;
		letter-spacing: 0em;
		margin-right: 6px;
		text-transform: capitalize;
	}
}
.img-status {
	margin-right: 5px;
	width: 6px;
	height: 6px;
}
.img-down-arrow-div-status {
	text-align: right;
	width: 100%;
	margin-right: 8.75px;
	margin-top: 1px;
	position: relative;
}
.select-box-down-arrow-rotate {
	transform: rotate(180deg);
	transition: transform 0.3s linear;
}
.select-box-down-arrow-unrotate {
	transform: rotate(0);
	transition: transform 0.3s linear;
}
