@import 'src/app/common/utils/scss/main.scss';

#scrollableDivLiveSubCategories {
	max-height: 73.5vh !important;
	overflow: auto;
	padding-top: 10px;
	.infinite-scroll-component {
		padding-top: 53px;
	}
	&::-webkit-scrollbar {
		width: 3px;
		height: 25px;
		background: transparent;
	}
	&::-webkit-scrollbar-track {
		background: transparent;
	}
	&::-webkit-scrollbar-thumb {
		background: #888;
	}

	&.padding-bottom-increase {
		.infinite-scroll-component {
			padding-bottom: 110px;
		}
	}
	.table-live {
		background: '#2A2A31';
		width: 100% !important;
		border-collapse: separate;
		border-spacing: 0 2px;
		border: 'none';
		table-layout: auto;
		tbody {
			tr {
				transition: transform 1s ease;
				&.translateX {
					transform: translate(-10px, -10px);
				}
			}
		}

		thead {
			tr {
				position: absolute;
				transform: translateY(-76px);
				width: 100%;
				left: 0;
				right: 0;
				display: flex;
				align-items: center;
				z-index: 2;
				border-radius: 8px 8px 0 0;
			}
		}

		.column-head {
			vertical-align: middle;
		}

		tr:first-child {
			box-shadow: none;
			height: 74px;
			background: #363541;
		}

		tr {
			&.odd {
				background: #2a2a31;
				box-shadow: 0px 16px 20px rgba(0, 0, 0, 0.25);
				height: 64px !important;
				cursor: move;
			}
			&.even {
				background: #26242c;
				box-shadow: 0px 16px 20px rgba(0, 0, 0, 0.25);
				height: 64px;
				cursor: move;
			}
			.grab-icon {
				margin-right: 28px;
				cursor: pointer;
			}
			span.name-span {
				padding-top: 2px;
			}
			span.category-span {
				padding-left: 10px;
			}

			td {
				font-family: 'TT-Commons-Regular';
				font-style: normal;
				font-weight: normal;
				font-size: 18px;
				line-height: 135%;
				color: rgba(255, 255, 255, 0.7);
				vertical-align: middle;
				&:first-child {
					padding-left: 29px;
					width: 32.5%;
				}
				&:last-child {
					display: flex;
					justify-content: flex-start !important;
					padding-top: 20px;
					position: relative;
					padding-left: 35px;
				}
				&.row-icon {
					width: 9.7%;
					padding-left: 1%;
				}
				&.row-category {
					width: 46%;
				}
				.category-capsule {
					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: center;
					padding: 4px 12px;
					border: 2px solid #383746;
					box-sizing: border-box;
					border-radius: 12px;
					width: fit-content;
					margin-left: 10px;
					color: rgba(255, 255, 255, 0.7);
				}
				.category-capsule-inner-text {
					margin-top: 3px;
				}
				.row-category-div {
					display: flex;
					flex-direction: row;
				}
				.category-more {
					display: flex;
					justify-content: center;
					align-items: center;
					margin-left: 10px;
					color: #f82c5e;
					font-family: 'TT-Commons-Regular';
					font-style: normal;
					font-weight: normal;
					font-size: 16px;
					font-weight: 600;
					letter-spacing: 0;
					line-height: 164%;
					margin-top: 7px;
					height: 29px;
					position: relative;
					cursor: pointer;
				}
				.row-icon-img {
					max-width: 75px !important;
					max-height: 35px !important;
					border: none;
					margin-top: 3px;
				}
				.row-icon-img-none {
					display: none;
				}
			}

			th {
				text-align: left;
				font-family: 'TT-Commons-Demi';
				font-style: normal;
				color: #fff;
				font-size: 22px;
				font-weight: 600;
				letter-spacing: 0;
				line-height: 164%;
				vertical-align: middle;
				padding-top: 0.5%;

				&:first-child {
					padding-left: 24px;
					border-radius: 8px 0px 0px 0px;
				}
				&:last-child {
					width: 120px;
					border-radius: 0px 8px 0px 0px;
				}
				&.name {
					width: 32.5%;
				}
				&.icon {
					width: 10%;
				}
				&.category {
					width: 46%;
				}
				&.actions {
					width: 10%;
					margin-left: 20px;
				}
			}
		}

		.checkbox {
			display: inline-flex;
			vertical-align: middle;

			.bucket-name {
				padding-top: 3px;
				cursor: pointer;
				&:hover {
					color: #f82c5e;
				}
			}

			#name-checkbox-row {
				appearance: none;
				-webkit-appearance: none;
				width: 19px;
				height: 19px;
				cursor: pointer;
				background: rgba(40, 40, 40, 0.2);
				color: black;
				-moz-appearance: none;
				border: 1px solid rgba(255, 255, 255, 0.32);
				box-sizing: border-box;
				border-radius: 4px;
				margin-right: 16px;
				margin-top: 4px;
				margin-left: -1px;
			}

			#name-checkbox {
				appearance: none;
				-webkit-appearance: none;
				width: 19px;
				height: 19px;
				cursor: pointer;
				background: rgba(40, 40, 40, 0.2);
				color: black;
				-moz-appearance: none;
				border: 1px solid rgba(255, 255, 255, 0.32);
				box-sizing: border-box;
				border-radius: 4px;
				margin-right: 16px;
				margin-top: 7px;
				.extra-padding {
					padding-top: 32;
				}
			}
			#name-checkbox-row:checked,
			#name-checkbox:checked {
				background-color: #f82c5e;
				padding: 2px 3px;
				border: 0;
				&::after {
					content: '';
					width: 12px;
					height: 9px;
					background-image: url(../../../assets/images/tick.svg);
					display: inline-block;
				}
			}
		}

		.row {
			min-height: 64px;
			border-bottom: 1px solid #181a1f;
			border-top: 1px solid #181a1f;
			background-color: #2a2a31;
		}
	}
	.table-live thead tr:first-child {
		box-shadow: none;
		height: 74px;
		background: #363541;
	}

	@media only screen and (max-width: 1080px) {
		max-height: 77vh;
		.table-live {
			tr {
				th {
					padding-top: 1.2%;
					&:first-child {
						padding-left: 17px;
					}

					&.name {
						padding-right: 64px;
						width: 305px !important;
					}
					&.icon {
						width: 180px !important;
					}
				}
				td {
					padding-top: 6px;
					&:first-child {
						padding-left: 17px;
					}
					&:last-child {
						padding-top: 21px;
						width: 130px;
					}
				}
			}
		}
	}

	@media only screen and (min-width: 1900px) and (max-width: 2200px) {
		.table-live {
			.row-category {
				width: 46.2% !important;
			}
		}
	}
	@media only screen and (min-width: 2201px) {
		.table-live {
			.row-category {
				width: 46.3% !important;
			}
		}
	}

	@media only screen and (min-width: 1280px) {
		max-height: 77.2vh;
	}

	@media screen and (max-width: 1495px) {
		max-height: 71vh !important;
	}
}

.row-actions {
	padding-left: 10px;
	.live-channel-action-button {
		cursor: pointer;
		margin-right: 10px;
		.live-channel-action-button-image {
			width: 20px;
			height: 18px;
		}
		.live-channel-action-button-image-exta-styling {
			width: 20px;
			height: 20px;
		}
	}
}

.tooltip {
	opacity: 0;
	color: rgba(255, 255, 255, 0.7);
	right: -305px; /* moves the tooltip to the right */
	top: -15px; /* moves it down */
	position: absolute;
	z-index: 1000;
	width: 285px;
	visibility: hidden;
	max-height: 200px;
	background: #181a1f;
	padding: 10px 10px 0 0;
	overflow-y: auto !important;
	border-radius: 12px;
	background-color: #181a1f !important;
	animation: showTip 0.5s forwards;
}

@keyframes showTip {
	from {
		opacity: 0;
		visibility: hidden;
	}
	to {
		opacity: 1;
		visibility: visible;
	}
}
.tool::after {
	content: '';
	position: absolute;
	top: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #181a1f transparent transparent transparent;
}

.category-capsule-outer-wrapper {
	margin-bottom: 6px;
	display: inline-block;
}

.t-one:before {
	display: inline;
	margin-left: 8px;
	margin-right: 4px;
	margin-top: -6px;
	content: '';
	position: absolute;
	border: solid;
	border-color: #181a1f transparent;
	border-width: 0 0.5em 0.5em 0.5em;
	z-index: 6000;
	-webkit-transform: rotate(-90deg);
	-moz-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	-o-transform: rotate(-90deg);
	transform: rotate(-90deg);
	pointer-events: none;
	animation: showArrow 0.5s forwards;
	opacity: 0;
	visibility: hidden;
}
@keyframes showArrow {
	from {
		opacity: 0;
		visibility: hidden;
	}
	to {
		opacity: 1;
		visibility: visible;
	}
}

@media screen and (max-width: 1275px) {
	.tooltip {
		right: 60px;
	}
	.t-one:before {
		margin-left: -65px;
		-webkit-transform: rotate(90deg);
		-moz-transform: rotate(90deg);
		-ms-transform: rotate(90deg);
		-o-transform: rotate(90deg);
		transform: rotate(90deg);
	}
}
