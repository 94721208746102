@import "../../common/utils/scss/variables.scss";

.filter-checkbox {
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 135%;
  margin-bottom: 5px;

  label {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 135%;
    color: rgba(255, 255, 255, 0.7);
    padding-top: 1px;
    transform:translateY(1px);
  }

  input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 4px;
    flex: none;
    order: 0;
    flex-grow: 0;
    background-color: inherit;
    margin: 0;
    margin-right: 16px;

    &:checked {
      background: url("../../assets/images/tick.svg") no-repeat;
      background-color: $primary-default;
      background-position: center;
    }
  }
}

.filter-pill {
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 5px;
  line-height: 135%;

  .pill {
    padding: 6px 12px 3px 12px;
    border: 2px solid rgba(255, 255, 255, 0.1);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 100%;
    border-radius: 20px;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.7);
  }

  .pill[aria-checked="true"] {
    background-color: $primary-default;
  }
  .pill[aria-checked="false"] {
    background-color: $filter-background;
  }
}
