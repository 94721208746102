@import 'src/app/common/utils/scss/main.scss';

.carousel-horizontal-content-tile {
	margin-right: 24px;
	min-width: 9.5%;
	cursor: pointer;
	.movie-label {
		padding: 2px 8px;
		background: rgba(255, 145, 41, 0.36);
		border-radius: 8px;
		display: inline-block;
		text-align: center;
		color: #ffffff;
		margin-top: 7px;

		.movie-label-text {
			text-transform: capitalize;
			transform: translateY(1px);
		}
	}
}
.ytSearch {
	margin-right: 0px;
}

.image-parent-block {
	position: relative;
	filter: drop-shadow(0px 18px 26px rgba(0, 0, 0, 0.35));
	border-radius: 6px;
	overflow: hidden;
}

.cardImgHor {
	object-fit: cover;
	height: 100%;
	width: 100%;
	border-radius: 6px;
}

.placeholderImage {
	aspect-ratio: 1.7 !important;
}

.tile-horizontal-label {
	padding: 0px 1.1em 0 0;
	margin-top: 3px;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	max-height: 43px; // remove this if face any sort of problem on dashboard screen
	p {
		color: #ffffffb2;
		padding: 0;
	}
}
.add-icon {
	position: absolute;
	right: 12px;
	bottom: 15px;
	width: 20px;
	height: 20px;
	display: inline-block;
	background-color: rgba(0, 0, 0, 0.8);
	border-radius: 12px;
	cursor: pointer;
	& > img {
		width: 100%;
		height: 100%;
	}
}
