@import '../../common/utils/scss/variables.scss';
.live-channel-right-container {
	margin-top: 1.5%;
	height: 100%;
	display: flex;
	flex-direction: column;

	.live-header-block-channel {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.live-header-block-wrapper {
			display: flex;
			align-items: center;
		}

		.cross-icon {
			width: 25px;
			height: 25px;
			cursor: pointer;
		}
		.count-header-wrapper {
			font-family: 'TT-Commons-Demi';
			font-style: normal;
			font-weight: normal;
			font-size: 24px;
			line-height: 120%;
			display: flex;
			align-items: center;

			color: rgba(255, 255, 255, 0.42);
		}
	}

	.live-search-box-container {
		display: flex;
		flex-direction: row;
		margin-bottom: 31px;
		margin-top: 30px;
		justify-content: space-between;
		align-items: center;
		.live-search-with-cancel {
			width: 30%;
			display: flex;
			align-items: center;

			.cancel-button {
				margin-left: 10px;
				color: white;
				border: none;
			}

			.live-stream-in-channel-search {
				background: rgba(0, 0, 0, 0.5);
				.search-box {
					background: rgba(0, 0, 0, 0);
				}
			}
		}
		.live-header-buttons {
			display: flex;
			align-items: center;
			height: 47px;
			margin-left: auto;
			margin-right: 0px;

			.live-add-button {
				border: none;
				background-color: $primary-default;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 47px;
				height: 47px;
				margin-right: 16px;
				img {
					margin: 0;
					border: none;
				}
			}
			.live-delete-button {
				border: none;
				background-color: $primary-default;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 47px;
				height: 47px;
				margin-right: 16px;
				img {
					margin: 0;
					border: none;
				}
			}
			.live-app-button {
				margin-right: 16px;
				.live-app-button-text {
					font-weight: 600;
					font-size: 20px;
					line-height: 115%;
					color: #ffffff;
					padding: 13px 15px;
					border-radius: 8px;

					span {
						line-height: 20px;
						display: inline-block;
						transform: translateY(2px);
					}
				}
				&:last-child {
					margin-right: 0px;
				}
			}
		}
	}

	.content-block {
		position: relative;
		// hiding scroll bar
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
		max-height: fit-content;
	}

	@media only screen and (max-width: 1080px) {
		margin-top: 5%;
		margin-left: 2px;
		max-height: 95vh;

		.bucket-header-block {
			margin-bottom: 3.5%;
		}

		.tab-button-block {
			margin-bottom: 0.7em;
		}
	}
}
