@import 'src/app/common/utils/scss/variables.scss';

.publish-confirmation-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.title {
		color: rgba(255, 255, 255, 0.7);
		font-size: 20px;
		text-align: center;
		font-weight: 600;
		line-height: 120%;
		padding: 32px;
	}

	.frequency-blocks {
		display: flex;
		flex-direction: row;
		margin-bottom: 45px;
		justify-content: space-between;
		width: 80%;
	}

	.buttons {
		display: flex;
		.sk-circle {
			margin: 0;
		}
		.cancel {
			margin-right: 10px;
			background: $sidebar-bg;
			border: 1px solid rgba(255, 255, 255, 0.1);
		}
		button {
			color: rgb(255, 255, 255);
			border: none;
			background: $primary-default;
			border-radius: 8px;
			padding: 7.5px 23px;
			font-weight: 600;
			font-size: 18px;
		}
	}
}

.frequency-container {
	display: flex;
	flex-direction: column;
	border: 2px solid #383746;
	box-sizing: border-box;
	border-radius: 12px;
	width: 100px;
	height: 80px;
	align-items: center;
	background: #2a2a31;
	margin-left: 10px;

	.input-publish {
		appearance: none;
		-webkit-appearance: none;
		width: 19px;
		height: 19px;
		cursor: pointer;
		background: rgba(40, 40, 40, 0.2);
		color: black;
		-moz-appearance: none;
		border: 1px solid rgba(255, 255, 255, 0.7);
		box-sizing: border-box;
		border-radius: 26px;
		margin-top: 18px;
	}
	.label-publish {
		margin-top: 7px;
		color: white;
		font-family: 'TT-Commons-Demi';
		font-style: normal;
		font-weight: 600;
		font-size: 16px;
		line-height: 19px;
		text-align: center;
	}
	#checkbox-publish-row:checked,
	#checkbox-publish:checked {
		background-color: white;
		padding: 2px 3px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 0;
		&::after {
			content: '';
			width: 12px;
			height: 9px;
			background-image: url(../../assets/images/PublishTick.svg);
			display: inline-block;
		}
	}
}

.active-input-container {
	background: #f82c5e;
}

.wrapper-frequency {
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: 100%;
}
.disableInputButton {
	pointer-events: none;
	opacity: 0.5;
}
