@import '../../common/utils/scss/variables.scss';

.image-button-container {
	.button {
		display: flex;
		align-items: center;
		justify-content: center;
		border: none;
		border: 1px solid rgba(255, 255, 255, 0.1);
		font-family: 'TT-Commons-Regular';
		border-radius: 8px;

		.label {
			font-style: normal;
			font-weight: 600;
			font-size: 20px;
			line-height: 115%;
			letter-spacing: 0.4px;
			transform: translateY(3px);
		}

		img {
			margin-right: 12px;
		}

		.label-grey {
			color: rgba(255, 255, 255, 0.7);
		}
		.label-white {
			color: #ffffff;
		}

		&.button-black {
			background-color: $sidebar-bg;
			padding: 11.5px 18px 11.5px 21px;
			transition: background-color 0.5s linear;
			margin-right: 6px;
		}
		&.extra-spacing-right {
			margin-right: 12px;
		}
		&.button-pink {
			background-color: $primary-default;
			padding: 11.5px 29px 11.5px 29px;
			transition: background-color 0.5s linear;
			margin-right: 6px;
		}
	}
}

.disable-button {
	pointer-events: none;
	opacity: 0.5;
}
