.tag-pill-container {
	display: inline-block;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px 20px;
	background: #26242c;
	border-radius: 12px;
	flex: none;
	order: 0;
	flex-grow: 0;
	margin-right: 8px;
	margin-bottom: 8px;

	.tags-wrap {
		display: flex;
		.tag-pills {
			font-family: 'TT-Commons-Medium';
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 120%;
			color: rgba(255, 255, 255, 0.7);
			text-transform: capitalize;
		}
		.img-pills {
			margin-left: 8.75px;
			cursor: pointer;
		}
	}
}
