.digest-modal-container {
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	position: relative;
	padding: 32px 36px;
	border-radius: 12px;
	transition: max-height 0.5s linear;
	&-content {
		overflow-y: auto;
		overflow-x: hidden;
		width: 100%;
		height: 100%;
		transition: all 0.3s ease-in-out;
		scroll-behavior: smooth;
		-ms-overflow-style: none;
		scrollbar-width: none;
		display: flex;
		flex-direction: column;
		&::-webkit-scrollbar {
			width: 3px;
			height: 25px;
			background: transparent;
		}
		&::-webkit-scrollbar-track {
			background: transparent;
		}
		&::-webkit-scrollbar-thumb {
			background: #888;
		}

		&::-webkit-scrollbar {
			width: 3px;
			height: 25px;
			background: transparent;
		}
		&::-webkit-scrollbar-track {
			background: transparent;
		}
		&::-webkit-scrollbar-thumb {
			background: #888;
		}

		.digest-modal-container-default-message {
			display: flex;
			flex-direction: column;
			height: 80px;
			background: transparent;
			// transform: translateX(30%);
			margin-bottom: 16px;
			.digest-default-message-header {
				font-family: 'TT-Commons-Regular';
				font-style: normal;
				font-weight: normal;
				font-size: 15px;
				line-height: 130%;
				letter-spacing: 1px;
				text-transform: uppercase;
				color: rgba(255, 255, 255, 0.42);
				margin-bottom: 4px;
			}
			.digest-default-message-body {
				font-family: 'TT-Commons-Regular';
				font-style: normal;
				font-weight: bold;
				font-size: 24px;
				line-height: 115%;
				color: #ffffff;
				width: 580px;
				display: flex;
				align-items: center;

				.tick-button {
					width: 30px;
					height: 30px;
					background-color: #4fd471;
					border: none;
					border-radius: 50%;
					text-align: center;
					cursor: pointer;
					outline: none;
				}
				.tick-button:hover {
					opacity: 0.86;
				}

				.digest-card-wrapper-desc-text {
					width: 100%;
					background: transparent;
					border: none;
					resize: none;
					font-family: 'TT-Commons-Regular';
					font-style: normal;
					font-weight: bold;
					font-size: 24px;
					line-height: 115%;
					color: #ffffff;
					outline: none;
					&::-webkit-scrollbar {
						width: 3px;
						height: 25px;
						background: transparent;
					}
					&::-webkit-scrollbar-track {
						background: transparent;
					}
					&::-webkit-scrollbar-thumb {
						background: #888;
					}
				}
				.focusText {
					color: #f82c5e !important;
				}
			}
		}

		.digest-card-container {
			width: 100%;
			height: 100%;
			background: transparent;
			padding-top: 16px;
		}
	}

	.digest-modal-header {
		padding-top: 0;
		color: #fff;
		font-style: normal;
		font-weight: 600;
		font-size: 22px;
		line-height: 164%;
		padding-bottom: 12px;
		border-bottom: 1px solid #0e0e171f;
		margin-bottom: 28px;
		z-index: 1;
		position: relative;
		background-color: #2a2a31;
	}
	.digest-action-button {
		display: flex;
		justify-content: flex-end;
		margin-top: 30px;
		span:last-child {
			margin-left: 24px;
		}
	}
}
.d-block {
	display: block;
}
.d-none {
	display: none;
}
.popup-content-container {
	transform: scale(0.9);
	margin-top: 140px;
	transition: all 0.3s ease-in-out;
	animation: modalAnim 0.2s forwards;
}
@keyframes modalAnim {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
.popup-overlay {
	background: rgba(0, 0, 0, 0.1);
	animation: overlayAnim 0.3s forwards;
}
@keyframes overlayAnim {
	from {
		background: rgba(0, 0, 0, 0.1);
	}
	to {
		background: rgba(0, 0, 0, 0.86);
	}
}
