@import 'src/app/common/utils/scss/variables.scss';

.carousel-wrapper {
	background-color: $disabled-bg;
	width: 100%;
	border-radius: 8px;
	padding-bottom: 31px;
	margin-bottom: 20px;

	.carousel-header-block {
		padding: 28px 71px 0 40px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		margin-bottom: 12px;
		.heading {
			display: flex;
			&:hover {
				cursor: pointer;
				h3 {
					color: $primary-default;
				}
			}
			h3 {
				margin-top: 5px;
				font-style: normal;
				font-weight: normal;
				font-size: 28px;
				line-height: 135%;
				text-align: center;
				font-family: $font-stack-demi;
				color: rgba(255, 255, 255, 0.7);
			}
			h3:hover {
				color: $primary-default;
				cursor: pointer;
			}
			.hover-image-round {
				background: rgba(0, 0, 0, 0.2);
				border-radius: 50%;
				padding: 10px;
				width: 40px;
				height: 40px;
			}
			.navigate-icon {
				margin-left: 2px;
				margin-top: 14px;
				width: 12px;
				height: 12px;
			}
		}
	}

	.right-aligned-options {
		position: relative;
		margin-right: 9px;
		.options {
			display: flex;
			align-items: center;
			img {
				cursor: pointer;
			}
		}

		.kebab-dropdown {
			z-index: 1;
			position: absolute;
			top: 26px;
			left: -100px;
		}
	}

	.carousel-cardWrap {
		position: relative;

		.carousel-content-block {
			padding: 0 0px;
			padding-right: 1px;
			position: relative;
			width: 100%;
			display: flex;
			align-items: flex-start;
			justify-content: flex-start;
			.carousel-container {
				margin-left: 40px;
			}
			button {
				color: white;
			}
			button[disabled] {
				color: grey;
				opacity: 0.5;
			}
			div > .tns-item {
				width: 7.5% !important;
				padding-right: 0 !important;
			}
		}
	}

	@media only screen and (max-width: 1080px) {
		padding-bottom: 19px;
		.carousel-header-block {
			padding: 28px 87px 0 28px;

			.heading {
				.Popular {
					font-size: 20px;
				}
				.Test {
					font-size: 20px;
				}
			}
		}
		.carousel-cardWrap {
			.carousel-content-block {
				.carousel-container {
					margin-left: 28px;
				}
			}
		}
	}
}

.previous {
	height: 180px;
	width: 22px;
	background-color: rgba(24, 26, 31, 0.8);
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	display: none;
	z-index: 9;
	bottom: 5%;
	top: 0;
	margin: auto;
	&::after {
		content: '';
		transform: rotate(135deg);
		-webkit-transform: rotate(135deg);
		color: #f82c5e;
		border: solid #f82c5e;
		border-width: 0 2px 2px 0;
		display: inline-block;
		padding: 6px;
	}
	&[disabled] {
		display: none !important;
	}
}
.next {
	height: 180px;
	width: 22px;
	background-color: rgba(24, 26, 31, 0.8);
	bottom: 5%;
	top: 0;
	margin: auto;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	z-index: 9;
	display: none;
	&::after {
		content: '';
		transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
		border: solid #f82c5e;
		border-width: 0 2px 2px 0;
		display: inline-block;
		padding: 6px;
		color: #f82c5e;
	}
	&[disabled] {
		display: none !important;
	}
}
.carousel-container {
	width: 100%;
}

.carousel-content-block:hover ~ .controls .previous,
.controls .previous:hover {
	display: block;
}
.carousel-content-block:hover ~ .controls .next,
.controls .next:hover {
	display: block;
}

.react-multiple-carousel__arrow {
	border-radius: 0px !important;
	background: none !important;
	position: relative !important;
	margin-top: 0px;
}
.carousel-wrapper:hover .react-multiple-carousel__arrow {
	position: absolute !important;
}
.react-multiple-carousel__arrow::before {
	height: 43px !important;
	width: 43px !important;
	min-width: 0px !important;
	min-height: 0px !important;
	background: $body-background-color !important;
	opacity: 0.8 !important;
	padding-top: 11px;
	border-radius: 50%;
	color: #f82c5e !important;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
}

.react-multiple-carousel__arrow--right {
	right: 24px !important;
	margin-right: -24px !important ;
}
.react-multiple-carousel__arrow--left {
	left: 0 !important;
}

.carousel-content-block button {
	top: auto;
}
