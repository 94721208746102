@import '../../../common/utils/scss/variables.scss';
.delete-confirmation-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;

	.cross-popup {
		max-width: 16px;
		height: 16px;
		position: absolute;
		right: 2%;
		top: 12.8px;
		z-index: 10;
		cursor: pointer;
	}
	.align-icon {
		left: 298px;
	}

	.title {
		color: rgba(255, 255, 255, 0.7);
		font-size: 20px;
		text-align: center;
		font-weight: 600;
		line-height: 120%;
		padding: 32px;
	}

	.buttons {
		display: flex;
		.sk-circle {
			margin: 0;
		}
		.cancel {
			margin-right: 10px;
			background: $sidebar-bg;
			border: 1px solid rgba(255, 255, 255, 0.1);
		}
		button {
			color: rgb(255, 255, 255);
			border: none;
			background: $primary-default;
			border-radius: 8px;
			padding: 7.5px 23px;
			font-weight: 600;
			font-size: 18px;
			text-transform: capitalize;
		}
	}
	.live-margin {
		margin-bottom: 14px;
	}
}
