@import 'src/app/common/utils/scss/variables.scss';
#scrollableDivLiveCategory {
	max-height: 80vh !important;
	// height: 80vh !important;
	overflow-y: scroll;
	overflow-x: hidden !important;
	margin-top: 26px;
	// padding-bottom: 15%;

	@media only screen and (max-height: 900px) {
		#scrollableDivLiveCategory {
			padding-bottom: 20%;
		}
	}
	&::-webkit-scrollbar {
		width: 3px;
		height: 25px;
		background: transparent;
	}
	&::-webkit-scrollbar-track {
		background: transparent;
	}
	&::-webkit-scrollbar-thumb {
		background: #888;
	}
}

.category-grid-container {
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
	position: relative;
	// max-width: 258px;
	height: 162px;
	border-radius: 4px;
	cursor: move;
	.overlay-edit {
		display: none;
	}
	&:hover .overlay-edit {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		background: rgba(0, 0, 0, 0.7);
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 101;

		.hover-image-round {
			background: rgba(0, 0, 0, 0.5);
			border-radius: 50%;
			padding: 10px;
			width: 40px;
			height: 40px;
			cursor: pointer;
		}
		.navigate-icon {
			margin-left: 2px;
			width: 38px;
			height: 38px;
		}
	}
	.selection-button {
		input[type='checkbox'] {
			position: absolute;
			appearance: none;
			background: rgba(24, 26, 31, 0.9);
			border: 1px solid $filter-tag-color;
			width: 20px;
			height: 20px;
			border-radius: 50%;
			left: 5px;
			top: 5px;
			z-index: 2;
			cursor: pointer;

			&:checked {
				background: url('../../../assets/images/tick.svg') no-repeat;
				background-color: #f82c5e;
				background-position: center;
				border: 1px solid $primary-default;
			}
		}
	}
	.category-grid-container-img {
		width: 100%;
		height: 100% !important;
		position: relative;
		border-radius: 4px;
	}
	.category-grid-bottom-container {
		width: 100%;
		height: 63px;
		background: linear-gradient(360deg, #000000 45.83%, rgba(0, 0, 0, 0) 104.17%);
		border-radius: 4px;
		display: flex;
		position: absolute;
		z-index: 10;
		.category-grid-title {
			width: 80%;
			margin-left: 16px;
			margin-top: 28px;
			font-style: normal;
			font-weight: 600;
			font-size: 16px;
			line-height: 19px;
			color: #ffffff;
			font-family: 'TT-Commons-Demi';
		}
		.category-grid-count-container {
			position: relative;
			margin-top: 24px;
			margin-right: 13px;
			width: 26.23px;
			height: 25.18px;
			background: #ff9129;
			border-radius: 8px;
			display: flex;
			align-items: center;
			justify-content: center;
			.category-grid-count {
				height: 100%;
				font-family: 'TT-Commons-Regular';
				font-style: normal;
				font-weight: normal;
				font-size: 15px;
				line-height: 19px;
				display: flex;
				align-items: center;
				letter-spacing: 1px;
				text-transform: uppercase;
				color: #ffffff;
				padding-top: 4px;
			}
		}
	}
}

.loader-wrapper {
	height: 80vh !important;
}
