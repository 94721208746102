@import 'src/app/common/utils/scss/main.scss';
.create-live-popup-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	.create-live-popup-wrapper {
		padding-left: 32px;
		width: 100%;
		height: 100%;
		.select-popup-error-wrapper {
			width: 90%;
			overflow-y: auto;
			overflow-x: hidden;
			&::-webkit-scrollbar {
				width: 3px;
				height: 10px;
			}
			&::-webkit-scrollbar-track {
				background: transparent;
			}
			&::-webkit-scrollbar-thumb {
				background: #ccc;
			}

			.popup-option-wrapper-size {
				&::-webkit-scrollbar {
					width: 3px;
					height: 10px;
				}
				&::-webkit-scrollbar-track {
					background: transparent;
				}
				&::-webkit-scrollbar-thumb {
					background: #ccc;
				}
				.infinite-scroll-component::-webkit-scrollbar {
					width: 3px;
					height: 10px;
				}
				.infinite-scroll-component::-webkit-scrollbar-track {
					background: transparent;
				}
				.infinite-scroll-component::-webkit-scrollbar-thumb {
					background: #ccc;
				}
			}

			.custom-select-dropdown-wrapper {
				align-items: baseline;
				height:45px;
				p {
					padding: 0;
				}
				.down-arrow {
					display: flex;
					height: 100%;
					flex-shrink: 0;
					flex-grow: 0;
					align-items: center;
					img {
						width: 15px;
						margin-right: 10px;
					}
				}
			}
		}
		.create-live-popup-message {
			color: #ffffff !important;
			position: relative;
			width: 100%;
			font-size: 20px;
			font-weight: 600;
			line-height: 120%;
			text-align: left;
			padding: 32px;
			padding-left: 0px !important;
			padding-bottom: 0px !important;
			font-family: 'TT-Commons-Demi';
			margin-bottom: 14px;
		}
		.create-live-popup-seperator {
			width: 94%;
			height: 0px;
			border: 1px solid rgba(14, 14, 23, 0.2);
			margin-bottom: 24px;
		}
		.create-live-popup-input-wrapper {
			width: 100%;
			margin-bottom: 36px;
			.input-live {
				font-size: 18px;
			}
		}
		.create-live-popup-category {
			margin-bottom: 36px;
		}
		.names {
			p {
				font-size: 18px;
			}
		}
		.create-live-popup-file-picker {
			margin-bottom: 30px;
			.file-picker-outerdiv {
				.file-picker-span {
					font-size: 18px !important;
				}
			}
			.create-live-popup-progressbar {
				margin-top: -10px;
			}
			.create-live-popup-progressbar-category {
				margin-top: -14px;
				z-index: 1000;
			}
			.upload-error-div {
				color: #f82c5e;
				font-family: 'TT-Commons-Regular';
				margin-top: 10px;
				font-size: 16px;
				padding-left: 4px;
			}
		}
		.upload-margin {
			margin-bottom: 24px !important;
		}
		.file-picker-on-error {
			margin-bottom: 20px !important;
		}
		.file-picker-on-error-cat {
			margin-bottom: 10px !important;
		}
		.create-live-popup-buttons {
			display: flex;
			margin-right: 22px;
			margin-left: auto;
			width: fit-content;
			.create-live-popup-cancel {
				margin-right: 24px;
				background: $sidebar-bg;
				border: 1px solid rgba(255, 255, 255, 0.1);
			}
			button {
				color: rgb(255, 255, 255);
				border: none;
				background: $primary-default;
				border-radius: 8px;
				padding: 7.5px 23px;
				font-weight: 600;
				font-size: 18px;
			}
			.create-live-popup-disable-button {
				pointer-events: none;
				opacity: 0.5;
			}
		}
	}
}
