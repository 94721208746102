.bucket-right-container {
	margin-top: 1.9%;
	height: 100%;
	display: flex;
	flex-direction: column;

	.icon-button {
		width: 3.25em;
		height: 3.25em;
		background-color: #26242c;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.wrapper-right {
		display: flex;
		align-items: center;
	}
	.right-button-block {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 8px;
		margin-right: 12px;

		img {
			margin-right: 1.25em;
		}
		.icon-button-span {
			margin-left: 20px;
		}
		img:last-child {
			margin-right: 0;
		}
	}

	.adjust-screen {
		margin-right: 0px;
		margin-left: 8px;
		margin-top: -16px;
	}
	.adjust-screen-top {
		margin-right: 0px;
		margin-left: 16px;
	}
	.adjust-padding {
		padding: 16px !important;
		height: 46px !important;
	}
	.disable-button {
		pointer-events: none;
		opacity: 0.6;
	}

	.tab-button-block {
		width: 100%;
		border-bottom: 1px solid #2a2a31;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 4.2em;
	}

	.bucket-header-block {
		margin-bottom: 20px;
	}
	.create-bucket {
		width: fit-content;
		float: right;
		display: flex;
		.create-bucket-list {
			margin-right: 8px;
		}
	}
	.searchbox-wrapper {
		width: 30%;
		display: flex;
		align-items: center;
		margin-bottom: 20px;
		.cancel-button {
			margin-left: 10px;
			color: white;
			border: none;
		}

		.live-stream-in-channel-search {
			background: rgba(0, 0, 0, 0.5);
			.search-box {
				background: rgba(0, 0, 0, 0);
			}
		}
	}

	@media only screen and (max-width: 1080px) {
		margin-top: 5%;
		margin-left: 2px;
		max-height: 95vh;

		.bucket-header-block {
			margin-bottom: 3.5%;
		}

		.tab-button-block {
			margin-bottom: 2.7em;
		}
	}
}
