@import "src/app/common/utils/scss/main.scss";
.not-found-page {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.not-found-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.not-found-img {
    height: 491px;
    width: 491px;
    background: $tabs-bg;
    @include border-radius(50%);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 83px;
    img {
        height: 321px;
        width: 207px;
    }
}

.not-found-desc {
    h1 {
        color: $error-color;
        font-size: $base-font + 66;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 120px;
    }
    .not-found-text {
        color: $tabs-color;
        font-size: $base-font + 6;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 30px;
        margin-bottom: 29px;
        display: block;
    }
    .btn-wrap {
        .black-btn {
            padding: 13px 22px 13px;
            height: 46px;
            width: 212px;
        }
        text-align: left;
    }
}