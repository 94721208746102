@import 'src/app/common/utils/scss/main.scss';

.option-box-wrapper {
	border-radius: 12px;
	background-color: #2a2a31;
	color: #fff;
	position: absolute;
	z-index: 2;
	margin-top: 4px;
	box-shadow: 0px 16px 20px rgba(0, 0, 0, 0.25);
	opacity: 0;
	transform: scale(0.7);
	transform-origin: top right;
	animation: openPop 0.15s forwards;
	z-index: 3;
	&::-webkit-scrollbar {
		width: 3px;
		height: 25px;
		background: transparent;
	}
	&::-webkit-scrollbar-track {
		background: transparent;
	}
	&::-webkit-scrollbar-thumb {
		background: #888;
	}

	.list-items {
		li:hover {
			background: #26242c;
		}
		&-name {
			text-transform: capitalize;
		}
	}

	.label {
		font-style: normal;
		font-weight: normal;
		font-size: 15px;
		line-height: 130%;
		letter-spacing: 1px;
		text-transform: uppercase;
		color: rgba(255, 255, 255, 0.42);
		padding: 20px 0px 0px 20px;
	}
	@keyframes openPop {
		from {
			opacity: 0;
			transform: scale(0.7);
		}
		to {
			opacity: 1;
			transform: scale(1);
		}
	}
	ul {
		height: 100%;
		li:last-child {
			border: 0;
		}
		li:hover {
			p {
				color: $primary-default;
			}
		}
		li {
			height: 48px;
			display: flex;
			align-items: center;
			padding: 2px 16px 0 20px;
			border-bottom: 1px solid #ffffff1a;
			p {
				padding-top: 2px;
				cursor: pointer;
				display: flex;
				align-items: center;
				font-size: 18px;
				font-weight: 600;
				line-height: 17px;
			}
		}
		.active-option {
			color: $primary-default;
		}
	}

	&.dashboard-option-wrapper-size {
		width: 311px;
		// overflow-y: scroll;
		overflow-y: hidden;
		height: 480px;
		.searchWrapper {
			margin-bottom: 1px;
		}
	}

	&.popupModal-option-wrapper-size {
		width: 328px;
		overflow-y: scroll;
		background-color: #181a1f;

		ul {
			li {
				border: none;
			}
		}
	}
}
.option-li-wrapper {
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
}
.option-wrapper-size {
	width: 136px;
}

.popup-option-wrapper-size {
	width: 270px;
	max-height: 300px;
	overflow-y: scroll;
}
.infinite-scroll-option-box {
	height: auto;
	&::-webkit-scrollbar {
		width: 3px;
		height: 25px;
		background: transparent;
	}
	&::-webkit-scrollbar-track {
		background: transparent;
	}
	&::-webkit-scrollbar-thumb {
		background: #888;
	}
}
