.primary-text-button {
	padding: 8.5px 20.5px;
	background: #f82c5e;
	border-radius: 12px;
	border: 1px solid rgba(255, 255, 255, 0.1);
	cursor: pointer;
	p {
		color: #fff;
		//styleName: Body/LG/DemiBold;
		font-family: 'TT-Commons-Demi';
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 23px;
		letter-spacing: 0em;
		text-align: left;
		padding-top: 4px;
	}
}
.padding-main {
	padding: 12.5px 24.5px 8.5px;
}
.padding-secondary {
	padding: 7.5px 20.5px 9px;
}
.disableInputButton {
	pointer-events: none;
	opacity: 0.5;
}
