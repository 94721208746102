@import 'src/app/common/utils/scss/main.scss';

#infinite-table-container {
	max-height: 75vh;
	overflow: auto;

	.infinite-scroll-component {
		padding-bottom: 200px;
	}

	&::-webkit-scrollbar {
		width: 3px;
		height: 25px;
		background: transparent;
	}
	&::-webkit-scrollbar-track {
		background: transparent;
	}
	&::-webkit-scrollbar-thumb {
		background: #888;
	}
	.table {
		height: inherit;
		background: '#2A2A31';
		width: 100% !important;
		margin-top: 4.5px;
		border-collapse: separate;
		border-spacing: 0 2px;
		border: 'none';
		table-layout: auto;
		tbody {
			tr {
				transition: transform 1s ease;
				&.translateX {
					transform: translate(-10px, -10px);
				}
			}
		}

		thead {
			tr {
				position: absolute;
				transform: translateY(-74px);
				width: 100%;
				left: 0;
				right: 0;
				display: flex;
				align-items: center;
				z-index: 2;
				border-radius: 8px 8px 0 0;
			}
		}

		.column-head {
			vertical-align: middle;
		}

		tr:first-child {
			box-shadow: none;
			height: 74px;
			background: #363541;
		}

		tr {
			&.odd {
				background: #2a2a31;
				box-shadow: 0px 16px 20px rgba(0, 0, 0, 0.25);
				height: 64px !important;
				cursor: pointer;
			}
			&.even {
				background: #26242c;
				box-shadow: 0px 16px 20px rgba(0, 0, 0, 0.25);
				height: 64px;
				cursor: pointer;
			}
			.row-dateRange {
				padding-left: 20px;
			}
			.grab-icon {
				margin-right: 28px;
				cursor: pointer;
			}
			span.name-span {
				padding-top: 2px;
			}
			span.date-span {
				padding-left: 10px;
			}
			span.status-span {
				padding-left: 10px;
				padding-top: 4.5px;
			}
			span.video-span {
				padding-top: 4.5px;
			}
			span.screen-span {
				padding-top: 4.5px;
			}

			td {
				font-family: 'TT-Commons-Regular';
				font-style: normal;
				font-weight: normal;
				font-size: 18px;
				line-height: 135%;
				color: rgba(255, 255, 255, 0.7);
				vertical-align: middle;
				&:first-child {
					padding-left: 29px;
				}
				&.row-videoCount {
					// padding-left: 98px;
					text-align: right;
					width: 113px;
				}
				&:nth-last-child(2) {
					padding-left: 12.5%; //14%
				}
				&:last-child {
					// display: flex;
					// justify-content: center;
					padding-top: 20px;
					position: relative;
				}
				&:last-child:hover {
					color: #f82c5e;
				}
				&.status-field {
					.img-down-arrow-div {
						text-align: right;
						width: 100%;
						margin-right: 8.75px;
						margin-top: -2px;
					}
					.img-down-arrow {
						margin-left: 4px;
					}
					.img-down-arrow-div {
						text-align: right;
						width: 100%;
						margin-top: -2px;
					}
				}
			}

			th {
				text-align: left;
				font-family: 'TT-Commons-Demi';
				font-style: normal;
				color: #fff;
				font-size: 22px;
				font-weight: 600;
				letter-spacing: 0;
				line-height: 164%;
				vertical-align: middle;
				padding-top: 0.5%;

				&:first-child {
					padding-left: 28px;
					border-radius: 8px 0px 0px 0px;
				}
				&:last-child {
					width: 150px;
					border-radius: 0px 8px 0px 0px;
				}
				&.name {
					left: 0;
					width: 32%;
					position: relative;
				}
				&.status {
					position: relative;
				}
				&.dateRange {
					width: 25%;
					position: relative;
					padding-left: 1%;
				}
				&.videoCount {
					position: relative;
				}
				&.screens {
					position: relative;
					padding-left: 12%;
				}
			}
		}

		.checkbox {
			display: inline-flex;
			vertical-align: middle;

			.bucket-name {
				padding-top: 3px;
			}
			.ranked-name {
				color: $primary-default;
				font-size: 12px;
				margin-left: 4px;
			}

			#name-checkbox-row {
				appearance: none;
				-webkit-appearance: none;
				width: 19px;
				height: 19px;
				cursor: pointer;
				background: rgba(40, 40, 40, 0.2);
				color: black;
				-moz-appearance: none;
				border: 1px solid rgba(255, 255, 255, 0.32);
				box-sizing: border-box;
				border-radius: 4px;
				margin-right: 16px;
				margin-top: 4px;
			}

			#name-checkbox {
				appearance: none;
				-webkit-appearance: none;
				width: 19px;
				height: 19px;
				cursor: pointer;
				background: rgba(40, 40, 40, 0.2);
				color: black;
				-moz-appearance: none;
				border: 1px solid rgba(255, 255, 255, 0.32);
				box-sizing: border-box;
				border-radius: 4px;
				margin-right: 16px;
				margin-top: 7px;
				.extra-padding {
					padding-top: 32;
				}
			}
			#name-checkbox-row:checked,
			#name-checkbox:checked {
				background-color: #f82c5e;
				padding: 2px 3px;
				border: 0;
				&::after {
					content: '';
					width: 12px;
					height: 9px;
					background-image: url(../../assets/images/tick.svg);
					display: inline-block;
				}
			}
		}

		.row {
			min-height: 64px;
			border-bottom: 1px solid #181a1f;
			border-top: 1px solid #181a1f;
			background-color: #2a2a31;
		}
	}
	.table thead tr:first-child {
		box-shadow: none;
		height: 74px;
		background: #363541;
	}

	@media only screen and (max-width: 1080px) {
		max-height: 83vh;
		.table {
			tr {
				th {
					padding-top: 1.2%;
					&:first-child {
						padding-left: 17px;
					}

					&.name {
						padding-right: 64px;
					}
				}
				td {
					padding-top: 6px;
					&:first-child {
						padding-left: 17px;
					}
					&:last-child {
						padding-top: 21px;
					}
				}
			}
		}
	}

	@media only screen and (min-width: 1220px) and (max-width: 1310px) {
		.table {
			tr {
				th {
					&.screens {
						padding-left: 8% !important;
					}
				}
				td {
					&:nth-last-child(2) {
						padding-left: 8.5%; //10.5%
					}
				}
			}
		}
	}
	@media only screen and (min-width: 1181px) and (max-width: 1220px) {
		.table {
			tr {
				th {
					&.screens {
						padding-left: 8% !important;
					}
				}
				td {
					&:nth-last-child(2) {
						padding-left: 8.5%; //7.5%
					}
				}
			}
		}
	}
	@media only screen and (max-width: 1180px) {
		.table {
			tr {
				th {
					&.screens {
						padding-left: 6% !important;
					}
					&.info-width {
						width: 100px;
					}
				}
				td {
					&:nth-last-child(2) {
						padding-left: 7.5%;
					}
				}
			}
		}
	}

	@media only screen and (min-width: 1280px) {
		max-height: 78.5vh;
	}
}
.empty-space {
	width: 19px;
	margin-right: 24px;
}
.changeBG {
	background: rgba(14, 14, 23, 0.32);
	width: 100%;
}
