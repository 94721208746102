@import '../../../common/utils/scss/variables.scss';

.dashboard-search {
	display: flex;
	align-items: center;
	background: $sidebar-bg;
	padding: 0px 16px;
	border-radius: 8px;
	position: relative;

	.filter-box {
		display: flex;
		flex-direction: row;
		align-items: center;
		height: 52px;
		width: 13vw;
		background: $sidebar-bg;
		color: rgba(255, 255, 255, 0.42);
		font-size: 1.125em;
		padding-left: 0.5em;
		border: 0;
	}

	.close-icon {
		width: 9px;
		height: 9px;
		cursor: pointer;
	}

	.digest-icon {
		width: 20px;
		height: 20px;
		cursor: pointer;
		position: absolute;
		right: 0;
		margin-right: 14.15px;
	}

	.search-box {
		display: flex;
		flex-direction: row;
		align-items: center;
		height: 52px;
		width: 28.5vw;
		background: $sidebar-bg;
		color: rgba(255, 255, 255, 0.42);
		font-size: 1.125em;
		padding-left: 0.5em;
		border: 0;
	}

	@media only screen and (max-width: 1080px) {
		.search-box {
			height: 40px;
			width: 23.5vw;
			padding-top: 0.3em;
			padding-left: 0.4em;
		}
	}
}
