.add-button {
	width: 163px;
	height: 176px;
	border: 2px solid transparent;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: #2a2a31;
	position: relative;
	padding: 2px;
	box-sizing: border-box;
	background-clip: padding-box;
	border-radius: 4px;
	cursor: pointer;

	.add-screen {
		font-style: normal;
		font-weight: 600;
		font-size: 18px;
		line-height: 96%;
		color: #f82c5e;
		margin-top: 12px;
	}

	&:before {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		z-index: -1;
		content: '';
		margin: -2px;
		border-radius: inherit;
		background: linear-gradient(
				268.81deg,
				#6263ed 0%,
				#6363ec 6.67%,
				#6761e8 13.33%,
				#6e5fe1 20%,
				#785bd8 26.67%,
				#8556cc 33.33%,
				#9451be 40%,
				#a44bae 46.67%,
				#b6449d 53.33%,
				#c63e8d 60%,
				#d5397f 66.67%,
				#e23473 73.33%,
				#ec306a 80%,
				#f32e63 86.67%,
				#f72c5f 93.33%,
				#f82c5e 100%
			),
			linear-gradient(
				238.19deg,
				#6263ed 0%,
				#6363ec 6.67%,
				#6761e8 13.33%,
				#6e5fe1 20%,
				#785bd8 26.67%,
				#8556cc 33.33%,
				#9451be 40%,
				#a44bae 46.67%,
				#b6449d 53.33%,
				#c63e8d 60%,
				#d5397f 66.67%,
				#e23473 73.33%,
				#ec306a 80%,
				#f32e63 86.67%,
				#f72c5f 93.33%,
				#f82c5e 100%
			);
	}
}
