.kebab-menu-icon-container {
	cursor: pointer;
	z-index: 1;
	.selected-dots {
		margin-top: 3px;
		margin-left: 9px;
	}
}
.more-styling {
	margin-left: -23px !important;
}
.more-styling-dropdown {
	right: 18px !important;
}
.table-kebab-dropdown {
	padding-top: 5px;
	z-index: 2;
	position: absolute;
	top: 15px;
	right: 30px;
}
