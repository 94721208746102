.cast-profile-wrapper {
	margin-top: 8px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 112px;
	cursor: pointer;
	flex-shrink: 0;
	.profile-img {
		width: 74px;
		height: 74px;
		border-radius: 115px;
	}
	.user-icon-error {
		display: flex;
		justify-content: center;
		align-items: center;
		.user-name-error-span {
			margin-top: 4px;
			color: #fff;
			font-family: 'TT-Commons-Regular';
			font-style: normal;
			font-weight: 400;
			font-size: 20px;
		}
	}
	.cast-name {
		font-family: 'TT-Commons-Regular';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 120%;
		color: #ffffff;
		margin-top: 8px;
		text-align: center;
		word-break: break-word;
		height: 38px;
	}
}
