@import "../../common/utils/scss/variables.scss";

.select-span{
  height: 40px;
  width: 197px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px 16px 8px 16px;
  background-color: $body-background-color;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .selected-value{
      font-family: 'TT-Commons-Regular';
      font-size: 18px;
      color: #FFFFFF6B;
  }
  .select-box-down-arrow-rotate{
      transform: rotate(180deg);
      transition: transform 0.3s linear;
  }
  .select-box-down-arrow-unrotate{
      transform: rotate(0);
      transition: transform 0.3s linear;
  }
  #select-box-bucket-down-arrow{
      cursor: pointer;
  }
}
.bucket-option-wrapper-size{
  width: 197px;  
}