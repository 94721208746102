@import 'src/app/common/utils/scss/main.scss';
.sidebar-wrapper {
	background-color: $sidebar-bg;
	box-shadow: 0 2px 19px 0 rgba(206, 206, 206, 0.15);
	width: 128px;
	height: calc(100vh - 40px);
	border-right: 1px solid $border-color;
	border-radius: 8px;
	padding: 69px 0 0 0;
	margin-left: 40px;
}

.sidebar-container {
	height: 100%;
	div.menuList {
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 4px;
		flex-direction: column;
		transition: height 0.2s linear;

		&.nav-1 {
			margin-bottom: 46px;
			margin-top: 16px;
		}
	}

	.is-scrollable::-webkit-scrollbar {
		display: none;
	}

	.sidebar-menu {
		display: block;
		z-index: 0;
		max-height: 70%;
		width: 100%;

		&.is-scrollable {
			overflow-y: auto;
			overflow-x: hidden;
			-ms-overflow-style: none;
			scrollbar-width: none;
		}

		& > li.menuListExpanded {
			left: 43px;
		}

		& > li.menuListNotExpanded {
			left: 43px;
		}

		& > li.menuListExpanded:first-child {
			left: 43px;
			width: 100%;
			transition: all 0.3s ease-in-out;
		}
		& > li:first-child {
			width: 24px;
			transition: all 0.3s ease-in-out !important;
			& img {
				max-width: 41px;
				left: 0;
				right: 0;
			}
		}

		.nav-2,
		.nav-3 {
			&.menuListExpanded {
				.active-expanded {
					margin-top: 14px;
				}
			}
		}
		li {
			left: 14px;
			position: relative;
			& a.active::after {
				content: '';
				position: absolute;
				width: 0px;
				height: 0px;
				background-color: #181a1f;
				border-radius: 8px;
				left: -18px;
				bottom: 3px;
				opacity: 0.3;
				animation: animTab 0.2s ease-in-out forwards;
			}
			& a.active {
				span {
					p {
						color: $primary-default;
					}
				}
			}
			& a.active-expanded::before {
				content: '';
				position: absolute;
				background-color: #181a1f;
				border-radius: 8px;
				bottom: 3px;
				opacity: 0.3;
				left: -14px;
				animation: animTabExpanded 0.2s ease-in-out forwards;
			}
			& a.active-expanded {
				span {
					p {
						color: $primary-default;
					}
				}
			}
			@keyframes animTab {
				0% {
					width: 40px;
					height: 40px;
					opacity: 0;
					top: 0;
				}
				// 20% {
				//   width: 102px;
				//   height: 50px;
				//   opacity: 0.6;
				//   top: 1px;
				// }
				// 40% {
				//   width: 88px;
				//   height: 50px;
				//   opacity: 0.6;
				//   top: 1px;
				// }
				// 60% {
				//   width: 78px;
				//   height: 50px;
				//   opacity: 0.6;
				//   top: 1px;
				// }
				// 80% {
				//   width: 68px;
				//   height: 50px;
				//   opacity: 0.6;
				//   top: 1px;
				// }
				100% {
					width: 58px;
					height: 50px;
					opacity: 1;
					top: 0;
				}
			}
			@keyframes animTabExpanded {
				0% {
					width: 58px;
					height: 50px;
					opacity: 1;
					top: 0;
				}
				20% {
					width: 68px;
					height: 50px;
					opacity: 1;
					top: 0;
				}
				40% {
					width: 78px;
					height: 50px;
					opacity: 1;
					top: 0;
				}
				60% {
					width: 80px;
					height: 50px;
					opacity: 1;
					top: 0;
				}
				80% {
					width: 102px;
					height: 50px;
					opacity: 1;
					top: 0;
				}
				100% {
					width: 122px;
					height: 50px;
					opacity: 1;
					top: 0;
				}
			}
		}
		li {
			a {
				color: $menu-color;
				font-size: $base-font - 1;
				font-weight: 600;
				letter-spacing: 0;
				line-height: 16px;
				opacity: 1;
				text-transform: capitalize;
				& img {
					position: relative;
					z-index: 1;
				}
			}
			&.menuList {
				height: 50px;
				display: flex;
				justify-content: center;
				margin-top: 4px;
				flex-direction: column;
				transition: height 0.2s linear;
			}
			&.menuListExpanded {
				height: 50px;
				display: flex;
				justify-content: center;
				margin-top: 4px;
				flex-direction: column;
				transition: height 0.2s linear;

				.sub-link-list {
					margin-top: 24px;
					margin-bottom: 4px;
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					opacity: 0;
					animation: scaleInList 0.3s forwards;

					@keyframes scaleInList {
						0% {
							transform: scaleX(0.2);
							transform-origin: left;
							opacity: 0;
						}
						20% {
							transform: scaleX(0.2);
							transform-origin: left;
							opacity: 0;
						}
						40% {
							transform: scaleX(0.4);
							transform-origin: left;
							opacity: 0;
						}
						60% {
							transform: scaleX(0.6);
							transform-origin: left;
							opacity: 0;
						}
						80% {
							transform: scaleX(0.8);
							transform-origin: left;
							opacity: 1;
						}
						100% {
							transform: scaleX(1);
							transform-origin: left;
							opacity: 1;
						}
					}
					.sub-link-list-item {
						margin-right: 17px;
						.active-sublink {
							.sub-link-text {
								color: $primary-default;
							}
						}
						.sub-link-text {
							color: #ffffffb2;
							font-family: 'TT-Commons-Demi';
							font-size: 16px;
							font-weight: 600;
							line-height: 30px;
						}
					}
					@media only screen and (max-width: 1080px) {
						.sub-link-list-item {
							margin-right: 5px;
						}
					}
					li:first-child {
						margin-top: 0 !important;
						margin-bottom: 0;
					}
				}
			}
		}
		li:first-child {
			img {
				padding: 0;
			}
		}
	}

	.profile-avatar {
		position: absolute;
		bottom: 0px;
		left: 0;
		margin: auto;
		right: 0;
		width: auto;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 88px;
		cursor: pointer;
		background-color: $sidebar-bg;
		z-index: 2;
		img {
			width: 48px;
			height: 48px;
			border-radius: 50%;
		}
		.avatar-initials {
			border-radius: 50%;
			color: #fff;
			background: #f82c5e;
			height: 48px;
			width: 48px;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 20px;
			font-family: 'TT-Commons-Demi';

			span {
				margin-top: 4px;
			}
		}
		.empty-avatar {
			background: transparent;
		}
	}

	@media only screen and (max-width: 1080px) {
		.sidebar-menu {
			& > li.menuListNotExpanded {
				left: 38px;
			}
			& > li.menuListExpanded {
				left: 38px;
			}
			li {
				&.menuList {
					margin-top: 17px;
				}
				&:first-child {
					// margin-top: 31px;
					// margin-bottom: 43px;
				}
				& a.active-expanded::after {
					padding: 0 45px;
				}
			}
		}
	}
	.sidebar-expansion-span {
		display: flex;
		justify-content: center;
		padding: 14.5px;
		top: 0;
		right: -32px;
		position: absolute;
		width: 32px;
		height: 32px;
		background-color: $sidebar-bg;
		border-radius: 0 8px 8px 0;
		cursor: pointer;
		&::before {
			content: '';
			position: absolute;
			background-color: transparent;
			bottom: -50px;
			right: 7px;
			height: 50px;
			width: 25px;
			border-top-left-radius: 12px;
			box-shadow: 0 -25px 0 0 $sidebar-bg;
		}
	}
	.icon-1,
	.icon-2,
	.icon-3 {
		position: absolute;
		width: 10px;
		height: 2px;
		background: #ffffff6b;
		transition: all 200ms cubic-bezier(0.84, 0.06, 0.52, 1.8);
	}

	.icon-1 {
		transform: translateY(-5px);
		animation-delay: 100ms;
	}

	.icon-3 {
		transform: translateY(5px);
		animation-delay: 250ms;
	}

	.icon-1.firstDivHamburger {
		transform: rotate(40deg);
		width: 14px;
	}
	.icon-3.secondDivHamburger {
		transform: rotate(-40deg);
		width: 14px;
	}
	.icon-2.thirdDivHamburger {
		opacity: 0;
	}
}

.height-fit-content {
	height: fit-content !important;
	// padding-top: 13px;
}
.height-50 {
	height: 50px !important;
}
.justify-content-center {
	justify-content: center;
}

.alert-confirmation {
	padding: 20px;
	width: 327px;
	height: auto;
	background: #2a2a31;
	box-shadow: 0px 18px 26px 0px #00000059;
	border-radius: 12px;
}

.overlay-custom-class-name {
	background: rgba(0, 0, 0, 0.9) !important;
}
