/* Eric Meyer's Reset */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  font-size: $base-font;
  font-family: $font-stack;
  color: $color-black;
  background-color: $body-background-color;
}

ol,
ul {
  list-style: none;
}

/* Font Face */

@font-face {
  font-family: "TT-Commons-Demi";
  src: url("../../../assets/fonts/TTCommons-DemiBold.woff") format("woff");
}
@font-face {
  font-family: "TT-Commons-Regular";
  src: url("../../../assets/fonts/TTCommons-Regular.woff") format("woff");
}
@font-face {
  font-family: "TT-Commons-Bold";
  src: url("../../../assets/fonts/TTCommons-Bold.woff") format("woff");
}
@font-face {
  font-family: "TT-Commons-Medium";
  src: url("../../../assets/fonts/TTCommons-Medium.woff") format("woff");
}

/* Global Typography */

*,
:before,
:after {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 100%;
}

/* Global Headings */

h1 {
  font-family: $font-stack-bold;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 2px;
  color: #ffffff6b;
}
h2 {
  color: $heading-color;
  font-size: $base-font + 6;
  font-weight: 600;
  letter-spacing: 1.11px;
  line-height: 30px;
  text-align: center;
  margin-bottom: 34px;
}

h3 {
  font-family: $font-stack-bold;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.9375rem;
  text-align: left;
  color: #fff;
}

/* General Text Formatting */

small {
  font-size: 85.71429%;
  line-height: 1.75;
  display: inline-block;
}

img {
  max-width: 100%;
  height: auto;
}

p {
  font-family: "TT-Commons-Demi";
  padding-top: 4.5px;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
}
/* Global Link style */

a {
  color: $color-black;
  text-decoration: none;
  outline: none;
  display: inline-block;
}

// Global Classes
.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

/* Global Button Styles */

button {
  background: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
}

.black-btn {
  @include border-radius(4px);
  background-color: #f82c5e;
  text-transform: capitalize;
  color: $menu-color;
  font-size: $base-font;
  font-weight: 600;
  padding: 11.6px 37px 11.6px;
  letter-spacing: 0px;
  font-family: $font-stack;
}

.cancel-btn {
  color: $cancel-btn;
  font-size: $base-font;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
  font-family: $font-stack;
}

/* Global style */

.container {
  padding-left: 15px;
  padding-right: 15px;
}

.global-container {
  display: flex;
  padding-top: 40px;
  height: 100vh;
  overflow: hidden;
  position: relative;
}
.global-sidebar-container {
  background-color: $sidebar-bg;
  height: 91.2vh;
  position: relative;
  margin-left: 40px;
  margin-right: 48px;
  transition: width 0.2s linear;
  width: 108px;
  border-radius: 8px;
  border-top-right-radius: 0;
  &.global-sidebar-container-expanded-width{
    width: 170px;
    max-width: none;
    flex: none;
    text-align: center;
  }
}


@media only screen and (min-width: 1081px) and (min-height: 600px) and (max-height: 900px) { 
  .global-sidebar-container { 
    height: 92.4vh;
  }
}
@media only screen and (min-width: 1281px) { 
  .global-sidebar-container { 
    height: 91.8vh;
  }
}
.global-content-container {
  width: 84.6%;
  height: 100vh;
  flex: none;
  position: absolute;
  transition: all 0.2s ease-in-out;
  right: 40px;
  &.global-content-expanded-container {
    width: calc(100% - 290px);
  }
  &.global-content-collapsed-container {
    width: calc(100% - 237px);
  }
  @media only screen and (max-width: 1080px) {
    width: 84.4%;
  }
}
input {
  &:focus {
    outline: 0;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type="number"] {
  -moz-appearance: textfield;
}

#root {
  position: relative;
}

.react-calendar__navigation__prev-button,
.react-calendar__navigation__next-button {
  @include pos-abs(50%, auto, auto, -15px);
  @include transform(translateY(-50%));
  height: 43px;
  width: 43px;
  background-color: #f4f4f7;
  @include border-radius(50%);
  font-size: 0;
  &:enabled {
    height: 43px;
    width: 43px;
    background-color: #f4f4f7;
    @include border-radius(50%);
  }
  &:after {
    content: "";
    @include pos-abs(50%, auto, auto, 50%);
    transform: translate(-50%, -50%) rotate(180deg);
    background: url(../../../assets/images/arrow-big.svg) no-repeat;
    height: 10.5px;
    width: 14px;
  }
}

.react-calendar__navigation__next-button {
  left: auto;
  right: -15px;
  &:after {
    content: "";
    @include pos-abs(50%, auto, auto, 50%);
    transform: translate(-50%, -50%) rotate(0deg);
    height: 10.5px;
    width: 14px;
  }
}

@media only screen and (max-width: 1080px) {
  .global-container {
    padding: 0;
  }
  .global-sidebar-container {
    background-color: $sidebar-bg;
    margin-left: 0;
    margin-right: 3.5%;
    border-radius: 8px;
    height: 100vh;
    width: 99px;
  }
  .global-content-container{
    &.global-content-collapsed-container{
      width: calc(100% - 170px);
    }
    &.global-content-expanded-container {
      width: calc(100% - 240px);
    }
  }
}