.table {
	&.published-table {
		tbody {
			td:first-child {
				width: 32%;
			}
			.row-dateRange {
				width: 25%;
			}
			td:last-child {
				.kebab-menu-icon-container {
					transform: translateX(-70px);
				}
			}
		}
	}
}
