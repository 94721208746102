.wrapper-episode {
	display: flex;
	.episode-tab-container {
		display: flex;
		flex-direction: row;
		align-items: center;
		background: rgba(24, 26, 31, 0.01);
		box-shadow: inset 0px -1px 0px rgba(14, 14, 23, 0.12);
		overflow-y: hidden;
		overflow-x: scroll;
		scroll-behavior: smooth;

		&::-webkit-scrollbar {
			width: 0px;
			height: 0px;
			background: transparent;
		}
		&::-webkit-scrollbar-track {
			background: transparent;
		}
		&::-webkit-scrollbar-thumb {
			background: transparent;
		}

		.block {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			padding: 15px 2px;
			height: 46px;
			width: 140px;
			cursor: pointer;
			flex-shrink: 0;
		}

		p {
			font-family: 'TT-Commons-Demi';
			font-style: normal;
			font-weight: 600;
			font-size: 20px;
			line-height: 115%;
			color: rgba(255, 255, 255, 0.42);
		}

		.active {
			border-bottom: 0;
			box-shadow: inset 0px -2px 0px #6061f6;
			// position: relative;
			p {
				color: #f82c5e;
			}
		}
	}
	.more-margin {
		margin-left: 28px;
	}
	.right-arrow {
		width: 16px;
		height: 16px;
		margin-right: 16px;
		margin-top: 12px;
		cursor: pointer;
	}
	.left-arrow {
		-webkit-transform: rotate(180deg);
		-moz-transform: rotate(180deg);
		-ms-transform: rotate(180deg);
		-o-transform: rotate(180deg);
		transform: rotate(180deg);
		width: 16px;
		height: 16px;
		cursor: pointer;
		margin-left: 16px;
		margin-top: 12px;
	}
	.deamed {
		pointer-events: none;
		opacity: 0.4;
	}
}
