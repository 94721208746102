@import "../../common/utils/scss/variables.scss";
.filter-tag-container {
  display: flex;
  white-space: nowrap;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  .filter-tag {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 20px 8px 20px;
    background: #26242c;
    border-radius: 12px;
    margin: 0px 12px 16px 5px;

    .tag-name {
      margin-right: 8.75px;
      color: $filter-tag-color;
      transform: translateY(0.75px);
    }
    .cross-icon-container {
      width: max-content;
      transform: translateY(2px);
    }
    .cross-icon {
      width: 9.5px;
      height: 9.5px;
      cursor: pointer;
    }
  }
}
