@import 'src/app/common/utils/scss/main.scss';

.right-container {
	width: 100%;
	position: relative;
	margin-top: 1.5rem;
	display: flex;
	flex-direction: column;
	.content-block::-webkit-scrollbar {
		display: none;
	}
	.content-block {
		overflow-y: auto;
		position: relative;
		// hiding scroll bar
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
		max-height: fit-content;
		height: 75vh;
		.infinite-scroll-data {
			display: flex;

			.carousel-data-container {
				display: flex;
				flex-direction: column;
				width: 100%;
			}
		}
		.debounce-loader {
			width: 100%;
			height: 100%;
			margin-top: 15%;
		}
		.empty-image {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			margin-top: 10%;

			img {
				margin: 40px 0px;
			}
			.no-filter-found {
				font-style: normal;
				font-weight: bold;
				font-size: 20px;
				line-height: 115%;
				color: #ffffff;
			}
			.please-change-filter {
				font-style: normal;
				font-weight: normal;
				font-size: 18px;
				line-height: 135%;
				text-align: center;
				color: rgba(255, 255, 255, 0.7);
			}
		}
	}
	.header-block {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		margin-bottom: 25px;
	}
	.dashboard-button-block {
		display: flex;
		align-items: center;
		justify-content: space-between;
		transform: translate(0px, -2px);
		margin-top: -7px;
		button:first-child {
			// margin-right: 28px;
			padding: 8.8px 16px;
		}
	}
	.search-box-block {
		margin-bottom: 32px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		.live-search-with-cancel {
			width: 30%;
			display: flex;
			align-items: center;

			.cancel-button {
				margin-left: 10px;
				color: white;
				border: none;
			}

			.live-stream-in-channel-search {
				background: rgba(0, 0, 0, 0.5);
				.search-box {
					background: rgba(0, 0, 0, 0);
				}
			}
		}
	}
	.header-parent-block {
		display: flex;
		flex-direction: column;
	}

	@media only screen and (max-width: 1080px) and (min-height: 600px) {
		max-height: 95vh;
		h1 {
			font-size: 32px;
			letter-spacing: 0px;
		}
		p {
			font-size: 20px;
		}
		h3 {
			font-size: 22px;
		}
		.header-block {
			margin-bottom: 30px;
			.heading {
				margin-top: 6px;
			}
		}
		.search-box-block {
			margin-bottom: 25px;
		}
		.dashboard-button-block {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 0;
			transform: translate(0px, 0px);
			button:first-child {
				margin-right: 28px;
				padding: 5px 16px;
			}
			button:last-child {
				p {
					transform: translateX(-2px);
				}
			}
		}
	}
	@media only screen and (min-width: 1081px) and (max-width: 1280px) {
		max-height: 88.5vh;
		p {
			font-size: 20px;
		}
		h3 {
			font-size: 26px;
		}
	}

	@media only screen and (min-width: 1281px) {
		max-height: 89vh;
		h1 {
			font-size: 36px;
			letter-spacing: 0;
		}
	}
}
@media only screen and (max-width: 1080px) {
	.right-container {
		margin-top: 4.5%;
		margin-left: 0.4%;
	}
}
