@import 'src/app/common/utils/scss/variables.scss';

.yt-video-popup {
	position: relative;
	width: 100%;
	height: 100%;
	.cross-icon-wrapper {
		position: absolute;
		right: 10px;
		top: 20px;
		cursor: pointer;
		z-index: 3;
		.cross-icon {
			width: 20px;
			height: 20px;
		}
	}
	.content-container {
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		overflow-y: scroll;
		border-radius: 12px;
		&::-webkit-scrollbar {
			display: none;
		}
		.thumbnail {
			height: 350px;
			width: 700px;
			position: relative;
			.backIcon {
				position: absolute;
				width: 20px;
				height: 20px;
				z-index: 4;
				cursor: pointer;
				top: 20px;
				left: 20px;
				.arrow {
					width: 100%;
					height: 100%;
				}
			}
			.video-thumb {
				width: 100%;
				height: 100%;
				object-fit: fill;
				position: relative;
			}
			.play-icon {
				position: absolute;
				width: 60px;
				height: 40px;
				z-index: 4;
				cursor: pointer;
				top: calc(50% - 20px);
				left: calc(50% - 30px);
				.play-icon {
					width: 100%;
					height: 100%;
				}
			}
		}
		.video-details {
			padding: 20px 10px;
			.video-details-heading {
				font-family: 'TT-Commons-medium';
				font-size: 24px;
				font-style: normal;
				font-weight: 600;
				line-height: 120%;
				color: $filter-tag-color;
				margin-bottom: 6px;
			}
			.video-details-channel-name {
				font-family: 'TT-Commons-demi';
				font-size: 18px;
				font-style: normal;
				font-weight: 600;
				line-height: 120%;
				color: #fff;
				margin-bottom: 10px;
			}
			.stats {
				display: flex;
				overflow-x: auto;
				.stats-wrap {
					padding: 10px 16px;
					background: $drop-down-highlight; //$filter-background;
					border-radius: 8px;
					margin-right: 12px;
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					.stats-count {
						font-family: 'TT-Commons-regular';
						font-size: 18px;
						font-style: normal;
						font-weight: 600;
						line-height: 120%;
						color: #fff;
					}
					.seprator {
						margin-top: 4px;
						margin-bottom: 6px;
						width: 100%;
						height: 0px;
						border: 1px solid rgba(255, 255, 255, 0.1);
					}
					.stats-label {
						font-family: 'TT-Commons-regular';
						font-size: 16px;
						font-style: normal;
						font-weight: 400;
						line-height: 120%;
						color: $filter-tag-color;
					}
				}
			}
			.desc {
				margin-top: 20px;
				font-family: 'TT-Commons-regular';
				font-size: 16px;
				font-style: normal;
				font-weight: 400;
				line-height: 140%;
				color: $filter-tag-color;
			}
		}
	}
}
