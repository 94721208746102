@import 'src/app/common/utils/scss/variables.scss';
.buck-desc-container {
	padding: 32px;
	.buck-desc-heading-name {
		font-family: 'TT-Commons-Regular';
		font-style: normal;
		font-weight: 700;
		font-size: 20px;
		line-height: 115%;
		color: #ffffff;
		margin-bottom: 4px;
	}
	.buck-desc-heading-count {
		font-family: 'TT-Commons-Regular';
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 120%;
		color: rgba(255, 255, 255, 0.42);
		margin-bottom: 12px;
	}
	.cross-icon-popup {
		position: absolute;
		right: 14px;
		top: 14px;
		cursor: pointer;
		height: 12px;
		width: 12px;
	}
	.seprator {
		border: 1px solid rgba(14, 14, 23, 0.2);
		margin-bottom: 6px;
	}
	.content-wrapper {
		overflow-y: auto;
		max-height: 340px;
		transition: height 0.5s ease-in;
		&::-webkit-scrollbar {
			width: 3px;
			height: 25px;
			background: transparent;
		}
		&::-webkit-scrollbar-track {
			background: transparent;
		}
		&::-webkit-scrollbar-thumb {
			background: #888;
		}
		.loader {
			margin-top: 20px;
		}
		.content-div {
			margin-top: 12px;
			display: grid;
			grid-template-columns: 1fr 1fr;
			row-gap: 20px;
			column-gap: 60px;
			overflow-y: auto;
			padding-bottom: 4px;

			.bucket-card {
				display: flex;
				align-items: center;
				.buck-thumbnail-wrapper {
					width: 90px !important;
					height: 90px !important;

					.buck-single-thumb {
						img {
							width: 90px !important;
							height: 90px !important;
							object-fit: cover;
							display: block;
						}
					}
					.buck-thumb {
						// display: inline-flex;
						display: grid;
						grid-template-columns: 1fr 1fr;
						img {
							width: 45px !important;
							height: 45px !important;
							object-fit: cover;
							display: block;
						}
					}

					.image-thumb-container-double {
						width: 100%;
						height: 100%;
						position: relative;
						display: flex;
						.image-bottom-wrap-double,
						.image-top-wrap-double {
							width: 50%;
							height: 100%;
							display: flex;
							img {
								width: 45px !important;
								height: 90px !important;
								object-fit: cover;
								display: block;
							}
						}
					}

					.image-thumb-container {
						width: 100%;
						height: 100%;
						position: relative;

						.image-top-wrap {
							width: 100%;
							height: 50%;
							display: flex;
							img {
								width: 45px !important;
								height: 45px !important;
								object-fit: cover;
								display: block;
							}
						}
						.image-bottom-wrap {
							width: 100%;
							height: 50%;
							img {
								width: 90px !important;
								height: 45px !important;
								object-fit: cover;
								display: block;
							}
						}
					}
				}

				.buck-desc {
					padding-left: 12px;
					max-width: 70%;
					.buck-name {
						font-family: 'TT-Commons-Demi';
						font-style: normal;
						font-weight: 700;
						font-size: 20px;
						line-height: 115%;
						color: #ffffff;
						margin-bottom: 4px;
					}
					.buck-count {
						font-family: 'TT-Commons-Regular';
						font-style: normal;
						font-weight: 400;
						font-size: 16px;
						line-height: 120%;
						display: flex;
						align-items: center;
						color: rgba(255, 255, 255, 0.42);
					}
				}
			}
		}
	}
}
