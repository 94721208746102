@import 'src/app/common/utils/scss/main.scss';
.live-popup-wrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	.live-popup-message {
		color: #ffffff !important;
		font-size: 20px;
		font-weight: 600;
		line-height: 120%;
		padding: 32px;
		padding-bottom: 0px !important;
		font-family: 'TT-Commons-Demi';
		margin-bottom: 14px;
	}
	.live-popup-seperator {
		width: 84%;
		height: 0px;
		border: 1px solid rgba(14, 14, 23, 0.2);
		margin-bottom: 16px;
	}
	.live-popup-category {
		width: 80%;
	}
	.buttons {
		display: flex;
		margin-right: 22px;
		margin-left: auto;
		.cancel {
			margin-right: 24px;
			background: $sidebar-bg;
			border: 1px solid rgba(255, 255, 255, 0.1);
		}
		button {
			color: rgb(255, 255, 255);
			border: none;
			background: $primary-default;
			border-radius: 8px;
			padding: 7.5px 23px;
			font-weight: 600;
			font-size: 18px;
		}
		.disable-button {
			pointer-events: none;
			opacity: 0.5;
		}
	}
}
