@import 'src/app/common/utils/scss/variables.scss';
.live-bucket-detail {
	width: 100%;
	position: relative;
	margin-top: 1.2rem;
	padding-right: 7px;
	display: flex;
	flex-direction: column;
	.header-parent-block {
		display: flex;
		flex-direction: column;
		margin-top: 8px;
		.header-block {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			margin-bottom: 25px;
			.view-tags {
				font-family: 'TT-Commons-Medium';
				font-style: normal;
				font-weight: 600;
				font-size: 20px;
				line-height: 115%;
				color: #f82c5e;
				cursor: pointer;
			}
		}
		.dashboard-button-block {
			display: flex;
			align-items: center;
			justify-content: space-between;
			transform: translate(0px, -2px);
			margin-top: -7px;
			button:first-child {
				padding: 8.8px 16px;
			}
		}
		.search-box-block {
			margin-bottom: 25px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;

			.aside-buttons {
				display: flex;
				align-items: center;
				#tooltip.bottom-side {
					margin-top: 120px;
					right: 195px;
					left: auto;
				}

				#tooltip.bottom-side .tooltip-arrow {
					top: 0;
					left: 50%;
					margin-left: -5px;
					border-width: 0 5px 5px;
					border-bottom-color: #2a2a31;
				}

				.live-add-button {
					border: none;
					background-color: $primary-default;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 47px;
					height: 47px;
					margin-right: 4px;
					img {
						margin: 0;
						border: none;
					}
				}

				.unselect-all-cards-icon {
					margin-left: 18px;
					cursor: pointer;
				}
				.change-layout-icon {
					height: 46px;
					width: 46px;
					margin-left: 12px;
					cursor: pointer;
				}
				.no-margin {
					margin-right: 0px;
				}
				.live-delete-button {
					border: none;
					background-color: #2a2a31;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 47px;
					height: 47px;
					img {
						margin: 0;
						border: none;
					}
				}
			}
		}
	}
	.content-container {
		display: flex;
		width: 100%;
		overflow-y: auto;

		&::-webkit-scrollbar {
			width: 3px;
			height: 0px;
			background: transparent;
			display: none;
		}
		&::-webkit-scrollbar-track {
			background: transparent;
		}
		&::-webkit-scrollbar-thumb {
			background: transparent;
		}
		.content-container-wrapper {
			position: relative;
			width: 100%;
			height: 100%;
			.backIcon {
				margin-left: 50px;
				height: 25px;
				width: 25px;
				cursor: pointer;
			}
			.shuffle-mode-none {
				display: none;
			}
			.shuffle-mode {
				margin-bottom: 20px;
				display: flex;
				align-items: center;
				margin-left: 40px;
				.ordinary {
					font-family: 'TT-Commons-Regular';
					font-style: normal;
					font-weight: 400;
					font-size: 18px;
					line-height: 135%;
					color: #ffffff;
				}
				.highlighted {
					font-family: 'TT-Commons-Regular';
					font-style: normal;
					font-weight: 400;
					font-size: 18px;
					line-height: 135%;
					color: #f82c5e;
					cursor: pointer;
				}
			}
		}
		.data-container {
			position: relative;
			height: 100%;
		}
		.empty-state-container {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			height: 100%;
			width: 100%;
			min-height: 74vh;
			.no-filter-found {
				font-style: normal;
				font-weight: normal;
				font-size: 24px;
				line-height: 115%;
				color: #ffffff;
				margin-top: 12px;
				font-family: 'TT-Commons-Regular';
			}
			.add-channel {
				margin-top: 12px;
			}
		}
	}
}

@media only screen and (min-width: 1281px) {
	.live-bucket-detail {
		max-height: 89vh;
	}
}
