.close-icon {
	width: 9px;
	height: 9px;
	cursor: pointer;
	margin-top: -8px;
}

.digest-icon {
	width: 20px;
	height: 20px;
	cursor: pointer;
	position: absolute;
	right: 0;
	margin-right: 14.15px;
	margin-top: -8px;
}
