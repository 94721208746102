.custom-date-range-wrapper {
  width: 270px;
  background-color: #2a2a31;
  margin-bottom: 36px;
  height: 52px;
  p {
    //styleName: Caps / Medium;
    color: #ffffff6b;
    font-family: 'TT-Commons-Medium';
    font-size: 15px;
    line-height: 19px;
    letter-spacing: 1px;
    margin: 0;
    padding: 0;
  }
}
.date-placeholder {
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 3px 3px 8px 0;
  // background-color: $body-background-color;
  p {
    //styleName: Heading / 4;
    font-family: 'TT-Commons-Bold';
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
  }
}
.date-wrapper {
  font-weight: bold;
  letter-spacing: 0;
  line-height: 18px;
  display: inline-block;
  //border-bottom: 1px solid $calendar-border;
  padding-bottom: 3px;
  letter-spacing: 0.2px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.color-white {
  color: #fff !important;
}
