.scroll-to-top-div {
	position: fixed;
	z-index: 9999;
	width: 40px;
	height: 40px;
	border-radius: 25px;
	right: 50px;
	left: auto;
	bottom: 50px;
	top: auto;
	cursor: pointer;
	background-color: #181a1f;
	.scroll-to-top-image {
		width: 100%;
		height: 100%;
	}
}
.qewd-margin {
	bottom: 80px;
}
