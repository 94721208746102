.disabled-edit-button {
	transition: opacity 0.5s linear;
	opacity: 0.4;
	pointer-events: none;
	cursor: no-drop;
}

.edit-modal-popup-animation-content {
	transition: all 0.3s ease-in-out !important;
	animation: anim 0.2s forwards !important;
}

.edit-modal-popup-animation-overlay {
	z-index: 100 !important;
}

.live-stream-on-popup {
	padding: 30px;
}

@keyframes anim {
	from {
		transform: translateX(100%);
	}
	to {
		transform: translateX(0%);
	}
}
