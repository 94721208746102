@import 'src/app/common/utils/scss/variables.scss';

.settings-screen-container {
	height: 100vh;
	.settings-header-parent-block {
		display: flex;
		justify-content: space-between;

		.settings-header-block {
			margin-top: 20px;
			display: flex;
			align-items: center;
			h1 {
				font-family: 'TT-Commons-Demi';
				font-style: normal;
				font-weight: 700;
				font-size: 36px;
				line-height: 100%;
				display: flex;
				align-items: flex-end;
				color: rgba(255, 255, 255, 0.42);
			}
		}
		.button-wrapper {
			display: flex;
			align-items: center;
			margin-top: 40px;
			.button-wrapper-para {
				font-family: 'TT-Commons-Regular';
				font-style: normal;
				font-weight: 400;
				font-size: 18px;
				line-height: 135%;
				color: rgba(255, 255, 255, 0.7);
			}
		}
	}
	.content-container {
		display: flex;
		margin-top: 16px;
		.left-panel-container {
			background: rgba(14, 14, 23, 0.62);
			border-radius: 8px 0px 0px 8px;
			padding: 28px;
			overflow-y: scroll;
			width: 22%;
			&::-webkit-scrollbar {
				width: 0px;
				height: 0px;
				background: transparent;
			}
			&::-webkit-scrollbar-track {
				background: transparent;
			}
			&::-webkit-scrollbar-thumb {
				background: transparent;
			}
			.content-pill-wrapper {
				padding: 12px 24px;
				background: #2a2a31;
				border-radius: 8px;
				margin-bottom: 16px;
				cursor: pointer;
				p {
					font-family: 'TT-Commons-Demi';
					font-style: normal;
					font-weight: 600;
					font-size: 18px;
					line-height: 96%;
					color: rgba(255, 255, 255, 0.7);
				}
			}
			.active-tab {
				p {
					color: #f82c5e;
				}
			}
		}
	}
}
