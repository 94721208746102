.qwed-digest-right-wrapper {
	margin-top: 2%;

	.qwed-digest-thumbnails {
		display: flex;
		width: 100%;
		overflow-x: scroll;
		overflow-y: hidden;
		flex-shrink: 0;
		align-items: center;
		height: 63px;
		&::-webkit-scrollbar {
			width: 5px;
			height: 5px;
		}

		&::-webkit-scrollbar-thumb {
			background: lightgray;
			border-radius: 10px;
		}

		&::-webkit-scrollbar-thumb:hover {
			background: gray;
		}
	}
	.search-box-container {
		margin-bottom: 31px;
		margin-top: 30px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.pre-populate {
			.pre-populate-text {
				font-weight: 600;
				font-size: 20px;
				line-height: 115%;
				color: #ffffff;
				padding: 13px 17px;
				border-radius: 8px;

				span {
					line-height: 20px;
					display: inline-block;
					transform: translateY(2px);
				}
			}
		}
		.search-with-cancel {
			display: flex;
			align-items: center;
			:focus-within {
				border: 2px solid #f82c5e;
				transition: border 0.1s ease-in-out;
			}
			.qwed-digest-search {
				width: 508px;
				margin-right: 18px;

				input {
					border: none !important;
				}
			}
			.image-button-container {
				border: none !important;
				.cancel-button {
					border: none;
					.cancel-button-label {
						font-style: normal;
						font-weight: normal;
						font-size: 18px;
						line-height: 135%;
						color: #ffffff;
					}
				}
			}
		}
	}
	.tab-button-block {
		margin-bottom: 20px;
		position: relative;

		.right-button-block {
			display: flex;
			align-items: center;
			position: absolute;
			top: 20%;
			right: 0;

			.sort-by {
				font-style: normal;
				font-weight: 600;
				font-size: 18px;
				line-height: 96%;
				color: #ffffff;
				margin-right: 16px;
			}
		}
	}
	.qwed-digest-content-block {
		height: 80vh;
		overflow-y: auto;
		overflow-x: hidden;
		padding-bottom: 15%;
		&::-webkit-scrollbar {
			width: 3px;
			height: 25px;
			background: transparent;
		}
		&::-webkit-scrollbar-track {
			background: transparent;
		}
		&::-webkit-scrollbar-thumb {
			background: #888;
		}

		&::-webkit-scrollbar {
			width: 3px;
			height: 25px;
			background: transparent;
		}
		&::-webkit-scrollbar-track {
			background: transparent;
		}
		&::-webkit-scrollbar-thumb {
			background: #888;
		}

		.carousel-horizontal-content-tile {
			margin-right: 0px;
		}

		.cardImgHor {
			width: 100%;
			height: 141.38px;
		}
		.empty-image {
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			// transform: translateX(15%);

			img {
				margin: 40px 0px;
			}
			.no-filter-found {
				font-style: normal;
				font-weight: bold;
				font-size: 20px;
				line-height: 115%;
				color: #ffffff;
			}
			.please-change-filter {
				font-style: normal;
				font-weight: normal;
				font-size: 18px;
				line-height: 135%;
				text-align: center;
				color: rgba(255, 255, 255, 0.7);
			}
		}
	}
	.qwed-bottom-buttons {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		width: 150%;
		background: #2a2a31;
		box-shadow: 0px -18px 26px rgba(0, 0, 0, 0.25);
		height: 87px;
		position: absolute;
		transform: translateX(-30%);
		bottom: 30px;
		animation: buttonAnimation 0.3s forwards;

		@keyframes buttonAnimation {
			from {
				opacity: 0.3;
				background: transparent;
			}
			to {
				opacity: 1;
				background: #2a2a31;
			}
		}

		.create-qwed-digest {
			transform: translateX(-30%);
			.create-qwed-digest-text {
				padding: 11.5px 15.5px;
				border-radius: 8px;
				font-style: normal;
				font-weight: 600;
				font-size: 20px;
				span {
					transform: translateY(2px);
					line-height: 100%;
					display: inline-block;
				}
			}
		}
		.cancel-button {
			transform: translateX(-60%);
			.cancel-button-text {
				border: 1px solid rgba(255, 255, 255, 0.1);
				border-radius: 8px;
				background: transparent;
				margin-right: 20px;
				padding: 11.5px 20px;
				font-weight: 600;
				font-size: 20px;
				span {
					transform: translateY(2px);
					line-height: 100%;
					display: inline-block;
				}
			}
		}
	}

	@media only screen and (max-width: 1080px) {
		.qwed-bottom-buttons {
			bottom: 0;
		}
	}

	@media only screen and (max-height: 900px) {
		.qwed-digest-content-block {
			padding-bottom: 20%;
		}
	}
}

.selected-on-add {
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
	border: 3px solid #f82c5e;
	box-shadow: 0px 18px 26px rgba(0, 0, 0, 0.35);
	border-radius: 8px;
	transition: border 0.2s ease;
}

.count-on-add {
	background: #f82c5e;
	border-radius: 50%;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 120%;
	color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: all 0.2s ease;
}

.display-none {
	display: none;
}

.count-on-add-text {
	transform: translateY(2px);
}

.count-alert-container-content {
	.count-alert-content {
		display: flex;
		flex-direction: column;
		.title {
			font-style: normal;
			font-weight: 600;
			font-size: 20px;
			line-height: 120%;
			text-align: center;
			color: rgba(255, 255, 255, 0.7);
			padding: 30px;
		}
		.btn-wrap {
			text-align: center;
		}
		.app-button-style {
			span {
				font-style: normal;
				font-weight: 600;
				font-size: 18px;
				line-height: 96%;
				color: #ffffff;
			}
		}
	}
}
